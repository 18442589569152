import { useMemo, useState } from "react";
import { CachedDocument } from "../../../adapters/Firebase/Firebase";
import GraphContainer from "../GraphContainer";
import extractCleanSurfaceData, { CleanSurfaceData } from "./CleanSurfaceData";
import CleanSurfaceCoordinatesGraph from "./CleanSurfaceCoordinatesGraph";
import AverageCleanSurfaceCard from "./AverageCleanSurfaceCard";

interface CleanSurfaceGraphData {
    sessionDoc: CachedDocument
    children: any
    graphElements?: number
}

function CleanSurfaceSession(props: CleanSurfaceGraphData) {
    const session: CachedDocument = props.sessionDoc;

    const [sessionData, setSessionData] = useState<CleanSurfaceData | undefined>(undefined);

    useMemo(() => {
        const data = extractCleanSurfaceData(session.doc);
        setSessionData(data);
    }, [session])

    if (sessionData === undefined) {
        return <></>;
    }

    return (
        <>
            <GraphContainer order={1} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
                {props.children}
                <CleanSurfaceCoordinatesGraph data={sessionData} />
            </GraphContainer>

			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<AverageCleanSurfaceCard data={sessionData} />
			</GraphContainer>
        </>);
}

export default CleanSurfaceSession;
