import { Box, Button, Divider, Drawer, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import UserMenu from '../../features/users/UserMenu';
import { NavItem } from './NavItem';
import Logo from "../../media/logo";
import LogoText from "../../media/logoText";
import { ReactComponent as SessionsIcon } from '../../media/charts.svg';
import { ReactComponent as SessionsSchedulerIcon } from '../../media/scheduler.svg';
import { ReactComponent as ContactIcon } from '../../media/contact.svg';
import { ReactComponent as FaqIcon } from '../../media/faq.svg';
import { ReactComponent as HomeIcon } from '../../media/home.svg';
import { ReactComponent as RemoteControlIcon } from '../../media/remote.svg';
import { ReactComponent as LicenseIcon } from '../../media/license.svg';

import UserLogo from '../../features/users/UserLogo';
import { Role } from '../../features/login/PremiumUser';
import { useTranslation } from '../../contexts/TranslationContext';

interface SidebarProps {
	open: boolean,
	keepOpen: boolean,
	onClose: any,
	user: any,
	onLogout: any,
	onLogin: any,
	width: number
}

const items = [
	{
		href: '/',
		icon: HomeIcon,
		title: "[sidebar_home]"
	},
	{
		href: '/buy',
		icon: LicenseIcon,
		title: "[sidebar_buy]"
	},
	{
		href: '/contact',
		icon: ContactIcon,
		title: "[sidebar_contact]"
	},
	{
		href: '/faq',
		icon: FaqIcon,
		title: "[sidebar_faq]"
	},
	{
		href: '/scheduler',
		icon: SessionsSchedulerIcon,
		title: "[sidebar_sessionscheduler]"
	},
	{
		href: '/remotecontrol',
		icon: RemoteControlIcon,
		title: "[sidebar_remotecontrol]"
	},
	{
		href: '/sessions',
		icon: SessionsIcon,
		title: "[sidebar_sessions]"
	},
];

export const DashboardSidebar = (props: SidebarProps) => {
	const { translate } = useTranslation();
	const theme = useTheme();

	const content = (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%'
				}}
			>
				<div>
					<Box sx={{ p: 3 }}>
						<NavLink to="/">
							<Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
								<Logo height={60} width={60} />
							</Box>
							<Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
								<LogoText height={42} width={200} fill={theme.palette.primary.main} style={{ alignself: "center" }} />
							</Box>
						</NavLink>
					</Box>

				</div>

				<Box sx={{ flexGrow: 3 }}>
					{items.map((item) => (
						<NavItem
							key={item.title}
							icon={item.icon}
							to={item.href}
							title={translate(item.title)}
						/>
					))}
				</Box>
				<Divider
					variant="middle"
					sx={{
						my: 3
					}}
				/>
				<Box sx={{ px: 2, flexGrow: 1 }}>
					<Box
						flexDirection="column"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						gap={2}>
						<UserLogo role={props.user? props.user.role : Role.None} width={200}/>
						{!props.user ?
							<Button style={{ fontWeight: "bold" }}
								onClick={() => {
									props.onLogin();
								}}
								variant="contained">
								Acceso usuarios
							</Button>
							:
							<UserMenu 
								user={props.user}/>
							}
					</Box>
				</Box>
			</Box>


			<Link href="/privacy_policy" variant="caption"
				display="flex" justifyContent="center">
				{translate("[sidebar_privacypolicy]")}
			</Link>

		</>
	);

	return (
		<Drawer
			anchor="left"
			onClose={props.onClose}
			open={props.open}
			PaperProps={{
				sx: {
					width: props.width
				}
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant={props.keepOpen ? "permanent" : "temporary"}
		>
			{content}
		</Drawer>
	);
};
