import { memo, useCallback, useContext, useEffect, useState } from "react";

import { Box, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { addUnmanagedPatient, deletePatient, subscribeToAllPatients, getStripePortal, subscribeToAllRemotePatients, renamePatient, sharePatientWithTherapist } from "../../adapters/Firebase";
import AlertDialog from "../../components/atoms/Popup";
import { UserContext } from "../../contexts/User.context";
import { Patient, getPatientInitials, getPatientIndex, defaultPatientSettings, getPatientName } from "../../data/Patient";
import PatientRow from "./Patient";
import { useTranslation } from "../../contexts/TranslationContext";


interface DeletablePatient {
	patientID: string;
	patientName: string;
}

interface PatientsProps {
	onPatientSelected: (id: Patient) => void;
}

enum AddPatientState {
	None,
	Adding,
	Registering,
	Deleting,
	Failed,
	Renaming,
	Sharing
}

export const Patients = memo(function (props: PatientsProps) {
	const { translate } = useTranslation();
	const [addState, setAddState] = useState<AddPatientState>(AddPatientState.None);
	const [patients, setPatients] = useState<any[]>([]);
	const [unmanagedPatients, setUnmanagedPatients] = useState([] as any);
	const [remotePatients, setRemotePatients] = useState([] as any);
	const [editablePatient, setEditablePatient] = useState<DeletablePatient | null>(null);
	const [initials, setInitials] = useState("");
	const [shareEmail, setShareEmail] = useState("");
	const [shareUnderstanding, setShareUnderstanding] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [searchInitials, setSearchInitials] = useState<string>("");

	const user = useContext(UserContext)!;

	const filteredPatients: any[] = patients.filter((patient: any) => {
		const initials = getPatientInitials(patient);
		if (searchInitials === "") {
			return true;
		}
		return searchInitials.split('')
			.every((initial: string) => initials.includes(initial));
	});

	const onPatientSelected = props.onPatientSelected;
	const loadingAddPatient = addState === AddPatientState.Adding
		|| addState === AddPatientState.Registering
		|| addState === AddPatientState.Deleting;

	const handleDeletePatient = (patient: any) => {
		setEditablePatient(
			{
				patientID: patient.id,
				patientName: getPatientIndex(patient)
			});
		setAddState(() => AddPatientState.Deleting);
	}

	const handleRenamePatient = (patient: any) => {
		setInitials(() => "");
		setEditablePatient(
			{
				patientID: patient.id,
				patientName: getPatientIndex(patient)
			});
		setAddState(() => AddPatientState.Renaming);
	}

	const handleSharePatient = (patient: any) => {
		setEditablePatient(
			{
				patientID: patient.id,
				patientName: getPatientIndex(patient)
			});

		setShareUnderstanding(false);
		setAddState(() => AddPatientState.Sharing);
	}

	const addPatient = () => {
		setInitials(() => "");
		setAddState(() => AddPatientState.Adding);
	}

	const registerPatient = async (patientInitials: string) => {
		setAddState(() => AddPatientState.Registering);
		const result = await addUnmanagedPatient(user.uid, patientInitials);
		if (result.result === 200) {
			setAddState(() => AddPatientState.None);
		}
		else {
			setErrorMessage(result.message);
			setAddState(() => AddPatientState.Failed);
		}
	}

	const handleSearchInitialsChange = (event: any) => {
		const initials = event.target.value.slice(0, 3).toUpperCase();
		setSearchInitials(initials);
	}

	const handleInitialsChange = (event: any) => {
		const initials = event.target.value.slice(0, 3).toUpperCase();
		setInitials(initials);
	}

	const confirmRenamePatient = async (newInitials: string) => {
		if (editablePatient == null) {
			setAddState(() => AddPatientState.None);
			return;
		}

		const patientID = editablePatient.patientID;
		setEditablePatient(null);
		//Change the Initials in the patient instantly to reflect the change
		setPatients((patients: any[]) => patients.map((item) => {
			if (item.id == patientID) {
				const p = item;
				p.doc.initials = newInitials;
				return p
			}
			else {
				return item;
			}
		}));

		await renamePatient(patientID, newInitials);
		setAddState(() => AddPatientState.None);
	}

	const confirmSharePatient = async (email: string) => {
		if (editablePatient == null) {
			setAddState(() => AddPatientState.None);
			return;
		}

		const patientID = editablePatient.patientID;
		setEditablePatient(null);

		const result = await sharePatientWithTherapist(patientID, email);
		if (result.result === 200) {
			setAddState(() => AddPatientState.None);
		}
		else {
			console.error(result.message);
			setErrorMessage(result.message);
			setAddState(() => AddPatientState.Failed);
		}
	}

	const confirmDeletePatient = async () => {
		if (editablePatient == null) {
			setAddState(() => AddPatientState.None);
			return;
		}
		const patientID = editablePatient.patientID;
		setEditablePatient(null);
		setPatients((patients: any[]) => patients.filter(item => item.id !== patientID));
		await deletePatient(patientID, user.uid);
		setAddState(() => AddPatientState.None);
	}

	const cancelAddPatient = () => {
		setEditablePatient(null);
		setAddState(() => AddPatientState.None);
	}

	const handlePatientSelected = useCallback((patient: any) => {
		setEditablePatient(() => patient.id);
		onPatientSelected(
			{
				id: patient.id,
				settings: defaultPatientSettings,
				...patient.doc,
			} as Patient);
	}, [onPatientSelected]);

	useEffect(() => {
		return subscribeToAllPatients(user?.uid, setUnmanagedPatients);
	}, [user]);

	useEffect(() => {
		return subscribeToAllRemotePatients(user?.uid, setRemotePatients);
	}, [user]);

	useEffect(() => {
		setPatients(() => [...unmanagedPatients, ...remotePatients]);
	}, [unmanagedPatients, remotePatients]);

	return (
		<>
			<Box display="flex" justifyContent="space-between" marginTop={2}>
				<TextField
					color="primary"
					type="text"
					inputMode="text"
					label={translate("[patients_search]")}
					value={searchInitials}
					onChange={handleSearchInitialsChange} />

				{loadingAddPatient ? (
					<>
						<CircularProgress size={36} />
					</>) : (
					<Button
						disabled={loadingAddPatient}
						onClick={addPatient}>
						{translate("[patients_addpatient]")}
					</Button>
				)
				}
			</Box>
			<br />
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<List>
					{filteredPatients.map((patient: any) => (
						<PatientRow
							name={getPatientName(patient)}
							initials={getPatientInitials(patient)}
							deleteable={!patient.doc.userID}
							sharedBy={patient.doc.sharedBy}
							key={patient.id}
							onDelete={() => handleDeletePatient(patient)}
							onChangeInitials={() => handleRenamePatient(patient)}
							onShare={() => handleSharePatient(patient)}
							onSelect={() => handlePatientSelected(patient)}
							index={getPatientIndex(patient)} />
					))}
				</List>
			</Paper>
			< AlertDialog
				open={addState === AddPatientState.Deleting}
				title={`${translate("[patients_remove]")} ${editablePatient?.patientName}`}
				okTitle={translate("[patients_accept]")}
				cancelTitle={translate("[patients_cancel]")}
				onOk={confirmDeletePatient}
				onCancel={cancelAddPatient}>
				{translate("[patients_confirmdelete]")}
			</AlertDialog>
			< AlertDialog
				open={addState === AddPatientState.Failed}
				title={translate("[patients_cantaddpatient]")}
				cancelTitle={translate("[patients_cancel]")}
				onCancel={cancelAddPatient}>
				{translate(errorMessage)}
			</AlertDialog>
			< AlertDialog
				open={addState === AddPatientState.Adding}
				title={translate("[patients_addpatient]")}
				okTitle={translate("[patients_add]")}
				cancelTitle={translate("[patients_cancel]")}
				onOk={() => registerPatient(initials)}
				onCancel={cancelAddPatient}>
				<TextField
					autoFocus
					inputMode="text"
					margin="dense"
					label={translate("[patients_initials]")}
					type="text"
					value={initials}
					onChange={handleInitialsChange}
					fullWidth
				/>
			</AlertDialog>
			< AlertDialog
				open={addState === AddPatientState.Renaming}
				title={translate("[patients_renamepatient]")}
				okTitle={translate("[patients_rename]")}
				cancelTitle={translate("[patients_cancel]")}
				onOk={() => confirmRenamePatient(initials)}
				onCancel={cancelAddPatient}>
				<TextField
					autoFocus
					inputMode="text"
					margin="dense"
					label={translate("[patients_initials]")}
					type="text"
					value={initials}
					onChange={handleInitialsChange}
					fullWidth
				/>
			</AlertDialog>
			< AlertDialog
				open={addState === AddPatientState.Sharing}
				title={translate("[patients_shareemail_title]")}
				okTitle={translate("[patients_share]")}
				cancelTitle={translate("[patients_cancel]")}
				onOk={() => confirmSharePatient(shareEmail)}
				onCancel={cancelAddPatient}
				okDisabled={!shareUnderstanding}
				>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="left"
					gap={2}
				>
					<TextField
						autoFocus
						required
						inputMode="email"
						margin="dense"
						label={translate("[patients_shareemail]")}
						type="email"
						value={shareEmail}
						onChange={(event) => setShareEmail(event.target.value)}
						fullWidth
					/>
					<Box>
						<Checkbox name="shareunderstanding"
							checked={shareUnderstanding}
							onChange={(event) => setShareUnderstanding(event.target.checked)}
						/>
						<Typography variant="caption">
							{translate("[patient_share_understanding]")}
						</Typography>
					</Box>
				</Box>
			</AlertDialog>
		</>
	);

});

export default Patients;
