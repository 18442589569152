import { ItemData } from './DataDefinitions';

export interface GonogoTryData {
	isCommisionError: boolean;
	isOmissionError: boolean;
	isError: boolean;
	reactionTime: number;
	handedness: string;
	itemData: ItemData;
}

export interface GonogoData {
	commisionErrors: number;
	omissionErrors: number;
	reactionTime: number;
	leftTriesCount: number,
	rightTriesCount: number,
	leftReactionTime: number,
	rightReactionTime: number,
	count: number,
	tries: GonogoTryData[]
}

function combineGoNogoDataData(sessionSelected: any): GonogoData {

	if (sessionSelected === undefined) {
		return {
			commisionErrors: 0,
			omissionErrors: 0,
			reactionTime: 0,
			leftTriesCount: 0,
			rightTriesCount: 0,
			leftReactionTime: 0,
			rightReactionTime: 0,
			count: 0,
			tries: []
		};
	}

	const trials = sessionSelected.trials.map((trial: any): GonogoTryData => ({
		reactionTime: trial.trialTime.hasReactionTime ? trial.trialTime.reactionTime : undefined,
		handedness: trial.handedness,
		isCommisionError: trial.isError && trial.trialTime.hasReactionTime,
		isOmissionError: trial.isError && !trial.trialTime.hasReactionTime,
		isError: trial.isError,
		itemData: {
			name: trial.interactableData.item,
			color: trial.interactableData.color,
			size: trial.interactableData.size,
		}
	}
	));


	const gonogoData = trials.reduce((session: any, trial: GonogoTryData, index: number) => {
		return {
			commisionErrors: session.commisionErrors + (trial.isCommisionError ? 1 : 0),
			omissionErrors: session.omissionErrors + (trial.isOmissionError ? 1 : 0),
			reactionTime: session.reactionTime + (trial.reactionTime ? trial.reactionTime : 0),
			leftTriesCount: session.leftTriesCount + (!trial.isError && trial.handedness === "left" ? 1 : 0),
			rightTriesCount: session.rightTriesCount + (!trial.isError && trial.handedness === "right" ? 1 : 0),
			leftReactionTime: session.leftReactionTime + (!trial.isError && trial.handedness === "left" ? trial.reactionTime : 0),
			rightReactionTime: session.rightReactionTime + (!trial.isError && trial.handedness === "right" ? trial.reactionTime : 0),
			count: session.count + 1
		}
	}, {
		commisionErrors: 0,
		omissionErrors: 0,
		reactionTime: 0,
		leftTriesCount: 0,
		rightTriesCount: 0,
		leftReactionTime: 0,
		rightReactionTime: 0,
		count: 0
	});


	gonogoData.leftReactionTime = gonogoData.leftReactionTime / gonogoData.leftTriesCount;
	gonogoData.rightReactionTime = gonogoData.rightReactionTime / gonogoData.rightTriesCount;

	return { ...gonogoData, tries: trials };
}

export default combineGoNogoDataData;