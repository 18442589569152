import { useMemo, useState } from "react";
import { CachedDocument } from "../adapters/Firebase/Firebase";
import AIAssistant, { serializeAIData } from "./AIAssistant";
import extractFruitsData, { FruitsData } from "./FruitsData";
import AverageFruitsCard from "./Graphs/AverageFruitsCard";
import FruitsDistributionGraph from "./Graphs/FruitsDistributionGraph";
import GraphContainer from "./Graphs/GraphContainer";
import HandednessGraph from "./Graphs/HandednessGraph";

interface FruitsGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}


const aiPrompt = (data: any) => "Here is the data of a session of a neurological patient using a VR exercise in which they had to pick up the mature oranges form the tree and place them in a basket. " +
	"Note that depending on the modifiers, the user might need to just touch, grab or even twist the orange in the branch to pick them up. " +
	"The time from the previous release to the next pick up is very important to understand how long it takes the user to pick up the oranges, moreover the coordinates to know if they haver more difficitulties pick up fruits on the top, the bottom, the left or the right of the tree. " +
	"Please elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it. " +
	`\n${serializeAIData(data)}`;
	
function FruitsSession(props: FruitsGraphData) {
	const session: CachedDocument = props.sessionDoc;

	const [sessionData, setSessionData] = useState<FruitsData | undefined>(undefined);

	useMemo(() => {
		const data = extractFruitsData(session.doc);
		setSessionData(data);
	}, [session])

	if (sessionData === undefined) {
		return <></>;
	}

	return (
		<>
			<GraphContainer order={2} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<HandednessGraph data={sessionData.handedness} />
			</GraphContainer>

			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<FruitsDistributionGraph data={sessionData} />
			</GraphContainer>

			<GraphContainer order={4} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AverageFruitsCard data={sessionData} />
			</GraphContainer>

			<GraphContainer order={6} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AIAssistant prompt={aiPrompt(sessionData)} />
			</GraphContainer >
		</>);
}

export default FruitsSession;
