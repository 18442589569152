import { Box, Typography } from "@mui/material";
import { GonogoData } from "../GonogoData";
import { useTranslation } from "../../contexts/TranslationContext";

interface AverageCardData {
	data: GonogoData
}


function AveragegGonogoCard(props: AverageCardData) {
	const { translate } = useTranslation();
	const data: GonogoData = props.data;
	return (<>
		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />
		<Box><Typography>{translate("[graph_multiobject_avg_reaction_time]")}</Typography></Box>
		<Box><Typography variant="h4">{(props.data.reactionTime / props.data.count).toFixed(2)} sec</Typography></Box>

		<Box><Typography>{translate("[graph_multiobject_error_rate]")}</Typography></Box>
		<Box><Typography variant="h4">
			{(((data.commisionErrors + data.omissionErrors) / data.count) * 100).toFixed(1)}%
		</Typography></Box>
		<br />
		<Box style={{ justifyContent: "space-between", display: "flex" }}>
			<Typography><strong>{translate("[graphs_gonogo_commision_errors]")}</strong> {data.commisionErrors}</Typography>
			<Typography><strong>{translate("[graphs_gonogo_omission_errors]")}</strong> {data.omissionErrors}</Typography>
		</Box>
	</>);
}

export default AveragegGonogoCard;