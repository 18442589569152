import { RemoteControlTag, toRemoteControlMessage } from "../features/remoteControl/remoteControlMessage";


export interface Patient {
	id: string;
	userID?: string;
	index?: number;
	settings: PatientSettingsData
	initials?: string;
}

export interface PatientSettingsData {
	readingSpeed: number;
	spacing: number;
	vibrance: number;
	hands: number;
	hemiparesis: number;
	tremors: boolean;
	instructionsMode: number;
	hideButton: boolean;
	heightOffset: number;
	tutorial: boolean;
	autoGrab: boolean;
	lateralityPosition: number;
	innactivityEnabled: boolean;
}

export const defaultPatientSettings: PatientSettingsData = {
	readingSpeed: 1,
	spacing: 1,
	vibrance: 0,
	hands: 0,
	hemiparesis: 1,
	tremors: false,
	instructionsMode: 1,
	hideButton: false,
	heightOffset: 0,
	tutorial: true,
	autoGrab: false,
	lateralityPosition: 1,
	innactivityEnabled: false,
};

export function getPatientInitials(patient: any) {
	return !!patient.doc?.initials ? patient.doc.initials :
		`@${patient.id.slice(0, 2).toUpperCase()}`;
}

export function getPatientName(patient: any) {
	return !!patient.doc.email ? 
		patient.doc.email 
		: patient.id.substring(0, 5);
}

export function getPatientIndex(patient: any) {
	return patient.doc.index ? `${patient.doc.index}` : "";
}

export function serializeSettings(data: PatientSettingsData): string[] {
	const safeData = {...defaultPatientSettings, ...data } as PatientSettingsData;
	return [
		toRemoteControlMessage(RemoteControlTag.readingSpeed, safeData.readingSpeed.toString()),
		toRemoteControlMessage(RemoteControlTag.itemsSpacing, safeData.spacing.toString()),
		toRemoteControlMessage(RemoteControlTag.vibranceRequested, safeData.vibrance.toString()),
		toRemoteControlMessage(RemoteControlTag.handMaterialRequested, safeData.hands.toString()),
		toRemoteControlMessage(RemoteControlTag.hemiparesis, safeData.hemiparesis.toString()),
		toRemoteControlMessage(RemoteControlTag.smoothTremors, safeData.tremors.toString()),
		toRemoteControlMessage(RemoteControlTag.enableTTS, safeData.instructionsMode.toString()),
		toRemoteControlMessage(RemoteControlTag.hideButton, safeData.hideButton.toString()),
		toRemoteControlMessage(RemoteControlTag.heightOffset, safeData.heightOffset.toString()),
		toRemoteControlMessage(RemoteControlTag.tutorial, safeData.tutorial.toString()),
		toRemoteControlMessage(RemoteControlTag.autoGrab, safeData.autoGrab.toString()),
		toRemoteControlMessage(RemoteControlTag.lateralityPosition, safeData.lateralityPosition.toString()),
		toRemoteControlMessage(RemoteControlTag.innactivityEnabled, safeData.innactivityEnabled.toString()),
	];
}