import { Add, Remove } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import ModifierControl from './ModifierControl';

interface MinMaxControlProps {
	title: string;
	description?: string,
	min: number;
	max: number;
	step?: number;
	value?: number;
	onValueChanged: (value: number) => void
}

function MinMaxControl(props: MinMaxControlProps) {

	const min: number = props.min;
	const max: number = props.max;
	const step = props.step ?? 1;
	const value: number = props.value ?? min;

	const onValueChanged = props.onValueChanged;
	const [prevValue, setPrevValue] = useState<number | null>(null);

	useEffect(() => {
		if (prevValue !== value) {
			onValueChanged(value);
			setPrevValue(value);
		}
	}, [value, prevValue, onValueChanged]);

	const handleOnRemove = () => {
		onValueChanged(Math.max(min, value - step));
	};

	const handleOnAdd = () => {
		onValueChanged(Math.min(max, value + step));
	}

	return (
		<ModifierControl
			title={props.title}
			description={props.description}>
			<Box display="flex" justifyContent="left" alignItems="center" >
				<Button onClick={handleOnRemove} variant="outlined" disabled={value <= min}>
					<Remove />
				</Button>
				<Typography width={100} textAlign="center" variant='h5'>
					{step < 1 ? value.toFixed(2) : value.toFixed(0)}
				</Typography>
				<Button onClick={handleOnAdd} variant="outlined" disabled={value >= max}>
					<Add />
				</Button>
			</Box>
		</ModifierControl>
	)
}


export default MinMaxControl;