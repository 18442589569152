import { Legend, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";
import { useTheme } from '@mui/material/styles';
import { MultiObjectData } from "../MultiObjectData";
import { renderBlackLegend } from "./legend";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";


interface CoordinateDistributionGraphData {
	data: MultiObjectData
}

function CoordinatesDistributionGraph(props: CoordinateDistributionGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;

	return (<>
		<Typography><strong>{translate("[graphs_distribution_attempts]")}</strong></Typography>
		<ResponsiveContainer height={300}>
			<ScatterChart
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
				<XAxis hide={true} domain={[-1, 1]} type="number" dataKey="x" />
				<YAxis hide={true} domain={[-1, 1]} type="number" dataKey="y" />
				<ReferenceLine x="0"></ReferenceLine>
				<Legend formatter={renderBlackLegend} />
				<Scatter name={translate("[graphs_distribution_hits]")}
					data={props.data.coordinates.filter((entry: any) => !entry.isError)}
					fill={colors.primary.light} />
				<Scatter name={translate("[graphs_distribution_errors]")}
					data={props.data.coordinates.filter((entry: any) => entry.isError)}
					fill={colors.error.light} />
			</ScatterChart>
		</ResponsiveContainer>
		<Box style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
			<Box style={{ textAlign: "center", flexDirection: "column" }}>
				<strong>{translate("[graphs_distribution_left_reaction_time]")}</strong>
				<p>
					{(props.data.leftReactionTime / props.data.leftTriesCount).toFixed(2)} sec
				</p>
			</Box>
			<Box style={{ textAlign: "center", flexDirection: "column" }}>
				<strong>{translate("[graphs_distribution_right_reaction_time]")}</strong>
				<p>
					{(props.data.rightReactionTime / props.data.rightTriesCount).toFixed(2)} sec
				</p>
			</Box>
		</Box>
	</>);
}

export default CoordinatesDistributionGraph;