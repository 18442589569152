import { useMemo, useState } from "react";
import GraphContainer from "./Graphs/GraphContainer";
import ViewDirectionGraph from "./Graphs/ViewDirectionGraph"
import LateralFeedbackGraph from "./Graphs/LateralFeedbackGraph"
import AverageBirdsCard from "./Graphs/AverageBirdsCard";
import { CachedDocument } from "../adapters/Firebase/Firebase";
import extractBirdsData from "./BirdsData";
import { BirdsData } from "./BirdsData";
import AIAssistant, { serializeAIData } from "./AIAssistant";

interface BirdsGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}

const aiPrompt = (data: any) =>
	"Here is the data of a session of a neurological patient using a VR exercise in which multiple birds fly and enter three trees placed in front of the user. The user must count how many birds entered the trees." +
	`\n${serializeAIData(data)}` +
	"\n- Note that depending on the countingMode, the user has to count all birds in total, or how many birds entered each one of the three trees, or how many birds entered the left or right tree." +
	"\n- The viewOrientation represents percentage of time the user spent in a certain Yaw orientation. They are organised in batches of 5 degrees starting at angle 0 (forward) up to 360. Therapists are very interested about wheter the patient looks more left or right." +
	"\nPlease elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it."

function BirdsSession(props: BirdsGraphData) {
	const session: CachedDocument = props.sessionDoc;

	const [sessionData, setSessionData] = useState<BirdsData | undefined>(undefined);

	useMemo(() => {
		const data = extractBirdsData(session.doc);
		setSessionData(data);
	}, [session])

	if (sessionData === undefined) {
		return <></>;
	}

	return (
		<>
			<GraphContainer order={2} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<ViewDirectionGraph data={sessionData.viewOrientation} />
			</GraphContainer>
			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<LateralFeedbackGraph data={sessionData.lateralFeedback} />
			</GraphContainer>
			<GraphContainer order={4} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AverageBirdsCard data={sessionData} />
			</GraphContainer>

			<GraphContainer order={5} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AIAssistant prompt={aiPrompt(sessionData)} />
			</GraphContainer >
		</>);
}

export default BirdsSession;
