import { DocumentData, collection, getDocsFromCache, getDocsFromServer, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';

import { useMemo, useState } from "react";
import { CachedDocument } from '../../adapters/Firebase';

export interface useSessionsProps {
	patientID: string;
	limit: number;
}

export interface useSessionsResult {
	sessions: CachedDocument[];
	isLoading: boolean;
}


export function useSessions(props: useSessionsProps): useSessionsResult {

	const [sessions, setSessions] = useState<CachedDocument[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const patientID = props.patientID;
	const limitDocs = props.limit;

	useMemo(() => {
		async function fetchData(patientID: string, limitDocs: number) {
			setIsLoading(true);
			const firestore = getFirestore();
			const collectionName = 'mockSessions';

			let allDocuments: DocumentData[] = [];

			const limitConstraints = limitDocs > 0 ? [limit(limitDocs)] : [];

			if (limitDocs > 0) {
				const cachedSessions = query(
					collection(firestore, collectionName),
					where("userID", "==", patientID),
					orderBy("timestamp", "desc"),
					...limitConstraints
				);

				const cachedDocuments = await getDocsFromCache(cachedSessions);
				allDocuments = [...cachedDocuments.docs];
			}

			const serverConstraints = allDocuments.length == 0 ? [] :
				[where("timestamp", ">", allDocuments[0].data().timestamp)];
				
			const serverSessions = query(
				collection(firestore, collectionName),
				where("userID", "==", patientID),
				orderBy("timestamp", "desc"),
				...limitConstraints,
				...serverConstraints
			);
			const serverDocuments = await getDocsFromServer(serverSessions);

			allDocuments = [...serverDocuments.docs, ...allDocuments];

			const data = allDocuments.map((doc): CachedDocument => {
				return {
					id: doc.id,
					doc: doc.data(),
					ref: doc.ref,
				}
			});

			setSessions(data);
			setIsLoading(false);
		}

		fetchData(patientID, limitDocs);

	}, [patientID, limitDocs]);


	return { sessions: sessions, isLoading: isLoading }
}

