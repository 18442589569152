import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { defaultPatientSettings, PatientSettingsData } from "../../../data/Patient";
import MinMaxControl from "../controls/MinMaxControl";
import ToggleControl from "../controls/ToggleControl";
import ToggleGroupControl from "../controls/ToggleGroupControl";
import QuickActionButton from "../quickActions/QuickActionButton";
import { quickActionDataCalibrate, quickActionDataRecenter } from "../quickActions/QuickActions";
import { RemoteControlTag, toRemoteControlMessage } from '../remoteControlMessage';

interface PatientSettingsProps {
	data: PatientSettingsData;
	onSettingsUpdated: (settings: PatientSettingsData) => void;
	sendMessage: (message: string) => void;
}

function PatientSettings(props: PatientSettingsProps) {

	const [settings, setSettings] = useState<PatientSettingsData>({...defaultPatientSettings, ...props.data});
	const [prevSettings, setPrevSettings] = useState<PatientSettingsData>({...defaultPatientSettings, ...props.data});

	const onSettingsUpdated = props.onSettingsUpdated;

	useEffect(() => {
		if (settings !== prevSettings) {
			onSettingsUpdated(settings);
			setPrevSettings(settings);
		}
	}, [settings, prevSettings, onSettingsUpdated]);

	return (
		<Box>
			<Box marginBottom={2} display="flex" flexDirection="row" gap={2} >
				<QuickActionButton
					data={quickActionDataRecenter} onClick={() => {
						props.sendMessage(quickActionDataRecenter.message);
					}} />
				<QuickActionButton
					data={quickActionDataCalibrate} onClick={() => {
						props.sendMessage(quickActionDataCalibrate.message);
					}} />
			</Box>
			<MinMaxControl
				title="[settings_heightOffset]"
				description="[settings_heightOffset_description]"
				min={-1}
				max={1}
				step={0.02}
				value={settings?.heightOffset}
				onValueChanged={(index: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, heightOffset: index };
						}
						return prev;
					});
				}} />

			<ToggleGroupControl
				title="[settings_lateralityposition_title]"
				description="[settings_lateralityposition_description]"
				items={["[settings_lateralityposition_left]", "[settings_lateralityposition_right]"]}
				value={settings?.lateralityPosition}
				onValueChanged={(index: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, lateralityPosition: index };
						}
						return prev;
					});
				}} />

			<ToggleGroupControl
				title="[instructionsmode_title]"
				description="[instructionsmode_description]"
				items={["[instructionsmode_screen]", "[instructionsmode_screenandlocute]", "[instructionsmode_locute]"]}
				value={settings?.instructionsMode}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, instructionsMode: value };
						}
						return prev;
					});
				}} />


			<ToggleControl
				title="[settings_hidebutton]"
				description="[settings_hidebutton_description]"
				value={settings?.hideButton}
				onValueChanged={(value: boolean) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, hideButton: value };
						}
						return prev;
					});
				}} />

			<ToggleControl
				title="[settings_tutorial]"
				description="[settings_tutorial_description]"
				value={settings?.tutorial}
				onValueChanged={(value: boolean) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, tutorial: value };
						}
						return prev;
					});
				}} />
			<ToggleControl
				title="[settings_innactivity]"
				description="[settings_innactivity_description]"
				value={settings?.innactivityEnabled}
				onValueChanged={(value: boolean) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, innactivityEnabled: value };
						}
						return prev;
					});
				}} />

			<MinMaxControl
				title="[control_reading]"
				description="[control_reading_description]"
				min={1}
				max={3}
				value={settings?.readingSpeed}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, readingSpeed: value };
						}
						return prev;
					});
				}} />
			<MinMaxControl
				title="[control_spacing]"
				description="[control_spacing_description]"
				min={1}
				max={3}
				value={settings?.spacing}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, spacing: value };
						}
						return prev;
					});
				}} />
			<MinMaxControl
				title="[settings_saturation]"
				description="[settings_saturation_description]"
				min={0}
				max={3}
				value={settings?.vibrance}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, vibrance: value };
						}
						return prev;
					});
				}} />

			<ToggleGroupControl
				title="[settings_handstitle]"
				description="[settings_handstitle_description]"
				items={["[hands_tone_abstract]", "[hands_tone_glove]", "[hands_tone_light]", "[hands_tone_mid]", "[hands_tone_dark]"]}
				value={settings?.hands}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, hands: value };
						}
						return prev;
					});
				}} />

			<ToggleGroupControl
				title="[settings_hemiparesis]"
				description="[settings_hemiparesis_description]"
				items={["[hands_hemiparesis_left]", "[hands_hemiparesis_both]", "[hands_hemiparesis_right]"]}
				value={settings?.hemiparesis}
				onValueChanged={(value: number) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, hemiparesis: value };
						}
						return prev;
					});
				}} />
			<ToggleControl
				title="[hands_tremorstoggle]"
				description="[hands_tremorstoggle_description]"
				value={settings?.tremors}
				onValueChanged={(value: boolean) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, tremors: value };
						}
						return prev;
					});
				}} />
			<ToggleControl
				title="[settings_autograb_title]"
				description="[settings_autograb_description]"
				value={settings?.autoGrab}
				onValueChanged={(value: boolean) => {
					setSettings((prev) => {
						if (prev) {
							return { ...prev, autoGrab: value };
						}
						return prev;
					});
				}} />

			<ToggleGroupControl
				title="[settings_environmenttitle]"
				description="[settings_environment_description]"
				items={["[environment_office]", "[environment_livingroom]", "[environment_yard]", "[environment_passthrough]", "[environment_kitchen]"]}
				value={-1}
				onValueChanged={(index: number) => {
					if (index === -1) return;
					const message = toRemoteControlMessage(RemoteControlTag.stageRequested, index.toString());
					props.sendMessage(message);
				}} />
		</Box>

	);

}

export default PatientSettings;