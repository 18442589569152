import { Process } from '../data/Process';
import { Patient } from '../data/Patient';
import { RemoteClient } from '../data/RemoteClient';

export interface RemoteControlState {
	remoteClient: RemoteClient | null,
	patient: Patient | null,
	processes: ModifiedProcess[],
}

export interface ModifiedProcess {
	process: Process,
	modifiers: any
}

export const defaultRemoteControlState: RemoteControlState = {
	remoteClient: null,
	patient: null,
	processes: []
}
