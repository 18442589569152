import { Button, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import QuickActionButton, { QuickActionData } from './QuickActionButton';
import MediaPanel from './QuickActionMediaPanel';
import AlertDialog from '../../../components/atoms/Popup';
import ProcessPill from '../../../components/atoms/ProcessPill';
import { RemoteControlTag, toRemoteControlMessage } from '../remoteControlMessage';
import { ProcessStatus, TrainingData } from '../TrainingData';
import VideoStream, { PointAtMode } from './VideoStream';
import { ModifiersText } from '../../session/Modifiers';
import { ProcessesContext } from '../../../contexts/Processes.context';
import { useTranslation } from '../../../contexts/TranslationContext';

interface QuickActionsProps {
	training: TrainingData | null;
	videoStream: MediaStream | null;
	speakerOn: boolean;
	setSpeakerOn: (mute: boolean) => void;
	microphoneMuted: boolean;
	muteMicrophone: (mute: boolean) => void;
	sendMessage: (message: string) => void;
	requestTraining: () => void;
}

export const quickActionDataRecenter: QuickActionData = {
	name: "[quickactions_recenter]",
	icon: "recentre",
	message: toRemoteControlMessage(RemoteControlTag.recenter),
	variant: "contained",
};

export const quickActionDataCalibrate: QuickActionData = {
	name: "[quickactions_calibrate]",
	icon: "recentre",
	message: toRemoteControlMessage(RemoteControlTag.calibrate),
	variant: "outlined",
};

const data: QuickActionData[] = [
	quickActionDataRecenter,
	{
		name: "[quickactions_hidetablet]",
		icon: "hidemenu",
		message: toRemoteControlMessage(RemoteControlTag.hideTablet),
		variant: "outlined",
	},
	{
		name: "[quickactions_earleft]",
		icon: "leftalarm",
		message: toRemoteControlMessage(RemoteControlTag.lateralFeedback, "Left"),
		variant: "outlined",
	},
	{
		name: "[quickactions_earright]",
		icon: "rightalarm",
		message: toRemoteControlMessage(RemoteControlTag.lateralFeedback, "Right"),
		variant: "outlined",
	},
	{
		name: "[quickactions_tutorialleft]",
		icon: null,
		message: toRemoteControlMessage(RemoteControlTag.quickAction, "tutorialLeft"),
		variant: "outlined",
	},
	{
		name: "[quickactions_tutorialright]",
		icon: null,
		message: toRemoteControlMessage(RemoteControlTag.quickAction, "tutorialRight"),
		variant: "outlined",
	}
];

function QuickActions(props: QuickActionsProps) {
	const { translate, translateWithObject } = useTranslation();
	const [stopModalOpen, setStopModalOpen] = useState(false);
	const [nextModalOpen, setNextModalOpen] = useState(false);
	const sendMessage = props.sendMessage;

	const handlePlayPause = () => {
		const message = toRemoteControlMessage(
			RemoteControlTag.playPauseTraining);
		sendMessage(message);
	};

	const handleSkip = () => {
		const message = toRemoteControlMessage(
			RemoteControlTag.skipTrial);
		sendMessage(message);
	}

	const handleNext = () => {
		setNextModalOpen(true);
	}

	const handleStop = () => {
		setStopModalOpen(true);
	};

	const sendNextMessage = (save: boolean) => {
		const message = toRemoteControlMessage(
			RemoteControlTag.nextExercise, save.toString());
		sendMessage(message);
		handleCloseStopModal();
	}

	const sendStopMessage = (save: boolean) => {
		const message = toRemoteControlMessage(
			RemoteControlTag.stopTraining, save.toString());
		sendMessage(message);
		setStopModalOpen(false);
	}

	const handleCloseStopModal = () => {
		setStopModalOpen(false);
		setNextModalOpen(false);
	}

	const onLocuteSubmit = async (event: any) => {
		event.preventDefault();
		const { message } = event.target.elements;
		const remoteControlMessage = toRemoteControlMessage(
			RemoteControlTag.locuteRequested, message.value);
		sendMessage(remoteControlMessage);
	};


	const trainingInstruction = () => {
		switch (props.training?.status) {
			case ProcessStatus.Finished:
				return "[monitor_processcompleted]";
			case ProcessStatus.Initialized:
				return "[monitor_start]";
			case ProcessStatus.NotStarted:
				return "";
			default:
				return props.training?.instruction ?? "";
		}
	}

	const instruction = trainingInstruction();
	const processID = props.training?.trainingAddress.processID;
	const processes = useContext(ProcessesContext);
	const process = processID ? processes.processes?.find(p => p.id === processID) : null;
	const inCourse = props.training?.status !== ProcessStatus.Finished && !!processID;
	const isPlaying = props.training ? props.training.status === ProcessStatus.Playing : false;

	const setVideoStreaming = useCallback((enable: boolean) => {
		const message = toRemoteControlMessage(
			RemoteControlTag.videoStreaming, enable ? "true" : "false");
		sendMessage(message);
	}, [sendMessage]);

	const handleVideoPointed = (x: number, y: number, mode: PointAtMode) => {
		const message = toRemoteControlMessage(
			RemoteControlTag.pointAt, `${x};${y};${mode}`);
		sendMessage(message);
	}

	useEffect(() => {
		setVideoStreaming(true);
		return () => {
			setVideoStreaming(false);
		}
	}, [setVideoStreaming]);

	const dynamicActions = process ? process.quickActions : [];
	const quickactions: QuickActionData[] = [
		...data,
		...dynamicActions.map((action): QuickActionData => {
			return {
				name: action.title,
				icon: action.icon,
				message: toRemoteControlMessage(RemoteControlTag.quickAction, action.tag),
				variant: "outlined",
			}
		})];

	return (
		<>
			<Box marginBottom={2} >
				<Box display="flex" justifyContent="center" alignItems="center" marginBottom={2} marginTop={2} >
					<VideoStream videoStream={props.videoStream}
						speakerOn={props.speakerOn}
						setSpeakerOn={props.setSpeakerOn}
						microphoneMuted={props.microphoneMuted}
						muteMicrophone={props.muteMicrophone}
						onPointed={handleVideoPointed} />
				</Box>

				<Box display="flex" flexDirection="row" justifyContent="left" alignItems="center" marginBottom={2}
					gap={1}>
					<ProcessPill processID={processID!} />
					<ModifiersText modifiers={props.training?.trainingAddress.modifiers} processID={processID!} />
				</Box>

				<Box display="flex" flexDirection="row" alignContent="center" justifyContent="center">
					<ReactMarkdown remarkPlugins={[remarkGfm]}>
						{translateWithObject(instruction)}
					</ReactMarkdown>
				</Box>

				<Box display="flex" justifyContent="center" width="100" my={2} >
					{inCourse ?
						<MediaPanel
							onPlay={handlePlayPause}
							onPause={handlePlayPause}
							onStop={handleStop}
							onFastForward={handleSkip}
							onNext={handleNext}
							isPlaying={isPlaying}
						/> :
						<Button onClick={props.requestTraining} variant="contained" >
							{translate("[monitor_select]")}
						</Button>
					}
				</Box>

				<Grid container spacing={2}>
					{quickactions.map((value: QuickActionData, index: number) => {
						return (
							<Grid item xs={6} sm={3} xl={2} key={index} >
								<QuickActionButton data={value} key={index}
									onClick={() => props.sendMessage(value.message)} />

							</Grid>
						);
					})}
				</Grid>
			</Box >

			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				maxWidth={600}
				margin="0 auto"
				component="form"
				onSubmit={onLocuteSubmit}
				sx={{
					'& .MuiTextField-root': { m: 1, width: '100%' },
					'& .MuiButtonBase-root': { m: 1 }
				}}
			>
				<Typography variant="subtitle1" gutterBottom>
					{translate("[quickaction_locute_title]")}
				</Typography>
				<TextField
					name="message"
					label={translate("[quickaction_locute_message]")}
					variant="outlined"
					type="text"
					required
					inputProps={{ maxLength: 100 }}
				/>
				<Button type="submit" variant="contained" color="primary">
					{translate("[quickaction_locute_action]")}
				</Button>
			</Box>

			<AlertDialog
				open={!!stopModalOpen || !!nextModalOpen}
				title=""
				okTitle={translate("[trainer_stop_save]")}
				onOk={!!stopModalOpen ? () => sendStopMessage(true) : () => sendNextMessage(true)}
				cancelTitle={translate("[trainer_stop_discard]")}
				onCancel={!!stopModalOpen ? () => sendStopMessage(false) : () => sendNextMessage(false)}
				onClose={handleCloseStopModal}>
				<ReactMarkdown>
					{!!stopModalOpen ? translate("[trainer_stop]") : translate("[trainer_next]")}
				</ReactMarkdown>
			</AlertDialog>
		</>
	)
}


export default QuickActions;