import {  Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import PricingTable from './PricingTable';
import { useTranslation } from '../../contexts/TranslationContext';

interface PricingPageProps {
	hideTitle?: boolean,
}

function PricingPage(props: PricingPageProps) {
	const { translate } = useTranslation();
	return (
		<>
			{!props.hideTitle &&
				<Typography variant="h3" gutterBottom>
					{translate("[pricing_buylicense]")}
				</Typography>
			}

			<Typography variant="subtitle1" gutterBottom component="div">
				<ReactMarkdown>
					{translate("[pricing_subtitle]")}
				</ReactMarkdown>
			</Typography>

			<PricingTable />
		</>
	);
}

export default PricingPage;
