export const pricingData =
{
	patient:
		{
			title: "[pricing_patient_title]",
			description: "[pricing_patient_description]",
			price: "[pricing_patient_price]",
			features:
				[
					"[pricing_patient_features_1]",
					"[pricing_patient_features_2]",
				],
		},
		therapist:{
			title: "[pricing_therapist_title]",
			description: "[pricing_therapist_description]",
			price: "[pricing_therapist_price]",
			features:
				[
					"[pricing_therapist_features_1]",
					"[pricing_therapist_features_2]",
					"[pricing_therapist_features_3]",
					"[pricing_therapist_features_4]",
				],
		},
		centre:{
			title: "[pricing_centre_title]",
			description: "[pricing_centre_description]",
			price: "[pricing_centre_price]",
			features:
				[
					"[pricing_centre_features_1]",
					"[pricing_centre_features_2]",
					"[pricing_centre_features_3]",
					"[pricing_centre_features_4]",
					"[pricing_centre_features_5]",
				],
		},
		custom:{
			title: "[pricing_custom_title]",
			description: "[pricing_custom_description]",
			price: "[pricing_custom_price]",
			features:
				[
					"[pricing_custom_features_1]",
					"[pricing_custom_features_2]",
					"[pricing_custom_features_3]",
					"[pricing_custom_features_4]"
				],
		},
}