import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import { DashboardSidebar } from './DashboardSidebar';
import { useLocation } from 'react-router-dom';
import { PremiumUser } from '../../features/login/PremiumUser';

interface DashboardLayoutData {
	user: PremiumUser | null,
	onLogout: any,
	onLogin: any,
	children: any
}

const sideBarSize = 280;
const breakSize = "lg";

export const DashboardLayout = memo((props: DashboardLayoutData) => {
	const theme = useTheme();
	const location = useLocation();
	const isBigScreen = useMediaQuery(theme.breakpoints.up(breakSize));
	const [isSidebarOpen, setSidebarOpen] = useState(isBigScreen);

	useEffect(()=>
	{
		setSidebarOpen(false);
	},[location.pathname]);

	return (
		<>
			<Box
				className="SidebarSpace"
				style={{
					display: 'flex',
					flex: '1 1 auto',
					maxWidth: '100%',
					paddingTop: 60,
					paddingLeft: (isBigScreen ? sideBarSize : 0)
				}}>
				<Box
					style={{
						display: 'flex',
						flex: '1 1 auto',
						flexDirection: 'column',
						width: '100%'
					}}
				>
					{props.children}
				</Box>
			</Box>

			{!isBigScreen &&
				<IconButton
					onClick={() => setSidebarOpen(true)}
					style={{
						position: "absolute",
						backgroundColor: "white",
						top: 10,
						left: 10,
						width: 50,
						height: 50
					}}
				>
					<MenuIcon fontSize="small" />
				</IconButton>}

			<div className="Sidebar">
				<DashboardSidebar
					user={props.user}
					onClose={() => setSidebarOpen(false)}
					onLogout={props.onLogout}
					onLogin={props.onLogin}
					keepOpen={isBigScreen}
					open={isSidebarOpen}
					width={sideBarSize}
				/>
			</div>

		</>
	);
});
