
export enum DeliveryStatus {
	Correct = 0,
	Raw = 1 << 0,
	Burnt = 1 << 1,
	IncorrectRecipe = 1 << 2,
	IncorrectTray = 1 << 3,
	IncorrectOrder = 1 << 4,
	Discarded = 1 << 5,
	TimedOut = 1 << 6,
	Ammended = 1 << 7
}

export enum IngredientType {
	Chicken,
	Beef,
	Tomato,
	Onion,
	Pepper,
	Mushroom,
	Courgette
}

export interface DeliveryData {
	time: number;
	timeSinceStart: number;
	status: DeliveryStatus;
	ingredients: IngredientType[];
}

export interface RingData {
	time: number;
	isTarget: boolean;
	answered: boolean;
}

export interface GrillData {
	totalSeconds: number
	deliveries: DeliveryData[];
	rings: RingData[];
}

function extractGrillData(sessionSelected: any): GrillData {
	
	const blocks = sessionSelected.blocks;
	const stringToIngredient = (ing: any) => {
		const str: keyof typeof IngredientType = ing;
		return IngredientType[str];
	}
	
	const deliveries = blocks.deliveredSkewers.map((entry: any): DeliveryData => ({
		time: entry.creationTime,
		timeSinceStart: entry.timeSinceStart,
		status: entry.delivery,
		ingredients: entry.ingredients.map((ing : string) => stringToIngredient(ing))
	}
	));

	const rings = blocks.ringResults.map((entry: any): RingData => ({
		time: entry.time,
		isTarget: entry.isTarget,
		answered: entry.answered,
	}
	));

	return {
		totalSeconds: sessionSelected.modifiers.timeDuration * 60,
		deliveries: deliveries,
		rings: rings,
	}
}


export default extractGrillData;