import { initializeApp } from "firebase/app";
import { addDoc, collection, connectFirestoreEmulator, deleteDoc, doc, DocumentData, DocumentReference, getDoc, getDocs, getDocFromCache, getDocFromServer, getFirestore, onSnapshot, orderBy, query, QueryConstraint, serverTimestamp, Unsubscribe, where, limit, getDocsFromCache, getDocsFromServer, initializeFirestore, FirestoreSettings, persistentLocalCache, persistentMultipleTabManager, setDoc, Timestamp, Query, QuerySnapshot } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { FIREBASE_CONFIG } from './Firebase.config';
import { InitializeFBFunctions } from './Functions';
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { InitializeFBRemoteConfig } from "../RemoteConfig";

export interface CachedDocument {
	id: string;
	ref: DocumentReference<DocumentData>;
	doc: any;
};

export function Initialize(useEmulator: boolean) {

	const app = initializeApp(FIREBASE_CONFIG);

	const firestore = initializeFirestore(app, {
		localCache: persistentLocalCache({
			tabManager: persistentMultipleTabManager(),
		})
	});

	if (useEmulator) {
		connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
		connectAuthEmulator(getAuth(app), "http://127.0.0.1:9099");
		connectFunctionsEmulator(getFunctions(app, "europe-west1"), "127.0.0.1", 5001);
	}

	InitializeFBFunctions(app);
	InitializeFBRemoteConfig(app);

}

export async function getProcesses(whereQueries: QueryConstraint[] = []): Promise<DocumentData[]> {
	const firestore = getFirestore();
	const collectionName = "processes";
	const processes = query(collection(firestore, collectionName), ...whereQueries);
	const docs = await getDocs(processes);

	const list: DocumentData[] = [];
	docs.forEach((doc) => list.push({
		id: doc.id,
		doc: doc.data(),
		ref: doc.ref
	}));

	return (list);
}

export async function getLatestSession(patientID: string, processID: string): Promise<any> {
	const firestore = getFirestore();
	const collectionName = "mockSessions";

	const queryConstraints = [
		where("userID", "==", patientID),
		where("processID", "==", processID),
		orderBy("timestamp", "desc"),
		limit(1)];

	const cachedQuery = query(collection(firestore, collectionName), ...queryConstraints);
	const cachedDocs = await getDocsFromCache(cachedQuery);

	if (!cachedDocs.empty) {
		const timestamp = cachedDocs.docs[0].data().timestamp;
		queryConstraints.push(where("timestamp", ">", timestamp));
	}

	const serverQuery = query(collection(firestore, collectionName), ...queryConstraints);
	const serverDocs = await getDocsFromServer(serverQuery);

	let docs = serverDocs;
	if (docs.empty) {
		docs = cachedDocs;
	}
	if (docs.empty) {
		return null;
	}
	return docs.docs[0].data();
}

export function subscribeToAllPatients(userID: string, onPatientsUpdated: (patients: any[]) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionName = `users/${userID}`;
	const docRef = doc(firestore, collectionName);
	const queryFB = query(collection(docRef, "patients"), orderBy("index", "desc"));

	return onSnapshot(queryFB, (snapshot) => {
		const patients: any[] = [];
		snapshot.forEach(doc => {
			patients.push({ id: doc.id, doc: doc.data() });
		});
		onPatientsUpdated(patients);
	});
}

export function subscribeToAllRemotePatients(userID: string, onRemotePatientsUpdated: (patients: any[]) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionName = `users/${userID}`;
	const docRef = doc(firestore, collectionName);
	const queryFB = query(collection(docRef, "patientInvitesSent"), where("status", "==", "accepted"));

	return onSnapshot(queryFB, (snapshot) => {
		const promises = snapshot.docs.map(docSnapshot => {
			const patientId = docSnapshot.id;
			const patientDocRef = doc(firestore, `users/${patientId}/patients/self`);
			return getDoc(patientDocRef).then(patientDoc => {
				const email = docSnapshot.data().patientEmail;
				const data = {
					...patientDoc.data(),
					initials: `@${email.slice(0, 2).toUpperCase()}`,
					email: email,
				}
				return { id: patientId, doc: data };
			});
		});

		Promise.all(promises).then(remotePatients => {
			onRemotePatientsUpdated(remotePatients);
		});
	});
}

export function subscribeToAllPatientInvites(userID: string, invitesCollection: "patientInvitesSent" | "patientInvitesReceived" | "patientSharesReceived", onInvitatesUpdated: (invites: any[]) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionName = `users/${userID}`;
	const docRef = doc(firestore, collectionName);
	const queryFB = query(collection(docRef, invitesCollection), orderBy("timestamp", "desc"));

	return onSnapshot(queryFB, (snapshot) => {
		const invitations: any[] = [];
		snapshot.forEach(doc => {
			invitations.push(doc.data());
		});
		onInvitatesUpdated(invitations);
	});
}

export function subscribeToAllCentreInvites(userID: string, invitesCollection: "invitesSent" | "invitesReceived", onInvitationsUpdated: (invitations: any[]) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionName = `users/${userID}`;
	const docRef = doc(firestore, collectionName);
	const queryFB = query(collection(docRef, invitesCollection), orderBy("timestamp", "desc"));

	return onSnapshot(queryFB, (snapshot) => {
		const invitations: any[] = [];
		snapshot.forEach(doc => {
			invitations.push(doc.data());
		});
		onInvitationsUpdated(invitations);
	});
}

export async function getSession(sessionID: string): Promise<DocumentData> {
	const firestore = getFirestore();
	const collectionName = `mockSessions/${sessionID}`;
	const docRef = doc(firestore, collectionName);

	const cachedSession = await getDocFromCache(docRef);

	if (cachedSession.exists()) {
		return {
			id: cachedSession.id,
			doc: cachedSession.data(),
			ref: cachedSession.ref
		}
	}
	const serverSession = await getDocFromServer(docRef);
	return {
		id: serverSession.id,
		doc: serverSession.data(),
		ref: serverSession.ref
	};
}

export async function extractSubcollection(
	document: CachedDocument, subCollection: string) {
	if (document.doc[subCollection] !== undefined) {
		return document.doc[subCollection];
	}

	const listOfBlocks: any[] = [];
	const blocks = await getDocs(collection(document.ref, subCollection));
	blocks.forEach(block => listOfBlocks.push(block.data()));

	return listOfBlocks;
}

export async function getVRLoggedWithinLimit(userID: string, timeLimit: number): Promise<boolean> {
	const firestore = getFirestore();
	const collectionPath = `users/${userID}/vrLogins/`;

	const logingsQuery = query(collection(firestore, collectionPath),
		orderBy("timestamp", "desc"),
		limit(1));

	const predicate = (data: DocumentData) => {
		const timestamp: Timestamp = data.timestamp;
		if (!timestamp || !(timestamp instanceof Timestamp)) {
			return false;
		} 

		const date = timestamp.toDate();
		return (Date.now() - date.getTime()) <= timeLimit;
	}

	const cacheResult = await predicateFirstResult(logingsQuery, predicate);
	if (!!cacheResult) {
		return true;
	}
	const serverResult = await predicateFirstResult(logingsQuery, predicate, true);
	return !!serverResult;
}

async function predicateFirstResult(
	query: Query<DocumentData>,
	predicate: (data: DocumentData) => boolean | undefined,
	useServer?: boolean)
	: Promise<boolean | undefined> {

	const docs = await (useServer ?
		getDocsFromServer(query)
		: getDocsFromCache(query));

	if (docs.empty) {
		return undefined;
	}

	const data = docs.docs[0].data();
	return predicate(data);
}


export async function publishOffer(userID: string, offerData: object): Promise<string | null> {
	const firestore = getFirestore();
	const collectionPath = `users/${userID}/remoteControlRequests/`;
	try {
		const timestampedOfferData = {
			...offerData,
			timestamp: serverTimestamp(),
		}

		const docRef = await addDoc(collection(firestore, collectionPath), timestampedOfferData);
		return docRef.id;
	} catch (error) {
		console.error("Error publishing offer: ", error);
		return null;
	}
}

export async function publishICE(userID: string, offerID: string, iceData: object) {
	const firestore = getFirestore();
	const docName = `users/${userID}/remoteControlRequests/${offerID}/ices`;

	try {
		await addDoc(collection(firestore, docName), iceData);
	} catch (error) {
		console.error("Error publishing ICE: ", error);
	}
}

export function subscribeToAnswers(userID: string, offerID: string, onAnswer: (id: string, answer: any) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionPath = `users/${userID}/remoteControlRequests/${offerID}/answers/`;
	const query = collection(firestore, collectionPath);

	return onSnapshot(query, (querySnapshot) => {
		querySnapshot.docChanges().forEach(change => {
			if (change.type === 'added') {
				onAnswer(change.doc.id, change.doc.data());
			}
		});
	}, (error) => {
		console.error("Error listening for document changes: ", error);
	});
}

export function subscribeToICEs(userID: string, offerID: string, answerID: string, onIce: (ice: any) => void): Unsubscribe {
	const firestore = getFirestore();
	const collectionPath = `users/${userID}/remoteControlRequests/${offerID}/answers/${answerID}/ices/`;
	const query = collection(firestore, collectionPath);

	return onSnapshot(query, (querySnapshot) => {
		querySnapshot.docChanges().forEach(change => {
			if (change.type === 'added') {
				onIce(change.doc.data());
			}
		});
	}, (error) => {
		console.error("Error listening for document changes: ", error);
	});
}


export async function deleteOffer(userID: string, offerID: string) {
	const firestore = getFirestore();
	const docName = `users/${userID}/remoteControlRequests/${offerID}`;

	try {
		await deleteDoc(doc(firestore, docName));
	} catch (error) {
		console.error("Error deleting offer: ", error);
	}
}

export async function submitPatientSettings(userID: string, patientID: string, settings: {}) {
	const firestore = getFirestore();
	if (userID === patientID) {
		patientID = "self";
	}
	const documentName = `users/${userID}/patients/${patientID}`;
	await setDoc(doc(firestore, documentName), { settings: settings }, { merge: true });
}

export async function submitSchedule(userID: string, patientID: string, processes: {}) {
	const firestore = getFirestore();
	if (userID === patientID) {
		patientID = "self";
	}
	const documentName = `users/${userID}/patients/${patientID}`;
	await setDoc(doc(firestore, documentName), { schedule: processes }, { merge: true });
}

export async function getSchedule(userID: string, patientID: string) {
	const firestore = getFirestore();
	if (userID === patientID) {
		patientID = "self";
	}
	const documentName = `users/${userID}/patients/${patientID}`;
	const docRef = doc(firestore, documentName);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data().schedule ?? [];
	}
	return [];
}