export default function UserNoLicenseLogo(props: any) {

	const { width, height, background, tshirt, stroke, ...other } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
			<ellipse cx="36.4091" cy="36" rx="36.4091" ry="36" fill={background} />
			<mask id="mask0_1_2216" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="73" height="72">
				<ellipse cx="36.4091" cy="36" rx="36.4091" ry="36" fill="#000000" />
			</mask>
			<g mask="url(#mask0_1_2216)">
				<path fillRule="evenodd" clipRule="evenodd" d="M8.05301 119.711C20.8079 127.068 48.3302 127.813 56.772 122.766C56.7025 122.488 56.0792 87.9619 56.0792 87.9619L69.2092 81.0078C69.4335 78.911 69.7571 76.4388 70.1401 74.684L69.9675 74.1682C66.8452 65.0501 57.8597 47.9068 34.1106 45.9267C14.5297 44.2944 -3.59032 61.2915 -5.05517 81.8318L12.0228 87.6517C12.0228 87.6517 8.53931 116.45 8.05301 119.711Z" fill={tshirt} />
				<path d="M8.53776 54.7409C15.8857 48.5819 24.806 45.5833 33.6606 46.314C56.4031 48.1786 65.5821 63.7659 69.0292 72.8903L69.4033 72.7559C65.9224 63.5438 56.6658 47.8162 33.6944 45.9263C24.7383 45.1917 15.7086 48.2176 8.28101 54.4447C0.0871811 61.3148 -5.04166 71.1777 -5.79198 81.5062L-5.39394 81.5355C-4.65157 71.3102 0.427521 61.5447 8.53776 54.7409Z" fill={stroke} />
				<path d="M26.0074 35.8407C26.9017 40.7175 26.8816 49.4338 26.8816 49.4338C27.8138 53.6372 38.612 53.0314 40.1278 49.4338C40.171 48.8675 40.1822 48.2994 40.1613 47.7319C40.1613 46.7404 40.1278 45.6858 40.1278 45.6858C40.1278 45.6858 48.2142 45.1192 48.7507 40.1204C49.0548 37.2876 48.3036 27.5167 47.8766 23.0431C44.1989 23.7992 39.8484 22.0908 39.8484 22.0908C41.2613 25.1415 33.9149 27.6758 30.7112 23.237C30.7112 23.237 34.8852 31.1579 29.0748 33.9144C28.1067 30.4454 25.9448 29.5846 24.3195 30.1294C23.6395 30.365 23.0706 30.8336 22.7189 31.4478C22.3673 32.062 22.2567 32.78 22.408 33.4677C22.8552 35.8277 26.0074 35.8407 26.0074 35.8407Z" fill="white" />
				<path d="M29.2728 33.5454C35.0641 30.7156 30.9033 22.6186 30.9033 22.6186C34.0952 27.161 41.4146 24.5742 40.0068 21.4456C40.0068 21.4456 44.3414 23.1939 48.0056 22.4201C49.9167 22.0187 51.6474 20.9305 52.4805 18.5466C54.7592 12.0373 48.2729 10.6704 46.0209 14.0867C45.4084 15.0166 44.1098 16.6511 44.1098 16.6511C44.1098 16.6511 44.5976 14.1625 42.3635 14.439C40.5637 14.662 41.8378 16.7916 37.2604 17.5721C35.7146 17.8353 32.36 16.8362 28.9075 17.4695C27.5599 17.7148 26.6801 19.4877 27.0142 20.9617C22.5883 20.9974 24.2566 28.528 24.535 29.672C26.1433 29.1145 28.3083 29.9953 29.2728 33.5454Z" fill={stroke} />
				<path d="M44.2752 33.8096L44.323 33.684L44.2275 33.5853C41.7227 30.9689 41.9947 27.3995 42.9775 26.3256C43.76 25.4691 46.1717 25.8929 47.0329 26.126L47.1665 25.6956C47.0281 25.6575 43.7552 24.7853 42.6149 26.0319C41.551 27.1955 41.2027 30.9823 43.7671 33.7714C43.5715 34.0876 42.8272 34.9373 40.6015 34.6907L40.5467 35.1391C40.8159 35.1681 41.0867 35.1823 41.3578 35.1817C43.7218 35.1817 44.2514 33.8701 44.2752 33.8096Z" fill={stroke} />
				<path d="M41.374 38.1975L41.223 37.7533C41.0404 37.8094 36.7124 39.1115 35.2121 35.1819L34.7542 35.3432C35.7406 37.9263 37.8644 38.4546 39.4426 38.4546C40.0958 38.456 40.7459 38.3694 41.374 38.1975Z" fill={stroke} />
				<path d="M38.7732 26.9142L38.8916 26.4197C38.7796 26.381 36.1432 25.4718 34.7542 27.486L35.0777 27.8182C36.2891 26.0591 38.7478 26.9065 38.7732 26.9142Z" fill={stroke} />
				<path fillRule="evenodd" clipRule="evenodd" d="M37.2365 31.9091C37.6935 31.9091 38.064 31.5428 38.064 31.0909C38.064 30.639 37.6935 30.2727 37.2365 30.2727C36.7795 30.2727 36.409 30.639 36.409 31.0909C36.409 31.5428 36.7795 31.9091 37.2365 31.9091Z" fill={stroke} />
				<path fillRule="evenodd" clipRule="evenodd" d="M46.339 31.9091C46.796 31.9091 47.1665 31.5428 47.1665 31.0909C47.1665 30.639 46.796 30.2727 46.339 30.2727C45.882 30.2727 45.5115 30.639 45.5115 31.0909C45.5115 31.5428 45.882 31.9091 46.339 31.9091Z" fill={stroke} />
				<path d="M27.3066 33.3032C27.3066 33.2847 26.496 31.33 24.8242 32.0808L24.9559 32.5787C25.3219 32.4008 25.7249 32.4006 26.0909 32.5783C26.4569 32.7559 26.7613 33.0994 26.9479 33.5455L27.3066 33.3032Z" fill={stroke} />
				<path d="M40.5464 47.4522C40.5464 46.6604 40.5148 45.8181 40.5148 45.8181C40.5148 45.8181 35.6247 46.06 33.9266 45.8181C35.4688 46.8623 38.1192 47.4975 40.5464 47.4522Z" fill={stroke} />
				<path d="M34.7338 56.0092C37.9159 55.8837 40.9549 54.9138 42.8633 53.4201L42.6134 53.1074C38.6454 56.2243 30.5643 56.4932 26.8946 53.6173L26.6447 53.9299C28.3677 55.2723 31.0278 56.0231 34.0204 56.0231C34.2569 56.0231 34.4947 56.0185 34.7338 56.0092Z" fill={stroke} />
			</g>
		</svg>


	);
}
