import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ProcessPill from '../../../components/atoms/ProcessPill';
import { getProcessDescription, Process } from '../../../data/Process';
import { useTranslation } from '../../../contexts/TranslationContext';

interface ProcessCardProps {
	process: Process;
	children: any;
}

export default function ProcessCard(props: ProcessCardProps) {
	const { translate } = useTranslation();
	const process: Process = props.process;
	const desc = translate(getProcessDescription(process.id));

	return (
		<Card>
			<CardContent>
				<ProcessPill processID={process.id} />
				<br />
				<br />
				<Typography variant="body2">
					{desc}
				</Typography>
			</CardContent>
			<CardActions>
				{props.children}
			</CardActions>
		</Card>
	);
}