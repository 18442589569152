import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CachedDocument } from "../../adapters/Firebase";
import { useTheme } from "@mui/material";

interface SessionChartProps {
    sessions: CachedDocument[]
    daysLimit: number
}


function LastUsageSessionsChart(props: SessionChartProps) {
    //generate an array per day with the amount of sassions in the last 30 days
    const today = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24));

    const sessionsCount = props.sessions.reduce((acc: number[], session: CachedDocument) => {
        const timestamp = session.doc.timestamp;
        const date = Math.floor(timestamp._seconds / (60 * 60 * 24));
        const daysSinceNow = today - date;
        if (daysSinceNow >= props.daysLimit) {
            return acc;
        }
        acc[daysSinceNow]++;
        return acc;
    }, new Array(props.daysLimit).fill(0));

    const sessionsWithDate = sessionsCount.map((count, index) => (
        {
            index: index,
            date: new Date((today - index) * 1000 * 60 * 60 * 24),
            count: count,
        }));

    const colors = useTheme().palette;

    return (
        <ResponsiveContainer height={300}>
            <LineChart data={sessionsWithDate}>
                <Line
                    dataKey={"count"}
                    connectNulls
                    type="linear"
                    strokeWidth="4"
                    stroke={colors.primary.main} />
                <XAxis dataKey={"date"}
                    reversed
                    tickFormatter={(date) => new Date(date).toLocaleDateString()} />
                <YAxis />

                <Tooltip />
            </LineChart>
        </ResponsiveContainer>
    );

}

export default LastUsageSessionsChart;