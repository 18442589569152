import { FirebaseApp } from 'firebase/app';
import { RemoteConfig, Value, fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

let firebaseRemoteConfig: RemoteConfig;

export function InitializeFBRemoteConfig(app: FirebaseApp) {
    firebaseRemoteConfig = getRemoteConfig(app);
    firebaseRemoteConfig.defaultConfig = {
        "vr_compatible_version": 0
    };
    fetchAndActivate(firebaseRemoteConfig)
        .then(() => {
        })
        .catch((err) => {
        });
}

export function getConfigValue(key: string): Value {
    return getValue(firebaseRemoteConfig, key);
}
