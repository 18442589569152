import { ReactComponent as IconAttention } from '../../media/attention.svg';
import { ReactComponent as IconMotor } from '../../media/bicep.svg';
import { ReactComponent as IconGonogo } from '../../media/gonogo.svg';
import { ReactComponent as IconMemory } from '../../media/memory.svg';

export interface WhatDoesItWorkData {
	title: string;
	description: string;
	icon: any;
}

export const whatDoesItWorkTitle = "[whatdoesitworks_title]";

export const whatDoesItWorkData = {
	items: [
		{
			title: "[whatdoesitworks_memory_title]",
			description: "[whatdoesitworks_memory_description]",
			icon: IconMemory,
		},
		{
			title: "[whatdoesitworks_inhibition_title]",
			description: "[whatdoesitworks_inhibition_description]",
			icon: IconGonogo,
		},
		{
			title: "[whatdoesitworks_attention_title]",
			description: "[whatdoesitworks_attention_description]",
			icon: IconAttention,
		},
		{
			title: "[whatdoesitworks_executivefunctions_title]",
			description: "[whatdoesitworks_executivefunctions_description]",
			icon: IconMotor,
		},
	],

	chartData: [
		{
			title: "[whatdoesitworks_chart_psicomotor]",
			value: 0.2,
		},
		{
			title: "[whatdoesitworks_chart_attention]",
			value: 0.2,
		},
		{
			title: "[whatdoesitworks_chart_memory]",
			value: 0.2,
		},
		{
			title: "[whatdoesitworks_chart_processingspeed]",
			value: 0.2,
		},
		{
			title: "[whatdoesitworks_chart_inhitibition]",
			value: 0.2,
		},
	],

	paragraph1: "[whatdoesitworks_paragraph1]",
	paragraph2: "[whatdoesitworks_paragraph2]",
	video: "https://www.youtube.com/embed/jriogLzArgM"
}