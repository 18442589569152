
export interface DishwasherData {
	totalTime: number;
	laterality?: number;
	mugs: ItemCollectionData;
	forks: ItemCollectionData;
	dishes: ItemCollectionData;
}

export interface ItemCollectionData {
	missplaced: number;
	items: ItemData[];
}

export interface ItemData {
	wellOriented: boolean;
	coordinates: Coordinates;
	hasBeenGrabbed?: boolean;
}

export interface Coordinates {
	x: number;
	y: number;
}

function extractCoordinates(coordinates: string): Coordinates {
	const coords = coordinates.substring(1, coordinates.length - 1);
	const [coordX, coordY] = coords.split(',');
	return { x: parseFloat(coordX), y: parseFloat(coordY) };
}

function extractItemCollectionData(data: any): ItemCollectionData {
	
	const items = data.items.map((entry: any): ItemData => ({
		wellOriented: entry.wellOriented,
		coordinates: extractCoordinates(entry.coordinates),
		hasBeenGrabbed: entry.hasBeenGrabbed
	}));
	
	return {
		missplaced: data.missplaced,
		items: items
	}
}

function extractDishwasherData(sessionSelected: any): DishwasherData {
	const data = sessionSelected.blocks;
	return {
		totalTime: data.totalTime,
		laterality: data.laterality,
		mugs: extractItemCollectionData(data.mugs),
		forks: extractItemCollectionData(data.forks),
		dishes: extractItemCollectionData(data.dishes)
	}
}


export default extractDishwasherData;