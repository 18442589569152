import { HandednessData, LateralFeedbackData, ViewDirectionData } from './DataDefinitions';

export function combineViewOrientationSamples(samplesA: number[], samplesB: number[]) {
	let result = new Array(samplesB.length);
	for (let i = 0; i < samplesB.length; i++) {
		result[i] = (samplesA.length > i ? samplesA[i] : 0) + samplesB[i];
	}
	return result;
};

export interface CoordinateTry {
	x: number;
	y: number;
	isError: boolean;
}

export interface MultiObjectTryData {
	errors: number,
	handedness: HandednessData,
	coordinates: CoordinateTry[],
	leftTriesCount: number,
	rightTriesCount: number,
}

export interface MultiObjectData {
	triesCount: number;
	decisionTime: number;
	reactionTime: number;
	usedClues: number;
	errors: number;
	handedness: HandednessData,
	coordinates: CoordinateTry[],
	leftTriesCount: number,
	rightTriesCount: number,
	leftReactionTime: number,
	rightReactionTime: number,
	viewOrientation: ViewDirectionData,
	lateralFeedback: LateralFeedbackData,
	count: number
}

export function getTrialData(trial: any): MultiObjectData {
	const reducedTries = trial.tries.reduce((acum: MultiObjectTryData, attempt: any) => {
		const key = attempt.handedness.toLowerCase();
		let attemptCoords = attempt.tableCoordinates.substring(1, attempt.tableCoordinates.length - 1);
		let [x, y] = attemptCoords.split(',');
		
		const isLeft = (key === "left");
		const isRight = (key === "right");
		const isError = attempt.isError;

		return {
			errors: acum.errors + (attempt.isError ? 1 : 0),
			handedness: {
				left: acum.handedness.left + (isLeft ? 1 : 0),
				right: acum.handedness.right + (isRight ? 1 : 0),
				leftErrors: acum.handedness.leftErrors + (isLeft && isError ? 1 : 0),
				rightErrors: acum.handedness.rightErrors + (isRight && isError ? 1 : 0),
			},
			coordinates: [...acum.coordinates, {
				x: parseFloat(x),
				y: parseFloat(y),
				isError: attempt.isError
			}],
			leftTriesCount: acum.leftTriesCount + (!attempt.isError && x <= 0 ? 1 : 0),
			rightTriesCount: acum.rightTriesCount + (!attempt.isError && x > 0 ? 1 : 0),
		}
	}, {
		errors: 0,
		handedness: { 
			right: 0,
			left: 0,
			leftErrors: 0,
			rightErrors: 0
		},
		coordinates: [],
		leftTriesCount: 0,
		rightTriesCount: 0,
	})

	const trialData: MultiObjectData = {
		...reducedTries,
		count: 1,
		triesCount: trial.tries.length,
		decisionTime: trial.trialTime.decisionTime,
		reactionTime: trial.trialTime.reactionTime,
		usedClues: (trial.usedClue ? 1 : 0),
		leftReactionTime: trial.trialTime.reactionTime * reducedTries.leftTriesCount,
		rightReactionTime: trial.trialTime.reactionTime * reducedTries.rightTriesCount,
		viewOrientation: {
			samples: (trial.viewOrientation != null ? trial.viewOrientation.samples : [])
		},
		lateralFeedback: trial.lateralFeedback ?? { leftFeedbackCount: 0, rightFeedbackCount: 0 },
	};

	return trialData;
};

export function combineMultigraphData(data: any[]): MultiObjectData {
	const totals = data.reduce((acum, trial) => {
		const sumarisedTrial = trial;
		return {
			triesCount: acum.triesCount + sumarisedTrial.triesCount,
			decisionTime: acum.decisionTime + sumarisedTrial.decisionTime,
			reactionTime: acum.reactionTime + sumarisedTrial.reactionTime,
			usedClues: acum.usedClues + sumarisedTrial.usedClues,
			errors: acum.errors + sumarisedTrial.errors,
			handedness: {
				left: acum.handedness.left + sumarisedTrial.handedness.left,
				right: acum.handedness.right + sumarisedTrial.handedness.right,
				leftErrors: acum.handedness.leftErrors + sumarisedTrial.handedness.leftErrors,
				rightErrors: acum.handedness.rightErrors + sumarisedTrial.handedness.rightErrors,
			},
			coordinates: [...acum.coordinates, ...sumarisedTrial.coordinates],
			leftTriesCount: acum.leftTriesCount + sumarisedTrial.leftTriesCount,
			rightTriesCount: acum.rightTriesCount + sumarisedTrial.rightTriesCount,
			leftReactionTime: acum.leftReactionTime + sumarisedTrial.leftReactionTime,
			rightReactionTime: acum.rightReactionTime + sumarisedTrial.rightReactionTime,
			viewOrientation: {
				samples: combineViewOrientationSamples(acum.viewOrientation.samples, sumarisedTrial.viewOrientation.samples)
			},
			lateralFeedback: {
				leftFeedbackCount: acum.lateralFeedback.leftFeedbackCount + trial.lateralFeedback.leftFeedbackCount,
				rightFeedbackCount: acum.lateralFeedback.rightFeedbackCount + trial.lateralFeedback.rightFeedbackCount,
			},
			count: acum.count + sumarisedTrial.count,
		}
	}, {
		triesCount: 0,
		decisionTime: 0,
		reactionTime: 0,
		usedClues: 0,
		errors: 0,
		handedness: { 
			right: 0,
			left: 0 ,
			leftErrors: 0,
			rightErrors: 0
		},
		coordinates: [],
		leftTriesCount: 0,
		rightTriesCount: 0,
		leftReactionTime: 0,
		rightReactionTime: 0,
		viewOrientation: { samples: [] },
		lateralFeedback: { leftFeedbackCount: 0, rightFeedbackCount: 0 },
		count: 0
	});

	return totals;
}