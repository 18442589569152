import { Box, Container, Typography } from '@mui/material';
import Advantages from './Advantages';
import Collaborators from './Collaborators';
import Introduction from './Introduction';
import OurTeam from './OurTeam';
import Research from './Research';
import UserReviews from './UserReviews';
import WaveSection from './WaveSection';
import WhatDoesItWork from './WhatDoesItWork';
import PricingPage from '../buying/PricingPage';
import { useTranslation } from '../../contexts/TranslationContext';
import Contact from '../users/Contact';
import DownloadDemo from './DownloadDemo';

function LandingPage() {
	const { translate } = useTranslation();
	return (
		<Container>

			<WaveSection
				marginTop={200}
				marginBottom={100}
				isTop>
				<Introduction />
			</WaveSection>

			<WhatDoesItWork />

			<DownloadDemo />

			<WaveSection
				marginTop={70}
				marginBottom={100}>
				<Advantages />
			</WaveSection>

			<Research />
			<OurTeam />

			<UserReviews />

			<Collaborators />

			<Typography variant="h2" gutterBottom>
				{translate("[pricing_buylicense]")}
			</Typography>
			<PricingPage hideTitle />

			<br />

			<Contact />
		</Container>
	);
}

export default LandingPage;
