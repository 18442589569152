import { Button, ListItem, SvgIcon } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export interface NavItemProps {
	to: string,
	title: string,
	icon: any,
	onClick?: () => void
}

export const NavItem = (props: NavItemProps) => {

	const theme = useTheme();
	
	return (
		<ListItem
			disableGutters
			sx={{
				display: 'flex',
				mb: 0.5,
				py: 0,
				px: 2
			}}
		>
			
			<NavLink to={props.to}
				style={({ isActive }) => (
					{
						width: "100%",
						borderRadius: 3,
						color: isActive ? `${theme.palette.primary.main}` : `${theme.palette.text.primary}`,
						fill: isActive ? `${theme.palette.primary.main}` : `${theme.palette.text.primary}`,
						backgroundColor: isActive ? '#00000008' : "#00000000",
					}
				)}
				onClick={props.onClick}
				>
				<Button
					disableRipple
					sx={{
						borderRadius: "inherit",
						width: '100%',
						color: "inherit",
						backgroundColor: "inherit",
						fill:"inherit",
						justifyContent: 'flex-start',
						gap: 1,
					}}
				>
					<SvgIcon component={props.icon}
						color="primary"
						viewBox="0 0 64 64"
						style={{ color: "inherit" }} />
					{props.title}
				</Button>
			</NavLink>
		</ListItem>
	);
};
