import { Pie, PieChart, ResponsiveContainer } from "recharts";
import { GrillData, DeliveryStatus } from "../GrillData";
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";

interface AverageCardData {
	data: GrillData
}

interface LabelData {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number; index: number;
	value: number;
	fill: string;
}

function AveragegGrillCard(props: AverageCardData) {
	const { translate } = useTranslation();
	const data: GrillData = props.data;

	const colors = useTheme().palette;

	const correctDeliveries = data.deliveries.filter(d => d.status === DeliveryStatus.Correct);
	const ammendedDeliveries = data.deliveries.filter(d => d.status === DeliveryStatus.Ammended);
	const incorrectDeliveries = data.deliveries.filter(d => d.status !== DeliveryStatus.Correct && (d.status & DeliveryStatus.TimedOut) === 0 && (d.status & DeliveryStatus.Discarded) === 0);
	const discardedDeliveries = data.deliveries.filter(d => (d.status & DeliveryStatus.Discarded) !== 0);
	const missingDeliveries = data.deliveries.filter(d => (d.status & DeliveryStatus.TimedOut) !== 0);

	const pieData = [
		{ name: '[graphs_grill_correct]', value: correctDeliveries.length, fill: colors.primary.main },
		{ name: '[graphs_grill_fixed]', value: ammendedDeliveries.length, fill: colors.primary.light },
		{ name: '[graphs_grill_incorrect]', value: incorrectDeliveries.length, fill: colors.error.main },
		{ name: '[graphs_grill_discarded]', value: discardedDeliveries.length, fill: colors.secondary.main },
		{ name: '[graphs_grill_out_of_time]', value: missingDeliveries.length, fill: colors.primary.light },
	];

	const averageCorrectTime = correctDeliveries.reduce((acc, curr) => {
		return acc + curr.time
	}, 0);

	const renderCustomizedLabel = (data: LabelData) => {
		const RADIAN = Math.PI / 180;
		const radius = 25 + data.innerRadius + (data.outerRadius - data.innerRadius);
		const x = data.cx + radius * Math.cos(-data.midAngle * RADIAN);
		const y = data.cy + radius * Math.sin(-data.midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				textAnchor={x > data.cx ? "start" : "end"}
				dominantBaseline="central"
			>
				{data.value > 0 && `${data.value} ${translate(pieData[data.index].name)}`}
			</text>
		);
	};


	return (<>
		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />

		<Box><Typography>{translate("[graphs_grill_avg_time]")}</Typography></Box>
		<Box><Typography variant="h4">
			{(averageCorrectTime / correctDeliveries.length).toFixed(2)} sec
		</Typography></Box>
		<br />

		<ResponsiveContainer height={200}>
			<PieChart >
				<Pie
					data={pieData}
					cx="50%"
					cy="50%"
					labelLine={false}
					outerRadius={50}
					dataKey="value"
					label={renderCustomizedLabel}
				/>
			</PieChart>
		</ResponsiveContainer>
	</>);
}

export default AveragegGrillCard;