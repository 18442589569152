import { memo, useContext, useEffect, useState } from "react";

import { CircularProgress, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import AlertDialog from "../../components/atoms/Popup";
import { UserContext } from "../../contexts/User.context";
import SentPatientInvite from "./SendPatientInvite";
import { cancelPatientInvitation, invitePatientToTherapist } from "../../adapters/Firebase/Functions";
import { subscribeToAllPatientInvites } from "../../adapters/Firebase";
import { PatientInvite } from "../center/invite";
import { useTranslation } from "../../contexts/TranslationContext";

enum AddState {
	None,
	Adding,
	Registering,
	Deleting,
	Failed
}

interface AddCodeState {
	state: AddState;
	message?: string;
}

const SendPatientInvites = memo(function () {
	const { translate } = useTranslation();
	const [addState, setAddState] = useState<AddCodeState>({ state: AddState.None });
	const [invitations, setInvitations] = useState<PatientInvite[]>([]);
	const [deletableInvite, setDeletableInvite] = useState<PatientInvite | null>(null);
	const [email, setEmail] = useState("");

	const user = useContext(UserContext)!;

	const loadingAdd = addState.state === AddState.Adding
		|| addState.state === AddState.Registering
		|| addState.state === AddState.Deleting;
	const handleDeletePatient = (invitation: PatientInvite) => {
		setDeletableInvite(invitation);
	}

	const addPatient = () => {
		setEmail(() => "");
		setAddState(() => ({ state: AddState.Adding }));
	}

	const registerPatient = async (patientEmail: string) => {
		const result = await invitePatientToTherapist(patientEmail);
		if (result.result === 200) {
			setAddState(() => ({ state: AddState.None }));
		}
		else {
			setAddState(() => ({ message: result.message, state: AddState.Failed }));
		}
	}

	const confirmDeletePatient = async () => {
		const invite = deletableInvite as PatientInvite;
		cancelDeletePatient();
		setAddState(() => ({ state: AddState.Deleting }));
		await cancelPatientInvitation(invite.patientID);
		setAddState(() => ({ state: AddState.None }));
	}

	const cancelDeletePatient = () => {
		setDeletableInvite(null);
	}

	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const cancelAddPatient = () => {
		setAddState(() => ({ state: AddState.None }));
	}

	useEffect(() => {
		const unsubscribe = subscribeToAllPatientInvites(user.uid, "patientInvitesSent", setInvitations);
		return unsubscribe;
	}, [user.uid]);

	return (
		<>
			<Typography variant="h5">{translate("[patients_link_title]")}</Typography>
			<Typography variant="body2">{translate("[patients_link_description]")}</Typography>
			<br />
			<div style={{ textAlign: "right" }}>
				{loadingAdd ? (
					<>
						<CircularProgress size={36} />
					</>) : (
					<Button
						disabled={loadingAdd}
						onClick={addPatient}
					>
						{translate("[patients_link_addpatient]")}
					</Button>
				)
				}
			</div>
			<br />
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<List>
					{Array.from(invitations).map((invitation: PatientInvite, index: number) => (
						<SentPatientInvite
							invitation={invitation}
							key={index}
							onDelete={() => handleDeletePatient(invitation)} />
					))}
				</List>
			</Paper>

			< AlertDialog
				open={!!deletableInvite}
				title={`${translate("[patients_link_remove]")} ${deletableInvite?.patientEmail}`}
				okTitle={translate("[patients_link_accept]")}
				cancelTitle={translate("[patients_link_cancel]")}
				onOk={confirmDeletePatient}
				onCancel={cancelDeletePatient}>
				{translate("[patients_link_confirmdelete]")}
			</AlertDialog>

			< AlertDialog
				open={addState.state === AddState.Failed}
				title={translate("[patients_link_cantaddpatient]")}
				cancelTitle={translate("[patients_link_cancel]")}
				onCancel={cancelAddPatient}>
				{translate(addState.message!)}
			</AlertDialog>

			< AlertDialog
				open={addState.state === AddState.Adding}
				title={translate("[patients_link_addtherapist]")}
				okTitle={translate("[patients_link_add]")}
				cancelTitle={translate("[patients_link_cancel]")}
				onOk={() => registerPatient(email)}
				onCancel={cancelAddPatient}>
				<TextField
					autoFocus
					inputMode="email"
					margin="dense"
					label={translate("[patients_link_email]")}
					type="text"
					value={email}
					onChange={handleEmailChange}
					fullWidth
				/>
			</AlertDialog>
		</>
	);

});

export default SendPatientInvites;
