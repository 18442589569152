import Avatar from "./Avatar";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { DriveFileRenameOutline, Delete } from "@mui/icons-material";
import { useTranslation } from "../../contexts/TranslationContext";


export interface PatientProps {
	initials: string;
	name: string;
	sharedBy?: string;
	deleteable: boolean;
	index: string;
	onSelect: any;
	onDelete: any;
	onChangeInitials?: any;
	onShare?: any;
}

export default function PatientRow(props: PatientProps) {
	const { translate } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenuClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		handleMenuClose();
		props.onDelete();
	};

	const handleChangeInitials = () => {
		handleMenuClose();
		props.onChangeInitials();
	};

	const handleShare = () => {
		handleMenuClose();
		props.onShare();
	}

	const menuButton = (
		<IconButton edge="end"
			color="secondary" aria-label="more" onClick={handleMenuClick}>
			<MoreVertIcon />
		</IconButton>
	);

	const menu = (
		<Menu
			anchorEl={anchorEl}
			open={open}
			onClose={handleMenuClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<MenuItem onClick={handleChangeInitials}>
				<ListItemIcon>
					<DriveFileRenameOutline fontSize="small" color="secondary" />
				</ListItemIcon>
				<ListItemText>
					{translate("[patients_rename]")}
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={handleShare}>
				<ListItemIcon>
					<ShareIcon fontSize="small" color="secondary" />
				</ListItemIcon>
				<ListItemText>
					{translate("[patients_share]")}
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={handleDelete}>
				<ListItemIcon>
					<Delete fontSize="small" color="secondary" />
				</ListItemIcon>
				<ListItemText>
					{translate("[patient_delete]")}
				</ListItemText>
			</MenuItem>
		</Menu>
	);

	const subtext = props.sharedBy ? translate(`${props.name} [patients_sharedby] ${props.sharedBy}`)
		: props.name;

	return (
		<ListItem
			divider
			secondaryAction={props.deleteable? menuButton : null}>
			<ListItemButton onClick={props.onSelect} >
				<ListItemAvatar>
					<Avatar name={props.initials} id={props.name} />
				</ListItemAvatar>
				<ListItemText primary={props.index} secondary={subtext}
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}/>
			</ListItemButton>
			{menu}
		</ListItem>
	)
}
