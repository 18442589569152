import userInVR from "../../media/landing/userInVR.png";
import userInRR from "../../media/landing/userInRR.png";

export const introductionData =
{
	"title": "[introduction_title]",
	"subtitle": "[introduction_subtitle]",
	"paragraph": "[introduction_paragraph]",
	"image1": userInVR,
	"image2": userInRR,
	"twitter": "https://twitter.com/ReCognitionRV",
	"instagram": "https://www.instagram.com/recognitionrv/",
	"facebook": "https://www.facebook.com/ReCognitionRV",
	"aplications": [
		{
			"title": "[introduction_applications1]",
		},
		{
			"title": "[introduction_applications2]",
		},
		{
			"title": "[introduction_applications3]",
		},
		{
			"title": "[introduction_applications4]",
		},
	],
}