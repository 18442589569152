import { Box,Paper, Typography, useTheme } from '@mui/material';
import { useContext} from 'react';
import { UserContext } from '../../contexts/User.context';
import { useTranslation } from '../../contexts/TranslationContext';
import { InlineWidget } from 'react-calendly';

const calendly = "https://calendly.com/re-cognition/30min";

function Calendly() {
	const { translate } = useTranslation();
	const user = useContext(UserContext);
	const colors = useTheme().palette;

	return (
		<Box>
			<Typography variant="h3" gutterBottom>
				{translate("[contact_schedule]")}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{translate("[contact_schedule_link]")}
			</Typography>
			<Paper elevation={3} sx={{ width: '100%', padding: 1 }}>
				<InlineWidget url={calendly}
					pageSettings={{
						backgroundColor: colors.background.paper,
						hideEventTypeDetails: true,
						hideLandingPageDetails: true,
						primaryColor: colors.primary.main,
						textColor: colors.text.primary
					}}
					prefill={{ email: user?.email! }} />
			</Paper>
		</Box>
	);
}

export default Calendly;
