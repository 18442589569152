
export interface UserReviewData {
  name: string,
  title: string,
  review: string,
}

export const userReviewsTitle = "Opiniones de usuarios";

export const userReviewsData: UserReviewData[] = [
  {
    "name": "[userreviews_1_name]",
    "title": "[userreviews_1_position]",
    "review": "[userreviews_1_review]",
  },
  {
    "name": "[userreviews_2_name]",
    "title": "[userreviews_2_position]",
    "review": "[userreviews_2_review]",
  },
  {
    "name": "[userreviews_3_name]",
    "title": "[userreviews_3_position]",
    "review": "[userreviews_3_review]",
  },
  {
    "name": "[userreviews_4_name]",
    "title": "[userreviews_4_position]",
    "review": "[userreviews_4_review]",
  },
  {
    "name": "[userreviews_5_name]",
    "title": "[userreviews_5_position]",
    "review": "[userreviews_5_review]",
  },
  {
    "name": "[userreviews_6_name]",
    "title": "[userreviews_6_position]",
    "review": "[userreviews_6_review]",
  },
  {
    "name": "[userreviews_7_name]",
    "title": "[userreviews_7_position]",
    "review": "[userreviews_7_review]",
  }
];