import { Typography } from "@mui/material";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Patient } from "../../data/Patient";
import Patients from "../users/Patients";
import SessionList from "./SessionList";
import SessionVisualizer from "./SessionVisualizer";
import { useTranslation } from "../../contexts/TranslationContext";

const SessionFeature = () => {
	const { translate } = useTranslation();
	const navigate = useNavigate();
	const [patientId, setPatientId] = useState<string>("");

	const handlePatientSelected = (patient: Patient) => {
		setPatientId(patient.userID ?? patient.id);
		navigate("./patient")
	}

	return (
		<>
			<Typography variant="h3" className="session-unprintable">{translate("[sessions_title]")}</Typography>
			<Routes>
				<Route path="/" element={
					<>
						<Typography variant="h5">{translate("[sessions_selectpatient]")}</Typography>
						<Patients onPatientSelected={handlePatientSelected} />
					</>
				} />
				<Route path="/patient" element={
					<SessionList patientID={patientId} />
				} />
				<Route path="/patient/:sessionIds" element={
					<SessionVisualizer />
				} />
			</Routes>
		</>
	);
}

export default SessionFeature;
