import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useEffect, useState } from "react";
import ModifierControl from './ModifierControl';
import { useTranslation } from '../../../contexts/TranslationContext';

interface SliderControlProps {
	title: string,
	description?: string,
	min: number,
	max: number,
	value?: number,
	values?: string[],

	onValueChanged: (value: number) => void
}

function SliderControl(props: SliderControlProps) {
	const { translate } = useTranslation();
	const title: string = props.title;
	const min: number = props.min;
	const max: number = props.max;
	const value: number = props.value ?? min;

	const onValueChanged = props.onValueChanged;
	const [prevValue, setPrevValue] = useState<number | null>(null);

	useEffect(() => {
		if (prevValue !== value) {
			onValueChanged(value);
			setPrevValue(value);
		}
	}, [value, prevValue, onValueChanged]);


	const handleOnChanged = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			onValueChanged(newValue);
		}
	};

	const valueLabel = !!props.values ? props.values[value] : value;

	return (
		<ModifierControl
			title={`${translate(title)}: ${valueLabel}`}
			description={props.description}>
			<Box maxWidth="230px">
			<Slider
				aria-label={title}
				value={value}
				valueLabelDisplay="off"
				marks
				step={1}
				min={min}
				max={max}
				onChange={handleOnChanged}
				color="primary"
				/>
			</Box>
		</ModifierControl>
	)
}


export default SliderControl;