import { Box } from "@mui/material";
import ContactForm from "../landing/ContactForm";
import Newsletter from "../landing/Newsletter";
import Calendly from "../landing/Calendly";

function Contact() {
	return (
		<Box
			maxWidth={600}
			margin="auto"
			display="flex"
			flexDirection="column"
		>
			<Newsletter />
			<br />
			<ContactForm />
			<br />
			<Calendly />
		</Box>
	);
}

export default Contact;