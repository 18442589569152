import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import StopIcon from '@mui/icons-material/Stop';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from '../../../contexts/TranslationContext';

export interface MediaPanelProps {
	onPlay: () => void;
	onPause: () => void;
	onStop: () => void;
	onFastForward: () => void;
	onNext: () => void;
	isPlaying: boolean;
}

const buttonSize = 80;

const MediaPanel = ({ onPlay, onPause, onStop, onFastForward, onNext, isPlaying }: MediaPanelProps) => {
	const { translate } = useTranslation();
	const handlePlayPause = () => {
		if (isPlaying) {
			onPause();
		} else {
			onPlay();
		}
	};

	return (
		<Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}
			border={2} borderRadius={2} borderColor="secondary.main">
			<IconButton onClick={onStop} color="secondary" size="large" sx={{ fontSize: buttonSize }}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<StopIcon fontSize="large" style={{ fontSize: buttonSize }} />
					<Typography variant="caption">{translate("[quickactions_stop]")}</Typography>
				</Box>
			</IconButton>
			<IconButton onClick={handlePlayPause} color="secondary" size="large" sx={{ fontSize: buttonSize }}>
				{isPlaying ?
					<Box display="flex" flexDirection="column" alignItems="center">
						<PauseIcon fontSize="large" style={{ fontSize: buttonSize }} />
						<Typography variant="caption">{translate("[quickactions_pause]")}</Typography>
					</Box>
					:
					<Box display="flex" flexDirection="column" alignItems="center">
						<PlayArrowIcon fontSize="large" style={{ fontSize: buttonSize }} />
						<Typography variant="caption">{translate("[quickactions_play]")}</Typography>
					</Box>
				}
			</IconButton>
			<IconButton onClick={onFastForward} color="secondary" size="large" sx={{ fontSize: buttonSize }}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<FastForwardIcon fontSize="large" style={{ fontSize: buttonSize }} />
					<Typography variant="caption">{translate("[quickactions_fastforward]")}</Typography>
				</Box>
			</IconButton>
			<IconButton onClick={onNext} color="secondary" size="large" sx={{ fontSize: buttonSize }}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<SkipNextIcon fontSize="large" style={{ fontSize: buttonSize }} />
					<Typography variant="caption">{translate("[quickactions_next]")}</Typography>
				</Box>
			</IconButton>
		</Box>
	);
};

export default MediaPanel;
