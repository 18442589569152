import Paper from '@mui/material/Paper';

interface GraphContainerProps {
	children: any,
	size?: 'big' | 'mid' | 'small',
	order?: number
	width?: string
}

function GraphContainer(props: GraphContainerProps) {

	return (
		<div style={{ order: props.order ?? 0, minWidth: props.size == 'big' ? '100%' : (props.size === 'mid' ? '45%' : '300px'), flexBasis: props.width || '30%', flexGrow: 1 }}>
			<Paper className="session-wrapper">
				<div style={{ padding: 20, minHeight: 420}} className="session-wrapper">
					{props.children}
				</div>
			</Paper>
		</div>);
}

export default GraphContainer;

