import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import ProcessPill from '../../components/atoms/ProcessPill';
import { CachedDocument } from "../../adapters/Firebase";
import { ModifiersText } from '../session/Modifiers';

interface SessionListProps {
    sessions: CachedDocument[]
    isLoading?: boolean
}

interface ModifiersData {
    modifiers: any,
    processID: string,
}

function UserSessions(props: SessionListProps) {
    const defaultPageCount = 10;

    const getDate = (date: Date) => new Intl.DateTimeFormat('es-es', { dateStyle: 'medium', timeStyle: 'short' }).format(date);

    const processComparator: GridComparatorFn<string> = (v1, v2) => {
        const process1: string = v1!;
        const process2: string = v2!;
        return process1.localeCompare(process2);
    }

    function sessionDataToRow(session: CachedDocument) {
        return {
            id: session.id,
            processID: session.doc.processID,
            modifiers: { modifiers: session.doc.modifiers, processID: session.doc.processID },
            date: getDate(new Date(session.doc.timestamp._seconds * 1000)),
        };
    };

    const columns: GridColDef[] = [
        {
            field: "processID", headerName: "Proceso", width: 190,
            renderCell: (params: GridRenderCellParams<string>) => {
                const processID: string = params.value!;
                return <ProcessPill processID={processID} />
            },
            sortComparator: processComparator
        },
        { field: "date", headerName: "Fecha", type: 'date', width: 160 },
        {
            field: "modifiers", headerName: "Modificadores", flex: 1, sortable: false,
            renderCell: (params: GridRenderCellParams<ModifiersData>) => {
                return <ModifiersText
                    modifiers={params.value?.modifiers}
                    processID={params.value?.processID!} />
            },
        },
    ];

    const rows: GridRowsProp = props.sessions.map((session: any) => sessionDataToRow(session));

    return (<>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        checkboxSelection={false}
                        autoHeight {...props.sessions}
                        rows={rows}
                        columns={columns}
                        loading={props.isLoading}
                        pageSize={defaultPageCount}
                        rowsPerPageOptions={[defaultPageCount]}
                    />
                </div>
            </div>
        </Paper>
    </>
    );
}

export default UserSessions;