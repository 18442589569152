export interface ItemData {
	name: string;
	color: string;
	size: string;
}

export enum Handedness {
	Any = 0,
	Left = 1,
	Right = 2,
	None = 3
}


export interface HandednessData {
	right: number,
	left: number,
	leftErrors: number,
	rightErrors: number
}

export interface ViewDirectionData {
	samples: number[]
}

export interface LateralFeedbackData {
	leftFeedbackCount: number,
	rightFeedbackCount: number
}