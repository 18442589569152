import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ContactForm from "../landing/ContactForm";
import VRAccess from "../users/VRAccess";
import { faqTutorials, faQuestions, notFound } from "./faqData";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "../../contexts/TranslationContext";

function Faq() {
	const { translate } = useTranslation();
	return (
		<>
			<Typography variant="h3" gutterBottom>
				{translate("[sidebar_faq]")}
			</Typography>
			{
				faqTutorials.map((tutorial, index) => (
					<Accordion key={index} style={{
						margin: "20px"
					}} defaultExpanded={index === 0} >
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">
								{translate(tutorial.title)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant="body1">
								{translate(tutorial.content)}
							</Typography>
							<Box width="100%"
								marginY={1}
								alignItems="center"
								justifyContent="center"
								display="flex">
								<iframe
									style={{ width: "600px", aspectRatio: "16 / 9" }}
									src={tutorial.video}
									title={tutorial.title}
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}

			{
				faQuestions.map((question, index) => (
					<Accordion key={index} style={{
						margin: "20px"
					}} >
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">
								{translate(question.title)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ul style={{ listStyleType: "none" }} >
								{translate(question.content).split(/• /).slice(1).map((item, index) => (
									<li key={index}>{`${index + 1}- ${item}`}</li>
								))}
							</ul>
						</AccordionDetails>
					</Accordion>
				))}

			<Accordion style={{
				margin: "20px"
			}} >
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">{translate("[vraccess_title]")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<VRAccess />
				</AccordionDetails>
			</Accordion>

			<Typography variant="h5" margin={3} >
				{translate(notFound)}
			</Typography>
			<ContactForm />
		</>
	)
}


export default Faq;
