import { Box, Typography } from "@mui/material";
import { CleanSurfaceData } from "./CleanSurfaceData";
import { useTranslation } from "../../../contexts/TranslationContext";


interface AverageCardData {
    data: CleanSurfaceData;
}

function AverageCleanSurfaceCard(props: AverageCardData) {
    const data: CleanSurfaceData = props.data;
    const { translate } = useTranslation();

    const leftStains = data.cells.filter((entry) => entry.coordinates.x <= 0.5)
        .sort((a, b) => a.completionTime - b.completionTime);

    const rightStains = data.cells.filter((entry) => entry.coordinates.x > 0.5)
        .sort((a, b) => a.completionTime - b.completionTime);

    const leftErrors = leftStains.filter((entry) => !entry.completed || entry.spongeColor !== entry.stainColor);
    const rightErrors = rightStains.filter((entry) => !entry.completed ||entry.spongeColor !== entry.stainColor);

    //total time is the completion time of the last cell minus the first one
    const totalLeftTime = leftStains.length < 2 ? 0 :
        leftStains[leftStains.length - 1].completionTime - leftStains[0].completionTime;

    const totalRightTime = rightStains.length < 2 ? 0 :
        rightStains[rightStains.length - 1].completionTime - rightStains[0].completionTime;

    //average reaction time is the average of the completion time from one cell to the next
    const averageLeftReactionTime = leftStains.reduce((acc, entry, index, array) => {
        if (index === 0) return acc;
        return acc + entry.completionTime - array[index - 1].completionTime;
    }, 0) / leftStains.length;

    const averageRightReactionTime = rightStains.reduce((acc, entry, index, array) => {
        if (index === 0) return acc;
        return acc + entry.completionTime - array[index - 1].completionTime;
    }, 0) / rightStains.length;

    return (<>

        <Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
        <br />
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography>{translate("[graphs_cleanSurface_totalTime]")}</Typography>
            <Typography variant="h4">
                {(data.totalTime).toFixed(1)} {translate("[units_seconds]")}
            </Typography>
        </Box>
        <br />
        <Box display="flex" flexDirection="row" justifyContent="space-evenly">
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography align="center" variant="h5">{translate("[graphs_cleanSurface_red]")}</Typography>
                <Typography>{translate("[graphs_cleanSurface_totalTime]")}</Typography>
                <Typography variant="h6">
                    {(totalLeftTime).toFixed(1)} {translate("[units_seconds]")}
                </Typography>
                <br />
                <Typography>{translate("[graphs_cleanSurface_reactiontime]")}</Typography>
                <Typography variant="h6">
                    {(averageLeftReactionTime).toFixed(1)} {translate("[units_seconds]")}
                </Typography>
                <br />
                <Typography>{translate("[graphs_cleansurface_error]")}</Typography>
                <Typography variant="h6">
                    {(100 * leftErrors.length / leftStains.length).toFixed(2)}%
                </Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography align="center" variant="h5">{translate("[graphs_cleanSurface_blue]")}</Typography>
                <Typography>{translate("[graphs_cleanSurface_totalTime]")}</Typography>
                <Typography variant="h6">
                    {(totalRightTime).toFixed(1)} {translate("[units_seconds]")}
                </Typography>
                <br />
                <Typography>{translate("[graphs_cleanSurface_reactiontime]")}</Typography>
                <Typography variant="h6">
                    {(averageRightReactionTime).toFixed(1)} {translate("[units_seconds]")}
                </Typography>
                <br />
                <Typography>{translate("[graphs_cleansurface_error]")}</Typography>
                <Typography variant="h6">
                    {(100 * rightErrors.length / rightStains.length).toFixed(2)}%
                </Typography>
            </Box>
        </Box>

    </>);
}

export default AverageCleanSurfaceCard;