import { Box, Typography, TextField, Button, Link, Paper } from '@mui/material';
import { subscribeNewsletter } from '../../adapters/Firebase';
import { useContext, useRef, useState } from 'react';
import { UserContext } from '../../contexts/User.context';
import { useTranslation } from '../../contexts/TranslationContext';

function Newsletter() {
	const { translate } = useTranslation();
	const [sending, setSending] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement>(null);
	const user = useContext(UserContext);

	const onSubmit = async (event: any) => {
		setSending(() => true);
		event.preventDefault();
		const { email } = event.target.elements;
		const result = await subscribeNewsletter(email.value);
		setSending(() => false);
		if (result && formRef.current) {
			formRef.current.reset();
		}
	};

	return (
		<Box >
			<Box
				component="form"
				onSubmit={onSubmit}
				ref={formRef}
				sx={{
					'& .MuiTextField-root': { m: 1, width: '100%' },
					'& .MuiButtonBase-root': { m: 1 }
				}}
			>
				<Typography variant="h3" gutterBottom>
					{translate("[newsletter_title]")}
				</Typography>
				<Typography variant="body2" gutterBottom>
					{translate("[newsletter_description]")}
				</Typography>

				<Paper elevation={3} sx={{ p: 2, mb: 2 }}>
				<TextField
					name="email"
					label={translate("[contact_email]")}
					variant="outlined"
					type="email"
					required
					autoComplete="email"
					defaultValue={user?.email}
				/>
				<Button type="submit" variant="contained" color="primary" disabled={sending}>
					{translate("[newsletter_subscribe]")}
				</Button>
				<Typography variant="caption" color="textSecondary">
					{translate("[newsletter_warning]")}
					<Link href="/privacy_policy" target="_blank">
						{translate("[newsletter_privacypolicy]")}
					</Link>
				</Typography>
				</Paper>
			</Box>
		</Box>
	);
}

export default Newsletter;
