import { useMemo, useState } from "react";
import { CachedDocument, extractSubcollection } from "../adapters/Firebase/Firebase";
import AIAssistant, { serializeAIData } from "./AIAssistant";
import AverageCard from "./Graphs/AverageCard";
import CoordinatesDistributionGraph from "./Graphs/CoordinatesDistributionGraph";
import GraphContainer from "./Graphs/GraphContainer";
import HandednessGraph from "./Graphs/HandednessGraph";
import LateralFeedbackGraph from "./Graphs/LateralFeedbackGraph";
import ReactionTimeGraph from "./Graphs/ReactionTimeGraph";
import ViewDirectionGraph from "./Graphs/ViewDirectionGraph";
import { combineMultigraphData, getTrialData } from "./MultiObjectData";

interface MultiObjectGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}

const aiPrompt = (data: any) =>
	"Here is the data of a session of a neurological patient using a VR application in which they have to touch or bin some objects in a table in front of them:" +
	`\n${serializeAIData(data)}` +
	"\n Here are some explanations on the data for you (do not explain these in your report): " +
	"\n- The x,y coordinates indicates the position of the target object in a table, where 0,0 is the center of the nearest edge to the user" +
	"\n- The viewOrientation represents percentage of time the user spent in a certain Yaw orientation. They are organised in batches of 5 degrees starting at angle 0 (forward) up to 360. Therapists are very interested about wheter the patient looks more left or right." +
	"\n- The modifiers indicate how the exercise was modified by the therapist, either adding or removing difficulty." +
	"\nPlease elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it."

function MultiObjectGraph(props: MultiObjectGraphData) {
	const session: CachedDocument = props.sessionDoc;
	const [blocks, setBlocks] = useState([]);
	const [totalBlocks, setTotalBlocks] = useState(0);

	useMemo(() => {
		extractSubcollection(session, "blocks").then((sessionBlocks: any) => {
			setTotalBlocks(sessionBlocks.length);
			sessionBlocks = sessionBlocks.filter((block: any) => block !== null && block.trials.length !== 0);
			setBlocks(sessionBlocks);
		})
	}, [session])

	const blocksAccumulatedData = useMemo(() => {
		const sortedBlocks = blocks.sort((a: any, b: any) => a.hierarchy - b.hierarchy);
		return sortedBlocks.map((block: any, index: number) => {
			const trials = block.trials.map((trial: any) => getTrialData(trial));
			return {
				...combineMultigraphData(trials),
				hierarchy: block.hierarchy,
				blockTitle: block.block,
				index: index,
				rawData: trials
			}
		});
	}, [blocks]);

	const sessionAccumulatedData = useMemo(
		() => combineMultigraphData(blocksAccumulatedData),
		[blocksAccumulatedData]);

	return (
		<>
			<GraphContainer size='big' order={0} width={props.graphElements && props.graphElements > 1 ? `45%` : '100%'}>
				{props.children}
				<ReactionTimeGraph data={blocksAccumulatedData} totalBlocks={totalBlocks}/>
			</GraphContainer>

			<GraphContainer order={1} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<HandednessGraph data={sessionAccumulatedData.handedness} />
			</GraphContainer>

			<GraphContainer order={2} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<ViewDirectionGraph data={sessionAccumulatedData.viewOrientation} />
			</GraphContainer>

			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<CoordinatesDistributionGraph data={sessionAccumulatedData} />
			</GraphContainer>

			<GraphContainer order={4} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AverageCard data={sessionAccumulatedData} />
			</GraphContainer>

			<GraphContainer order={5} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<LateralFeedbackGraph data={sessionAccumulatedData.lateralFeedback} />
			</GraphContainer>

			<GraphContainer order={6} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AIAssistant prompt={aiPrompt(sessionAccumulatedData)} />
			</GraphContainer >

		</>);
}

export default MultiObjectGraph;

