import healthCheck from "../../media/landing/health-check.png";
import teamSupport from "../../media/landing/team-support.png";
import reward from "../../media/landing/reward.png";
import willpower from "../../media/landing/willpower.png";

export interface AdvantageData {
    title: string,
    description: string,
    image: string,
}

export const advantagesTitle = "Ventajas";

export const advantagesData: AdvantageData[] = [
    {
        "image": willpower,
        "title": "Motivación",
        "description": "Diseñado para reducir la frustración e incrementar la autonomía del paciente. Con tareas asequibles, variadas e inmersivas.",
    },
    {
        "image": reward,
        "title": "Eficiencia",
        "description": "Optimización de las terapias, automatización y personalización de ejercicios y análisis de resultados, disminuyendo el coste personal y social.",
    },
    {
        "image": healthCheck,
        "title": "Telerrehabilitación",
        "description": "Tratamiento de pacientes desde cualquier lugar, mejorando la acesibilidad y pudiendo monitorizar su progreso en tiempo real.",
    },
    {
        "image": teamSupport,
        "title": "Efectividad",
        "description": "Científicamente probado y con ejercicios ecológicos para mejorar y complementar la efectividad de los métodos tradicionales.",
    },
];

export const advantagesBottomSectionData = {
  paragraph1: "“Re-Cognition es un entorno de realidad virtual cuyo objetivo se basa en la mejora de los componentes cognitivos y de la ejecución en diversas tareas cotidianas.”",
  paragraph2: "“Está destinado a personas con daño cerebral adquirido, niños con necesidades educativas especiales y cualquier usuario que precise una mejora cognitiva.”",
  video:"https://www.youtube.com/watch?v=R9nAVti8n98"
}