import { hsl } from 'd3-color';
import styled from "styled-components";

import Typography from '@mui/material/Typography';

export interface SessionTagProps {
	name: string;
}

export const TagTitle = styled.div`
    padding: 8px;
    border-radius: 8px;
    display: inline-block;
    min-width: 120px;
    text-align: center;
`;

function randomColorFromID(id: string, saturation: number, brightness: number): string {
	let hash = id.charCodeAt(0) * 7000;
	for (let i = 0; i < id.length; i++) {
		hash = id.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hsl(hash % 360, saturation, brightness).hex();
}

export function SessionTagSmall(props: SessionTagProps) {
	const background = randomColorFromID(props.name, 0.55, 0.9);
	const foreground = randomColorFromID(props.name, 1, 0.4);
	return (
		<div style={{ position: "relative" }} >
			<div style={{
				position: "absolute",
				top: 0,
				right: 0,
				display: "flex",
				width: "50px", height: "50px",
				borderRadius: "50%", backgroundColor: background
			}} >
				<Typography style={{ margin: "auto", color: foreground }}>
					{props.name}
				</Typography>
			</div>
		</div>
	);
}

export function SessionTagBig(props: SessionTagProps) {
	const background = randomColorFromID(props.name, 0.55, 0.9);
	const foreground = randomColorFromID(props.name, 1, 0.4);
	return (
		<div>
			<div style={{
				display: "flex",
				width: "50px", height: "50px",
				borderRadius: "50%", backgroundColor: background
			}} >
				<Typography style={{ margin: "auto", color: foreground }}>
					{props.name}
				</Typography>
			</div>
		</div>
	);
}
