import { useMemo, useState } from "react";
import { CachedDocument } from "../adapters/Firebase/Firebase";
import AIAssistant, { serializeAIData } from "./AIAssistant";
import extractDishwasherData, { DishwasherData } from "./DishwasherData";
import AverageDishwasherCard from "./Graphs/Dishwasher/AverageDishwasherCard";
import DishwasherCoordinatesGraph from "./Graphs/Dishwasher/DishwasherCoordinatesGraph";
import GraphContainer from "./Graphs/GraphContainer";

interface DishwasherGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}


const aiPrompt = (data: any) => "Here is the data of a session of a neurological patient using a VR exercise in which they had to pick up the mature oranges form the tree and place them in a basket. " +
	"Note that depending on the modifiers, the user might need to just touch, grab or even twist the orange in the branch to pick them up. " +
	"The time from the previous release to the next pick up is very important to understand how long it takes the user to pick up the oranges, moreover the coordinates to know if they haver more difficitulties pick up fruits on the top, the bottom, the left or the right of the tree. " +
	"Please elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it. " +
	`\n${serializeAIData(data)}`;

function DishwasherSession(props: DishwasherGraphData) {
	const session: CachedDocument = props.sessionDoc;

	const [sessionData, setSessionData] = useState<DishwasherData | undefined>(undefined);

	useMemo(() => {
		const data = extractDishwasherData(session.doc);
		setSessionData(data);
	}, [session])

	if (sessionData === undefined) {
		return <></>;
	}

	return (
		<>
			<GraphContainer order={0} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<DishwasherCoordinatesGraph data={sessionData} topTray />
			</GraphContainer>

			<GraphContainer order={1} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<DishwasherCoordinatesGraph data={sessionData} topTray={false} />
			</GraphContainer>

			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '33%'}>
				{props.children}
				<AverageDishwasherCard data={sessionData} />
			</GraphContainer>

		</>);
}

export default DishwasherSession;
