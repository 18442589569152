import { Button, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { RemoteClient } from "../../../data/RemoteClient";
import RemoteConnectionCard from "./RemoteConnectionCard";
import { getConfigValue } from "../../../adapters/RemoteConfig";
import { faQuestions } from "../../faq/faqData";
import { useEffect, useState } from "react";
import { getVRLoggedWithinLimit } from "../../../adapters/Firebase";
import { useTranslation } from "../../../contexts/TranslationContext";

interface RemoteConnectionsProps {
	userID: string | null;
	clients: RemoteClient[];
	onConnect: (client: RemoteClient) => void;
	onRefresh: () => void;
}

function RemoteConnections(props: RemoteConnectionsProps) {
	const { translate } = useTranslation();
	const clients = props.clients;
	const refresh = props.onRefresh;
	const connect = props.onConnect;
	const [lastVRLogin, setLastVRLogin] = useState<boolean>(false);
	const [latestVersion, setLastestVersion] = useState<number>(0);

	useEffect(() => {
		async function checkLastVRLogin(userID: string | null) {
			if (userID === null) {
				setLastVRLogin(() => false);
			}
			else {
				const recentVRLogin = await getVRLoggedWithinLimit(userID, 60 * 60 * 1000);
				setLastVRLogin(() => recentVRLogin);
			}
		}
		checkLastVRLogin(props.userID);
	}, [props.userID]);

	useEffect(() => {
		const version = getConfigValue("vr_compatible_version").asNumber();
		setLastestVersion(version);
	}, []);

	return (
		<>
			<div style={{ textAlign: "right" }}>
				<Button onClick={refresh} variant="outlined">
					{translate("[remoteconnections_title]")}
				</Button>
			</div>
			<Box>
				<Grid container spacing={{ xs: 3 }} columns={{ xs: 4, sm: 9, md: 12 }}>
					{clients.map((client, index) => (
						<Grid xs={2} sm={3} md={3} key={index}>
							<RemoteConnectionCard
								client={client}
								onSelected={connect}
								latestVersion={latestVersion} />
						</Grid>
					))}
				</Grid>
				<br />

				{clients.length === 0 && !lastVRLogin &&
					<Typography variant="body1" color="error">
						{translate("[remoteconnections_old_login]")}
					</Typography>}

				{clients.length === 0 &&
					<Typography variant="body2" component="div">
						<ul style={{ listStyleType: "none" }} >
							{translate(faQuestions[0].content).split(/• /).slice(1).map((item, index) => (
								<li key={index}>{`${index + 1}- ${item}`}</li>
							))}
						</ul>
					</Typography>}
			</Box>

		</>
	);
}

export default RemoteConnections;
