import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { askAI } from "../adapters/Firebase/Functions";
import { useTranslation } from "../contexts/TranslationContext";

interface AIAssistantProps {
	prompt: string
}

export function serializeAIData(data: any): string {
	const replacer = (key: string, value: any): any => {
		if (typeof value === 'number') {
			return parseFloat(value.toFixed(2));
		}
		return value;
	};

	const jsonString = JSON.stringify(data, replacer, 0);
	return jsonString.replace(/"([^"]+)":/g, '$1:');
}

async function getGPTResponse(prompt: string) {
	const result = await askAI(
		"You are a helpful message box in a dashboard. Your work is to provide information to a therapist about their patients who are using a Virtual Reality application to rehabilitate their neurological problems.",
		prompt);

	return result;
}

function AIAssistant(props: AIAssistantProps) {
	const { translate } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [gptResponse, setGptResponse] = useState("");

	const handleButtonClick = async () => {
		setLoading(true);
		const response = await getGPTResponse(props.prompt);
		setGptResponse(response);
		setLoading(false);
	};

	const handleCopyData = () => {
		navigator.clipboard.writeText(props.prompt);
	}

	return (
		<Box>
			<Typography><strong>{translate("[ai_title]")}</strong></Typography>
			<br />
			{loading ? (
				<Box textAlign="center">
					<CircularProgress />
				</Box>
			) : (
				<>
					{gptResponse ? (
						<Typography>{gptResponse}</Typography>
					) : (
						<>
							<Typography>{translate("[ai_warning]")}</Typography>
							<br />
							<Box textAlign="center">
								<Button variant="contained" color="primary" onClick={handleButtonClick}>
									{translate("[ai_generate]")}
								</Button>
								<br /><br />
								<Button variant="text" color="primary" onClick={handleCopyData}>
									{translate("[ai_copydata]")}
								</Button>
							</Box>
						</>
					)}
				</>
			)
			}
		</Box>
	)
}

export default AIAssistant;