export default function TherapistLogo(props: any) {

    const { width, height, detail, stroke, skin, shirt, background } = props;
    return (
        <svg
   width={width}
   height={height}
   viewBox="0 0 292.33899 134.05"
   fill="none"
   version="1.1"
   id="svg147"
   xmlns="http://www.w3.org/2000/svg">
  <defs
     id="defs147" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 257.277,41.153 v 13.6794 h -0.253 v 6.6471 h -1.915 v 2.2242 h -5.418 V 65.9 h -28.87 V 27.2278 h 5.419 v -2.1963 h 2.421 v -1.715 h 2.893 c -0.345,-0.0886 -0.692,-0.1748 -1.039,-0.2533 -0.393,-0.0912 -0.787,-0.1748 -1.18,-0.2533 -17.518,-3.4984 -36.724,4.4559 -52.389,21.5527 C 150.503,73.2236 117.69,56.522 101.356,40.4107 69.8974,9.37369 16.8207,19.0075 18.02,77.454 c 0.1019,6.9365 1.1756,13.8245 3.1897,20.4634 -1.4478,-8.5674 -1.3743,-15.6857 2.2566,-16.086 8.0428,-0.8866 8.2431,24.0376 7.8729,36.0426 0.1674,0.221 0.3322,0.441 0.5071,0.659 2.1629,-12.139 6.9931,-22.1355 10.7204,-21.0716 0.1108,0.0316 0.2179,0.0749 0.3194,0.1292 2.4874,1.3477 0.3347,9.1884 -3.3241,16.7804 -1.3313,2.772 -2.8529,5.449 -4.5538,8.012 l 0.3651,0.406 c 1.017,-0.994 2.0859,-1.934 3.2024,-2.815 12.087,-9.444 10.4389,7.25 8.6209,13.026 H 266.121 c 14.886,-23.047 15.596,-53.4763 -4.769,-86.0029 -1.258,-2.016 -2.618,-3.9666 -4.075,-5.8441 z m 0.419,69.747 v 2.08 h -17.46 l -0.664,0.253 -1.854,0.707 -4.736,-12.413 5.857,-2.2315 0.253,-0.0963 v -5.7048 h 3.124 v -2.0798 h 18.603 V 110.9 Z"
     fill={background}
     id="path1" />
  <path
     d="m 8.98997,131.605 c 1.73013,-1.433 5.08783,-2.777 11.47513,-1.706 -4.9877,-2.06 -14.99051,-6.614 -15.78924,-10.291 -0.08271,-0.366 -0.06784,-0.748 0.04308,-1.107 0.11093,-0.358 0.31388,-0.68 0.58789,-0.932 2.26597,-2.316 7.88457,0.895 13.09257,4.844 -3.0381,-3.015 -5.9238,-6.186 -8.64576,-9.5 -3.7883,-4.656 -8.20257,-11.01 -7.71682,-14.8497 0.06015,-0.5895 0.27255,-1.1523 0.6156,-1.631 0.34304,-0.4788 0.80448,-0.8565 1.33739,-1.0947 0.4924,-0.2357 1.02697,-0.3664 1.57082,-0.3839 0.54384,-0.0176 1.08545,0.0784 1.59152,0.2819 C 12.4327,97.1352 18.4695,108.117 24.0255,119.713 20.122,108.13 15.6827,89.1724 18.795,83.6446 c 0.5458,-0.9689 1.3045,-1.5145 2.2534,-1.6216 0.5784,-0.0645 1.1636,0.0071 1.7105,0.2091 0.5469,0.202 1.0411,0.5291 1.4444,0.9561 5.258,5.1377 5.2355,25.8848 4.8875,36.0658 1.7251,-9.839 5.228,-18.659 8.5756,-20.9715 0.8113,-0.5609 1.5975,-0.7368 2.3336,-0.5099 l -0.1377,0.4895 c -0.5884,-0.1708 -1.2319,-0.0178 -1.9155,0.4539 -3.8684,2.672 -7.9622,14.426 -9.1289,26.203 l -0.5008,-0.043 c 0.0225,-0.347 2.2534,-34.7373 -4.4619,-41.3053 -0.8262,-0.8058 -1.7251,-1.1449 -2.7542,-1.0199 -0.7937,0.0892 -1.4071,0.538 -1.8753,1.3692 -3.6732,6.517 3.5053,32.381 7.5115,41.56 l -0.4557,0.215 C 21.8246,116.018 13.4668,98.0327 6.99441,95.7073 6.5508,95.5245 6.07476,95.4373 5.59638,95.4514 5.118,95.4654 4.64769,95.5803 4.21516,95.7889 3.75633,95.9893 3.3588,96.3116 3.06413,96.722 2.76946,97.1324 2.58848,97.6159 2.54009,98.1219 1.78894,104.177 14.2555,118.277 23.6524,126.679 c 0.439,0.382 0.8563,0.753 1.252,1.111 l -0.3255,0.398 c -0.3673,-0.318 -0.7512,-0.658 -1.1518,-1.02 -5.1078,-4.454 -14.87279,-12.195 -17.75721,-9.248 -0.21484,0.192 -0.37481,0.439 -0.46297,0.715 -0.08816,0.277 -0.10122,0.573 -0.0378,0.856 0.98902,4.539 18.06768,10.964 18.24048,11.017 l -0.1453,0.487 C 15.1519,128.994 11.2009,130.437 9.30546,132 Z"
     fill={stroke}
     id="path2" />
  <path
     d="M 44.1416,0.661625 C 44.0237,0.45987 43.8569,0.292867 43.6572,0.17691 43.4576,0.0609535 43.2321,0 43.0026,0 42.7732,0 42.5477,0.0609535 42.3481,0.17691 42.1484,0.292867 41.9816,0.45987 41.8637,0.661625 L 40.1035,3.76874 C 40.0621,3.84234 40.0274,3.91977 40,4 h 0.5867 L 42.315,0.933702 c 0.0712,-0.119331 0.1711,-0.217932 0.2901,-0.286341 0.119,-0.06841 0.2531,-0.104337 0.3896,-0.104337 0.1364,0 0.2706,0.035927 0.3896,0.104337 0.119,0.068409 0.2189,0.16701 0.29,0.286341 L 45.4133,4 H 46 C 45.9718,3.91967 45.9362,3.84224 45.8938,3.76874 Z"
     fill={stroke}
     id="path3" />
  <path
     d="M 26,48 V 12 h 0.5108 V 47.4944 H 51 V 48 Z"
     fill={stroke}
     id="path4" />
  <path
     d="m 55.4709,48 v -0.5105 h 4.0176 V 3.51052 H 26.5115 V 7.06376 H 26 V 3 h 34 v 45 z"
     fill={stroke}
     id="path5" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 59,20.95 v 0.1 H 26 v -0.1 z"
     fill={stroke}
     id="path6" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 34.0502,23 v 23 h -0.1 V 23 Z"
     fill={stroke}
     id="path7" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 40.0502,34 v 12 h -0.1 V 34 Z"
     fill={stroke}
     id="path8" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 40.0502,23 v 6 h -0.1 v -6 z"
     fill={stroke}
     id="path9" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 46.0502,23 v 23 h -0.1 V 23 Z"
     fill={stroke}
     id="path10" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 52.0502,44 v 2 h -0.1 v -2 z"
     fill={stroke}
     id="path11" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 52.0502,22 v 19 h -0.1 V 22 Z"
     fill={stroke}
     id="path12" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 38,42.95 v 0.1 h -9 v -0.1 z"
     fill={stroke}
     id="path13" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 57,42.95 v 0.1 H 42 v -0.1 z"
     fill={stroke}
     id="path14" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 57,36.95 v 0.1 H 29 v -0.1 z"
     fill={stroke}
     id="path15" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 50,30.95 v 0.1 H 29 v -0.1 z"
     fill={stroke}
     id="path16" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 57,30.95 v 0.1 h -3 v -0.1 z"
     fill={stroke}
     id="path17" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 33,25.95 v 0.1 h -4 v -0.1 z"
     fill={stroke}
     id="path18" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 57,25.95 v 0.1 H 36 v -0.1 z"
     fill={stroke}
     id="path19" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 31.5,30 C 30.6716,30 30,29.3284 30,28.5 30,27.6716 30.6716,27 31.5,27 c 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 z"
     fill={detail}
     id="path20" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 31.5,36 C 30.6716,36 30,35.3284 30,34.5 30,33.6716 30.6716,33 31.5,33 c 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 z"
     fill={detail}
     id="path21" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 31.5,42 C 30.6716,42 30,41.3284 30,40.5 30,39.6716 30.6716,39 31.5,39 c 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 z"
     fill={detail}
     id="path22" />
  <path
     d="m 29.9819,30.7178 c -0.3087,-0.1826 -0.5626,-0.4447 -0.7351,-0.7588 -0.1725,-0.3141 -0.2574,-0.6687 -0.2457,-1.0268 0.0116,-0.3581 0.1194,-0.7065 0.3121,-1.0087 l 0.3448,0.2213 c -0.205,0.3216 -0.2873,0.706 -0.2317,1.0831 0.0555,0.3771 0.245,0.7217 0.5339,0.9708 0.289,0.2491 0.6581,0.386 1.0398,0.3859 0.3817,-2e-4 0.7507,-0.1376 1.0394,-0.3869 0.2887,-0.2494 0.4779,-0.5942 0.533,-0.9713 0.0551,-0.3772 -0.0275,-0.7616 -0.2328,-1.0829 -0.2053,-0.3214 -0.5195,-0.5583 -0.8853,-0.6674 -0.3657,-0.1091 -0.7586,-0.0832 -1.1068,0.0731 l -0.1696,-0.3743 c 0.2724,-0.1219 0.5686,-0.1815 0.8671,-0.1743 0.2984,0.0071 0.5915,0.0808 0.8577,0.2155 0.2663,0.1348 0.499,0.3273 0.6811,0.5635 0.1822,0.2361 0.3092,0.5099 0.3717,0.8013 0.0626,0.2915 0.0591,0.5932 -0.0102,0.8831 -0.0692,0.29 -0.2025,0.5608 -0.3901,0.7926 -0.1875,0.2319 -0.4246,0.419 -0.6939,0.5476 -0.2693,0.1286 -0.564,0.1955 -0.8625,0.1958 l 0.0027,-0.0027 c -0.3588,-3e-4 -0.7109,-0.0968 -1.0196,-0.2795 z"
     fill={stroke}
     id="path23" />
  <path
     d="m 30.1328,35.7951 0.1776,-0.3689 c 0.214,0.1062 0.4495,0.1623 0.6885,0.164 0.3563,10e-5 0.7024,-0.1195 0.9826,-0.3395 0.2803,-0.2201 0.4785,-0.5279 0.5629,-0.8741 0.0844,-0.3462 0.05,-0.7107 -0.0975,-1.035 -0.1475,-0.3244 -0.3997,-0.5898 -0.7161,-0.7536 -0.3165,-0.1639 -0.6787,-0.2168 -1.0288,-0.1501 -0.35,0.0667 -0.6675,0.2489 -0.9016,0.5176 -0.234,0.2687 -0.3711,0.6082 -0.3891,0.9641 -0.018,0.3559 0.084,0.7075 0.2898,0.9984 l -0.3334,0.2377 c -0.2588,-0.3655 -0.3874,-0.8073 -0.3652,-1.2547 0.0221,-0.4473 0.1938,-0.8743 0.4875,-1.2124 0.2937,-0.3382 0.6924,-0.568 1.1322,-0.6526 0.4398,-0.0846 0.8953,-0.0191 1.2935,0.186 0.3982,0.205 0.716,0.5378 0.9026,0.945 0.1865,0.4072 0.231,0.8652 0.1263,1.3007 -0.1048,0.4354 -0.3526,0.8232 -0.7039,1.101 C 31.8894,35.8465 31.455,35.9984 31.0071,36 l -0.0082,-0.0082 c -0.2999,8e-4 -0.5961,-0.0664 -0.8661,-0.1967 z"
     fill={stroke}
     id="path24" />
  <path
     d="m 29.9811,41.7219 c -0.3072,-0.1818 -0.5602,-0.4428 -0.7327,-0.7558 -0.1724,-0.313 -0.2579,-0.6665 -0.2476,-1.0239 0.0103,-0.3573 0.1161,-0.7053 0.3062,-1.0078 l 0.3465,0.2186 c -0.1985,0.3151 -0.2798,0.6901 -0.2296,1.0592 0.0501,0.3691 0.2285,0.7088 0.5038,0.9593 0.2753,0.2505 0.6299,0.3958 1.0016,0.4105 0.3716,0.0147 0.7366,-0.1022 1.0307,-0.3302 0.2942,-0.228 0.4988,-0.5525 0.5778,-0.9165 0.079,-0.364 0.0275,-0.7443 -0.1456,-1.074 -0.173,-0.3298 -0.4565,-0.5879 -0.8007,-0.7292 -0.3442,-0.1412 -0.7271,-0.1564 -1.0814,-0.043 l -0.1255,-0.3908 c 0.2748,-0.0896 0.5658,-0.1185 0.8528,-0.0845 0.2871,0.034 0.5633,0.13 0.8097,0.2813 0.2464,0.1514 0.457,0.3545 0.6174,0.5953 0.1603,0.2408 0.2666,0.5137 0.3114,0.7997 0.0448,0.2859 0.027,0.5783 -0.052,0.8567 -0.079,0.2785 -0.2174,0.5364 -0.4057,0.756 -0.1883,0.2197 -0.4219,0.3958 -0.6848,0.5161 -0.2629,0.1204 -0.5487,0.1822 -0.8377,0.1811 -0.3569,-2e-4 -0.7073,-0.0963 -1.0146,-0.2781 z"
     fill={stroke}
     id="path25" />
  <path
     d="M 35.3567,14 C 34.7316,14 34.1322,13.7366 33.6903,13.2678 33.2483,12.7989 33,12.163 33,11.5 33,10.837 33.2483,10.2011 33.6903,9.73223 34.1322,9.26339 34.7316,9 35.3567,9 h 2.2223 v 0.53763 h -2.2223 c -0.4906,0 -0.9612,0.20675 -1.3081,0.57477 -0.3469,0.368 -0.5418,0.8671 -0.5418,1.3876 0,0.5205 0.1949,1.0196 0.5418,1.3876 0.3469,0.368 0.8175,0.5748 1.3081,0.5748 H 49 V 14 Z"
     fill={stroke}
     id="path26" />
  <path
     d="m 51.6469,13.6082 c 0.3413,-0.2031 0.6007,-0.499 0.7401,-0.8441 0.1393,-0.3452 0.1512,-0.7212 0.0338,-1.0728 -0.1174,-0.3517 -0.3578,-0.6602 -0.6858,-0.8802 -0.328,-0.22 -0.7262,-0.3399 -1.136,-0.3419 H 41 V 10 h 9.5806 c 0.5199,0.0033 1.0251,0.1554 1.4416,0.4341 0.4164,0.2787 0.7224,0.6695 0.8732,1.1151 0.1507,0.4456 0.1383,0.9227 -0.0354,1.3616 -0.1737,0.4388 -0.4997,0.8164 -0.9302,1.0775 L 51.9377,14 Z"
     fill={stroke}
     id="path27" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 0,71.4951 3.50726,67 7,71.4951 3.50726,76 Z m 3.50726,3.3133 2.56634,-3.3133 -2.56634,-3.306 -2.580864,3.306 z"
     fill={detail}
     id="path28" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 287.898,70.7762 0.538,0.5379 -2.659,2.6587 -0.538,-0.5378 z"
     fill={detail}
     id="path29" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 291.797,66.8697 0.538,0.5379 -2.659,2.6587 -0.538,-0.5378 z"
     fill={detail}
     id="path30" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 285.77,66.8602 2.659,2.6587 -0.538,0.5379 -2.658,-2.6588 z"
     fill={detail}
     id="path31" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 289.68,70.7799 2.659,2.6587 -0.538,0.5379 -2.659,-2.6588 z"
     fill={detail}
     id="path32" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 7,22 v 4 H 6 v -4 z"
     fill={detail}
     id="path33" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 7,16 v 4 H 6 v -4 z"
     fill={detail}
     id="path34" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 6,20 v 1 H 2 v -1 z"
     fill={detail}
     id="path35" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 12,20 v 1 H 8 v -1 z"
     fill={detail}
     id="path36" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 185,14 7,1.8734 L 186.875,21 Z m 2.258,5.5709 3.309,-3.3075 -4.52,-1.2094 z"
     fill={detail}
     id="path41" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 283.875,119 289,124.125 282,126 Z m -0.825,5.952 4.522,-1.21 -3.311,-3.311 z"
     fill={detail}
     id="path42" />
  <path
     d="m 166.344,127.843 c 0,0 -2.958,1.864 -4.535,-2.03 l 0.272,2.921 c 0,0 -3.303,-0.403 -3.712,-2.516 -0.409,-2.112 -2.335,-3.235 -2.335,-3.235 l 0.618,5.759 c -3.026,1.401 -4.484,-3.235 -5.557,-5.759 -0.724,-1.714 -9.356,-3.563 -9.908,-1.381 -0.599,2.387 0.206,8.115 -3.162,7.14 0,0 -1.306,-6.411 -2.132,-7.384 -0.826,-0.973 -16.287,-0.403 -16.287,-0.403 0,0 -0.191,-4.541 -0.356,-9.292 -0.066,-1.849 -0.127,-3.733 -0.173,-5.387 -0.081,-3.009 -0.114,-5.276 -0.02,-5.276 0.094,0 2.701,0.662 5.869,1.476 5.114,1.319 11.709,3.041 11.709,3.041 v 6.599 c 0,0 16.653,-0.687 19.054,0 2.4,0.687 7.718,4.016 8.439,5.019 1.121,1.548 3.872,7.633 3.872,7.633 0,0 -1.659,1.289 -2.419,-0.01 z"
     fill={skin}
     id="path46" />
  <path
     d="m 136,106.479 -14.76,4.521 -3.021,-1.116 c -0.057,-1.54 -0.101,-3.079 -0.14,-4.444 -0.083,-3.1 -0.117,-5.44 -0.021,-5.44 0.096,0 2.757,0.685 5.988,1.522 5.22,1.357 11.954,3.135 11.954,3.135 z"
     fill={stroke}
     id="path47" />
  <path
     d="m 122.072,107.252 v 4.535 l -0.016,16.687 V 129.961 134 H 37 c 0.4439,-3.094 1.1339,-7.694 2.0293,-13.095 0.2993,-1.798 0.624,-3.683 0.9715,-5.634 0.9487,-5.33 2.0649,-11.131 3.3256,-16.8693 C 45.2669,89.5518 47.5474,80.847 50.079,74.2281 58.1962,52.9857 76.1354,50.1385 95.1019,49.1019 113.985,48.0731 138.884,53.9942 141,105.881 c -6.783,0.484 -18.928,1.371 -18.928,1.371 z"
     fill={shirt}
     id="path48" />
  <path
     d="m 165,133 h -57 v -3.763 h 2.897 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.42 V 128 h 2.089 v 1.237 h 1.421 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.421 V 128 h 2.088 v 1.237 h 1.421 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.424 V 128 h 2.086 v 1.237 h 1.423 V 128 h 2.086 v 1.237 h 1.42 V 128 h 2.089 v 1.237 H 165 Z"
     fill="#ffffff"
     id="path49" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 162.074,128 h -2.605 v 1.416 h -0.927 V 128 h -2.618 v 1.416 h -0.929 V 128 h -2.615 v 1.416 h -0.929 V 128 h -2.615 v 1.416 h -0.929 V 128 h -2.6 v 1.416 h -0.929 V 128 h -2.61 v 1.416 h -0.926 V 128 h -2.613 v 1.416 h -0.927 V 128 h -2.61 v 1.416 h -0.929 V 128 h -2.61 v 1.416 h -0.929 V 128 h -2.61 v 1.416 h -0.929 V 128 h -2.61 v 1.416 h -0.929 V 128 h -2.61 v 1.416 H 119.61 V 128 h -2.613 v 1.416 H 116.07 V 128 h -2.61 v 1.416 h -0.929 V 128 h -2.61 v 1.416 H 107 V 134 h 0.506 v -4.031 h 2.922 v -1.416 h 1.597 v 1.416 h 1.942 v -1.416 h 1.597 v 1.416 h 1.939 v -1.416 h 1.6 v 1.416 h 1.939 v -1.416 h 1.598 v 1.416 h 1.942 v -1.416 h 1.597 v 1.416 h 1.942 v -1.416 h 1.597 v 1.416 h 1.942 v -1.416 h 1.597 v 1.416 h 1.942 v -1.416 h 1.597 v 1.416 h 1.939 v -1.416 h 1.6 v 1.416 h 1.939 v -1.416 h 1.598 v 1.416 h 1.941 v -1.416 h 1.598 v 1.416 h 1.931 v -1.416 h 1.598 v 1.416 h 1.947 v -1.416 h 1.597 v 1.416 h 1.947 v -1.416 h 1.597 v 1.416 h 1.947 v -1.416 h 1.6 v 1.416 h 2.919 V 134 H 165 v -4.584 h -2.926 z m -41.285,1.692 h -1.433 v -1.416 h -2.106 v 1.416 h -1.433 v -1.416 h -2.007 l -0.096,0.067 v 1.349 h -1.436 v -0.462 l -0.253,0.136 v 0.603 h 1.942 v -1.416 h 1.597 v 1.416 h 1.939 v -1.416 h 1.6 v 1.416 h 1.939 v -0.636 c -0.086,-0.061 -0.169,-0.103 -0.253,-0.155 z m 1.932,0.263 h -0.081 z m 1.861,0 v -0.061 c -0.102,0.036 -0.2,0.058 -0.302,0.075 l 0.003,-0.014 z m 3.285,-0.277 h -1.265 c -0.137,0.059 -0.278,0.103 -0.423,0.13 v 0.147 h 1.942 V 129.7 c -0.087,-0.031 -0.172,-0.07 -0.254,-0.116 v -0.013 z"
     fill={stroke}
     id="path50" />
  <path
     d="m 47.1578,87 0.4528,0.025 C 47.099,97.8739 47.9909,109.837 48,109.957 L 47.5495,110 c 0,-0.12 -0.9056,-12.1186 -0.3917,-23 z"
     fill={stroke}
     id="path51" />
  <path
     d="M 124.189,58.2906 C 116.956,51.7562 107.269,48.9133 94.571,49.6018 76.4427,50.5825 56.5067,53.3974 49.4851,74.4994 L 49,74.3394 c 3.1584,-9.4891 9.1178,-16.0463 18.2202,-20.0477 8.4821,-3.7296 18.5726,-4.7153 27.3202,-5.1904 12.8306,-0.6961 22.6396,2.19 29.9806,8.8209 9.958,8.9987 15.509,25.172 16.479,48.0628 L 140.502,106 C 139.537,83.2492 134.047,67.1978 124.189,58.2906 Z"
     fill={stroke}
     id="path52" />
  <path
     d="m 107.479,45.019 c -1.081,1.3259 -5.187,1.0774 -5.187,1.0774 l -0.142,2.4237 -0.101,1.729 c -2.5996,7.9454 -15.3127,7.5879 -16.8164,-0.6389 0.3276,-1.9166 1.2175,-12.4377 1.2175,-12.4377 -4.9935,0.2535 -6.5126,-2.4034 -6.4481,-4.1274 0.1599,-4.9487 6.6673,-2.7456 6.6673,-2.7456 0.3353,-4.0564 2.9971,-8.7922 2.9971,-8.7922 C 95.5522,10.0076 112,24.1414 112,24.1414 c -0.242,2.0282 -2.708,18.6516 -4.521,20.8776 z"
     fill={skin}
     id="path53" />
  <path
     d="m 91.2327,7.39981 c 2.2759,-0.46938 2.3563,2.13424 1.8413,3.97289 1.7409,-4.33854 5.5619,-6.98106 6.971,-6.25236 1.409,0.7287 -0.8037,3.96767 -3.0822,5.31096 3.7882,-1.32773 13.5252,-1.13583 15.4722,5.9645 1.698,6.2031 -0.972,8.3114 -0.972,8.3114 0,0 -5.582,0 -6.532,-4.5642 -0.578,4.9091 -8.1164,5.0491 -10.0181,-1.893 0,0 -2.6653,10.0436 -7.996,11.75 -0.0025,0.0052 -3.7682,-15.7255 3.9817,-17.8519 -2.0097,-0.3683 -1.447,-4.38005 0.3341,-4.74829 z"
     fill={stroke}
     id="path54" />
  <path
     d="M 85.2607,33.218 C 85.1024,32.9912 84.9032,32.811 84.6783,32.6912 84.2207,32.4761 83.716,32.4561 83.2477,32.6345 L 83.2669,32.6191 83,32.2171 c 0.1601,-0.1545 0.9459,-0.3503 1.642,-0.0901 0.3292,0.1234 0.6267,0.3463 0.8644,0.6475 0.2377,0.3012 0.4076,0.6707 0.4936,1.0735 L 85.5922,34 c -0.0597,-0.2877 -0.1731,-0.5552 -0.3315,-0.782 z"
     fill={stroke}
     id="path55" />
  <path
     d="m 99.3355,26.5825 c -1.1831,-0.1106 -2.3935,0.3629 -2.9478,1.1538 L 96,27.382 c 0.6611,-0.9441 2.0182,-1.4912 3.3778,-1.3637 1.1852,0.1106 2.1402,0.7343 2.6222,1.701 L 101.572,28 c -0.546,-1.1113 -1.63,-1.3693 -2.2365,-1.4175 z"
     fill={stroke}
     id="path56" />
  <path
     d="m 103,38 0.021,-0.5147 c 1.949,0.0798 2.63,-0.965 2.814,-1.3613 -0.581,-0.5146 -2.59,-2.6274 -1.523,-5.7308 0.813,-2.3624 2.683,-3.2991 4.161,-3.3866 1.165,-0.0669 2.156,0.3758 2.527,1.1349 l -0.473,0.2213 c -0.279,-0.5687 -1.073,-0.8981 -2.023,-0.8415 -1.315,0.0772 -2.961,0.9161 -3.682,3.0366 -1.125,3.2269 1.381,5.2007 1.489,5.2856 l 0.139,0.1081 -0.05,0.1673 C 106.395,36.1369 105.801,38 103.226,38 Z"
     fill={stroke}
     id="path57" />
  <path
     d="M 98.5897,41.3158 C 97.6569,40.7337 97.0635,39.8855 97,39.0526 L 97.552,39 c 0.0469,0.6192 0.552,1.3003 1.3082,1.7771 0.5923,0.3694 1.2558,0.573 1.9328,0.5934 0.678,0.0205 1.349,-0.1427 1.959,-0.4758 L 103,41.4458 c -0.645,0.3656 -1.358,0.5554 -2.081,0.5542 -0.816,-0.0016 -1.6175,-0.2372 -2.3293,-0.6842 z"
     fill={stroke}
     id="path58" />
  <path
     d="M 101.866,48 C 97.4707,47.5891 93,44 93,44 c 2.2292,0.5279 9,1.3986 9,1.3986 z"
     fill={stroke}
     id="path59" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 100,33 c -0.5523,0 -1,-0.4477 -1,-1 0,-0.5523 0.4477,-1 1,-1 0.552,0 1,0.4477 1,1 0,0.5523 -0.448,1 -1,1 z"
     fill={stroke}
     id="path60" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 108,34 c -0.552,0 -1,-0.4477 -1,-1 0,-0.5523 0.448,-1 1,-1 0.552,0 1,0.4477 1,1 0,0.5523 -0.448,1 -1,1 z"
     fill={stroke}
     id="path61" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 122.05,96 v 11 h -0.1 V 96 Z"
     fill={stroke}
     id="path62" />
  <path
     d="M 86,57.3918 86.3557,57 c 2.3033,2.3673 5.8094,3.6789 9.1414,3.4231 2.5976,-0.1986 4.7629,-1.3143 6.0989,-3.1456 L 102,57.6122 c -1.425,1.9538 -3.721,3.1456 -6.4645,3.3524 C 95.2489,60.9882 94.9622,61 94.6756,61 91.469,61 88.2086,59.6585 86,57.3918 Z"
     fill={stroke}
     id="path63" />
  <path
     d="M 155.212,121.105 150,115.362 150.363,115 l 5.317,5.861 0.32,2.055 -0.493,0.084 z"
     fill={stroke}
     id="path64" />
  <path
     d="m 159.968,121.754 -4.968,-6.41 0.353,-0.344 5.056,6.525 0.591,4.396 -0.462,0.079 z"
     fill={stroke}
     id="path65" />
  <path
     d="M 163.073,120.511 158,116.363 158.317,116 l 5.182,4.238 1.501,4.62 -0.47,0.142 z"
     fill={stroke}
     id="path66" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 77.499,88.8881 2.2849,16.1579 -0.502,0.071 -2.2849,-16.1579 z"
     fill={stroke}
     id="path67" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 108,83 v -4 h 12 v 4 z m 11.494,-0.5148 v -2.9704 h -10.988 v 2.9704 z"
     fill={stroke}
     id="path68" />
  <path
     d="m 134.775,128.81 c -0.083,0.014 -0.168,0.02 -0.252,0.021 -0.318,0.008 -0.633,-0.052 -0.927,-0.175 -0.168,-0.07 -0.329,-0.156 -0.482,-0.257 -1.768,-1.078 -2.98,-3.857 -2.98,-3.857 v 5.469 c -0.187,0.06 -0.379,0.106 -0.573,0.139 -0.535,0.1 -1.087,0.046 -1.593,-0.157 -0.087,-0.033 -0.172,-0.074 -0.253,-0.12 -0.088,-0.046 -0.172,-0.1 -0.252,-0.16 -0.11,-0.073 -0.211,-0.159 -0.3,-0.257 -0.075,0.095 -0.159,0.182 -0.253,0.257 -0.134,0.108 -0.284,0.195 -0.444,0.257 -0.137,0.055 -0.278,0.096 -0.422,0.121 -0.405,0.071 -0.819,0.065 -1.222,-0.018 -0.126,0.039 -0.253,0.07 -0.371,0.098 -0.119,0.028 -0.2,0.041 -0.298,0.056 -0.516,0.087 -1.042,0.087 -1.558,0 h -0.081 c -0.36,-0.065 -0.711,-0.171 -1.048,-0.316 -0.186,-0.075 -0.368,-0.161 -0.545,-0.257 -0.086,-0.046 -0.169,-0.095 -0.253,-0.144 l -0.252,-0.165 c -0.326,-0.22 -0.635,-0.465 -0.924,-0.733 -0.071,-0.066 -0.139,-0.131 -0.205,-0.198 -0.751,-0.745 -1.39,-1.6 -1.896,-2.535 -1.043,0.912 -2.139,1.759 -3.283,2.535 -0.128,0.088 -0.252,0.173 -0.391,0.258 l -0.096,0.061 -0.252,0.157 c -0.306,0.188 -0.616,0.368 -0.927,0.535 l -0.253,0.134 -0.252,0.126 c -0.738,0.392 -1.543,0.637 -2.371,0.722 -0.542,0.021 -1.084,-0.033 -1.611,-0.162 -0.263,-0.061 -0.523,-0.137 -0.778,-0.228 l -0.252,-0.098 c -0.953,-0.379 -1.834,-0.924 -2.603,-1.61 -3.677,1.029 -9.3578,3.227 -15.448,5.691 H 57.2539 c -1.0579,-5.4 -2.222,-11.796 -3.0628,-16.667 -0.6565,-3.811 -1.1211,-6.686 -1.1892,-7.429 -0.0025,-0.016 -0.0025,-0.031 0,-0.047 6.4083,-1.092 15.5487,-2.679 21.8358,-3.857 l 0.2702,1.661 0.3914,2.418 0.8534,6.57 c 0,0 28.8403,-2.829 33.0393,-3.343 2.335,-0.278 7.739,-1.08 12.082,-1.738 3.469,-0.53 6.262,-0.965 6.262,-0.965 l 5.784,9.245 c 0,0 1.026,6.895 1.387,8.569 0.025,0.111 0.045,0.195 0.063,0.257 0.006,0.032 0.016,0.063 0.03,0.093 -0.074,0.019 -0.149,0.034 -0.225,0.043 z"
     fill={skin}
     id="path69" />
  <path
     d="m 54.1852,117 c -0.6562,-3.699 -1.1187,-6.49 -1.1841,-7.211 -0.0015,-0.015 -0.0015,-0.03 0,-0.045 6.3827,-1.063 15.4804,-2.601 21.7425,-3.744 L 75,107.6 Z"
     fill={stroke}
     id="path70" />
  <path
     d="m 127.712,110.524 c -0.971,0.137 -5.992,0.843 -10.697,1.48 -3.851,0.52 -6.529,0.862 -7.955,1.018 L 109,112.543 c 1.422,-0.156 4.094,-0.496 7.942,-1.015 5.211,-0.704 10.807,-1.494 10.861,-1.504 L 127.969,110 134,118.744 133.557,119 Z"
     fill={stroke}
     id="path71" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 76.3279,116.166 0.0985,0.497 -6.8919,1.365 -0.0984,-0.498 z"
     fill={stroke}
     id="path72" />
  <path
     d="M 130.165,121.113 128,115.176 128.472,115 l 2.187,6.02 0.341,2.919 -0.499,0.061 z"
     fill={stroke}
     id="path73" />
  <path
     d="M 126.932,124.035 124,116.199 124.444,116 l 2.956,7.899 0.003,0.034 0.597,4.999 -0.475,0.068 z"
     fill={stroke}
     id="path74" />
  <path
     d="M 119.497,118.3 116,117.519 116.103,117 l 3.749,0.839 0.053,0.122 5.095,11.819 -0.459,0.22 z"
     fill={stroke}
     id="path75" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 115.292,121.766 1.638,2.677 -0.431,0.263 -1.638,-2.676 z"
     fill={stroke}
     id="path76" />
  <path
     d="M 93.6211,96.4412 C 84.2332,79.9366 80.6779,65.1387 82.4325,49.8696 l 1.0246,0.1128 c -1.7085,14.8731 1.7034,29.3202 10.7377,45.3912 3.3633,-3.4708 6.8192,-11.3296 8.9092,-20.3538 2.359,-10.1819 2.507,-19.7974 0.399,-25.6965 L 104.466,49 c 4.698,13.1565 -2.179,40.3993 -10.0457,47.569 L 93.9387,97 Z"
     fill={stroke}
     id="path77" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 99,94 v 14 H 89 V 94 Z"
     fill="#ffffff"
     id="path78" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 89,109 V 94 h 10 v 15 z m 9.5081,-0.524 V 94.5245 h -9.0162 v 13.9515 z"
     fill={stroke}
     id="path79" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 95.2413,100.756 V 99 h -1.4849 v 1.756 H 92 v 1.485 h 1.7564 V 104 h 1.4849 v -1.759 H 97 v -1.485 z"
     fill={detail}
     id="path80" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 283,133.95 v 0.1 H 20 v -0.1 z"
     fill={stroke}
     id="path81" />
  <path
     d="m 215.894,79 c -2.358,-0.0027 -4.619,-0.9349 -6.286,-2.5922 -1.667,-1.6573 -2.605,-3.9043 -2.608,-6.248 V 34 h 0.51 v 36.1598 c 0.003,2.2092 0.887,4.3272 2.459,5.8894 1.572,1.5621 3.702,2.4409 5.925,2.4436 h 39.212 c 2.223,-0.0027 4.354,-0.8815 5.925,-2.4436 1.572,-1.5622 2.456,-3.6802 2.459,-5.8894 H 264 c -0.003,2.3442 -0.941,4.5915 -2.609,6.2489 -1.668,1.6573 -3.929,2.5893 -6.288,2.5913 z"
     fill={stroke}
     id="path86" />
  <path
     d="M 263.49,60 V 25.8987 c -0.003,-2.2239 -0.887,-4.3559 -2.459,-5.9284 -1.571,-1.5724 -3.702,-2.4571 -5.925,-2.4598 h -39.21 c -2.223,0.0021 -4.354,0.8864 -5.926,2.4589 -1.573,1.5726 -2.457,3.705 -2.46,5.9293 H 207 c 0.003,-2.3593 0.941,-4.6211 2.608,-6.2894 1.667,-1.6682 3.928,-2.6066 6.286,-2.6093 h 39.21 c 2.358,0.002 4.619,0.9401 6.287,2.6084 1.668,1.6684 2.606,3.9306 2.609,6.2903 V 60 Z"
     fill={stroke}
     id="path87" />
  <path
     d="m 233,23.2574 h -4.738 V 25 H 228 v -2 h 5 z"
     fill="#ffffff"
     id="path88" />
  <path
     d="m 257,62 h -2 v -0.257 h 1.766 V 55 H 257 Z"
     fill="#ffffff"
     id="path89" />
  <path
     d="m 233,23.4561 h -4.503 V 25 H 228 v -2 h 5 z"
     fill={stroke}
     id="path90" />
  <path
     d="m 258,62 h -3 v -0.4958 h 2.372 V 55 H 258 Z"
     fill={stroke}
     id="path91" />
  <path
     d="M 257.506,48 V 23.5174 H 239 V 23 h 19 v 25 z"
     fill={stroke}
     id="path92" />
  <path
     d="m 226,27.4389 h 0.501 v -1.9345 h 27.998 v 37.9912 h -5.098 V 64 H 255 V 25 h -29 z"
     fill={stroke}
     id="path93" />
  <path
     d="M 221,66 V 27 h 3.682 v 0.5044 h -3.181 v 37.9912 h 27.998 V 58.0401 H 250 V 66 Z"
     fill={stroke}
     id="path94" />
  <path
     d="M 249.482,53 V 27.5029 H 228 V 27 h 22 v 26 z"
     fill={stroke}
     id="path95" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 246,30 V 69 H 216 V 30 Z"
     fill={detail}
     id="path96" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 237,43.95 v 0.1 h -17 v -0.1 z"
     fill={stroke}
     id="path97" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 237,47.95 v 0.1 h -17 v -0.1 z"
     fill={stroke}
     id="path98" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 237,50.95 v 0.1 h -17 v -0.1 z"
     fill={stroke}
     id="path99" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 237,53.95 v 0.1 h -17 v -0.1 z"
     fill={stroke}
     id="path100" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 237,57.95 v 0.1 h -17 v -0.1 z"
     fill={stroke}
     id="path101" />
  <path
     d="m 216.74,37 0.768,2.2709 L 218.26,37 H 219 v 3 h -0.534 V 37.75 L 217.731,40 h -0.452 l -0.74,-2.25 V 40 h -0.534 L 216,37 Z"
     fill="#000000"
     id="path102" />
  <path
     d="m 222,37 v 0.5204 h -1.311 v 0.7311 h 1.256 v 0.5204 h -1.256 v 0.7077 H 222 V 40 h -2 v -3 z"
     fill="#000000"
     id="path103" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 222.566,37 c 0.612,0 0.878,0.1979 1.024,0.3464 0.131,0.1481 0.235,0.3274 0.306,0.5265 0.07,0.199 0.106,0.4135 0.104,0.6298 0.005,0.2609 -0.048,0.5188 -0.152,0.7475 -0.105,0.2287 -0.259,0.42 -0.445,0.5546 -0.259,0.1431 -0.543,0.2092 -0.828,0.1927 H 222 V 37 Z m -0.006,2.4741 c 0.209,0.0186 0.418,-0.0318 0.605,-0.1459 0.117,-0.0862 0.213,-0.2079 0.278,-0.3528 0.066,-0.1449 0.098,-0.3079 0.095,-0.4727 0,-0.138 -0.023,-0.2746 -0.069,-0.4011 -0.046,-0.1265 -0.113,-0.24 -0.198,-0.3333 -0.204,-0.1969 -0.464,-0.2903 -0.724,-0.2605 h -0.087 l 0.002,1.9663 z"
     fill="#000000"
     id="path104" />
  <path
     d="m 225.05,37 v 3 h -0.1 v -3 z"
     fill="#000000"
     id="path105" />
  <path
     d="m 228.419,39.7662 c -0.249,0.1525 -0.538,0.2337 -0.834,0.2338 -0.42,-0.0033 -0.821,-0.1628 -1.117,-0.4441 -0.297,-0.2813 -0.465,-0.6618 -0.468,-1.0596 0.002,-0.395 0.168,-0.7733 0.461,-1.0532 0.294,-0.28 0.692,-0.4391 1.108,-0.4431 0.303,-0.0016 0.599,0.0809 0.854,0.2371 0.254,0.1563 0.455,0.3796 0.577,0.6426 h -0.639 c -0.128,-0.1633 -0.308,-0.2833 -0.513,-0.3422 -0.205,-0.0588 -0.425,-0.0534 -0.627,0.0155 -0.201,0.0688 -0.374,0.1975 -0.493,0.3669 -0.119,0.1695 -0.177,0.3707 -0.166,0.5738 0,0.2583 0.105,0.5067 0.293,0.6934 0.188,0.1868 0.444,0.2975 0.716,0.3091 0.148,-0.0027 0.293,-0.0365 0.425,-0.0989 0.132,-0.0625 0.247,-0.1519 0.339,-0.262 l 0.652,0.0051 c -0.122,0.256 -0.319,0.4733 -0.568,0.6258 z"
     fill="#000000"
     id="path106" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 230.717,37 232,40 h -0.624 l -0.328,-0.784 h -1.096 L 229.624,40 H 229 l 1.275,-3 z m 0.128,1.7038 -0.346,-0.9198 -0.358,0.9198 z"
     fill="#000000"
     id="path107" />
  <path
     d="m 232.378,37 v 2.4783 H 233 V 40 h -0.998 L 232,37 Z"
     fill="#000000"
     id="path108" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 235.603,37.0109 c 0.332,-0.0424 0.668,0.0394 0.941,0.229 0.08,0.0704 0.145,0.156 0.191,0.2517 0.046,0.0957 0.071,0.1995 0.074,0.305 0.006,0.1173 -0.024,0.2336 -0.085,0.3347 -0.06,0.1012 -0.15,0.1828 -0.257,0.2351 0.162,0.0455 0.303,0.1443 0.4,0.2798 0.097,0.1355 0.143,0.2994 0.131,0.4643 0.002,0.14 -0.031,0.2783 -0.098,0.4024 -0.066,0.124 -0.163,0.2298 -0.282,0.3078 -0.243,0.1311 -0.52,0.1924 -0.797,0.177 h -0.816 L 235,37.0109 Z m 0.149,1.163 c 0.133,0 0.486,0 0.486,-0.3226 0,-0.1769 -0.114,-0.3226 -0.438,-0.3226 h -0.234 v 0.6452 z m 0.098,1.3008 c 0.112,0 0.569,0 0.569,-0.3928 0,-0.1223 -0.061,-0.4007 -0.558,-0.4007 h -0.298 l 0.003,0.7935 z"
     fill="#000000"
     id="path109" />
  <path
     d="m 237.05,37 v 3 h -0.1 v -3 z"
     fill="#000000"
     id="path110" />
  <path
     d="m 238.378,37 v 2.4783 H 239 V 40 h -0.998 L 238,37 Z"
     fill="#000000"
     id="path111" />
  <path
     d="m 240.378,37 v 2.4783 H 241 V 40 h -0.998 L 240,37 Z"
     fill="#000000"
     id="path112" />
  <path
     d="m 226.71,63.5684 c 0.075,0.0886 0.177,0.1383 0.284,0.1383 0.106,0 0.209,-0.0497 0.284,-0.1383 0.075,-0.0885 0.118,-0.2086 0.118,-0.3339 0,-0.3489 -0.224,-0.4197 -0.5,-0.5246 -0.588,-0.1915 -0.773,-0.6165 -0.773,-1.0493 -0.007,-0.2218 0.05,-0.4399 0.162,-0.6183 0.113,-0.1783 0.274,-0.3063 0.456,-0.3629 V 60 h 0.57 v 0.6899 c 0.164,0.0648 0.307,0.1861 0.411,0.348 0.104,0.162 0.164,0.3571 0.172,0.5597 h -0.565 c -0.01,-0.0879 -0.046,-0.1689 -0.102,-0.2281 -0.056,-0.0592 -0.129,-0.0928 -0.204,-0.0946 -0.081,0.0028 -0.157,0.0426 -0.212,0.1108 -0.056,0.0682 -0.086,0.1594 -0.085,0.2539 0,0.2911 0.176,0.3541 0.565,0.4984 0.208,0.0515 0.394,0.1887 0.524,0.3869 0.129,0.1981 0.195,0.4442 0.184,0.6939 0.007,0.2448 -0.057,0.4853 -0.182,0.6817 -0.124,0.1965 -0.302,0.3372 -0.504,0.3991 V 65 h -0.567 v -0.6847 c -0.203,-0.0442 -0.387,-0.1678 -0.523,-0.3508 -0.135,-0.1829 -0.214,-0.4143 -0.223,-0.6565 v -0.0735 h 0.592 c 0,0.1253 0.042,0.2454 0.118,0.3339 z"
     fill="#000000"
     id="path113" />
  <path
     d="m 229.711,63.5684 c 0.075,0.0886 0.177,0.1383 0.284,0.1383 0.106,0 0.209,-0.0497 0.284,-0.1383 0.075,-0.0885 0.118,-0.2086 0.118,-0.3339 0,-0.3489 -0.224,-0.4197 -0.5,-0.5246 -0.587,-0.1915 -0.772,-0.6165 -0.772,-1.0493 -0.007,-0.2218 0.05,-0.4399 0.162,-0.6183 0.113,-0.1783 0.273,-0.3063 0.456,-0.3629 V 60 h 0.569 v 0.6899 c 0.163,0.0648 0.306,0.1861 0.41,0.348 0.104,0.162 0.164,0.3571 0.172,0.5597 h -0.564 c -0.01,-0.0879 -0.046,-0.1689 -0.102,-0.2281 -0.056,-0.0592 -0.129,-0.0928 -0.204,-0.0946 -0.08,0.0028 -0.156,0.0426 -0.212,0.1108 -0.055,0.0682 -0.086,0.1594 -0.085,0.2539 0,0.2911 0.176,0.3541 0.565,0.4984 0.208,0.0515 0.393,0.1887 0.523,0.3869 0.13,0.1981 0.195,0.4442 0.184,0.6939 0.007,0.2448 -0.057,0.4853 -0.181,0.6817 -0.125,0.1965 -0.302,0.3372 -0.504,0.3991 V 65 h -0.569 v -0.6847 c -0.203,-0.0442 -0.387,-0.1678 -0.522,-0.3508 -0.135,-0.1829 -0.214,-0.4143 -0.223,-0.6565 v -0.0735 h 0.593 c 0,0.1253 0.043,0.2454 0.118,0.3339 z"
     fill="#000000"
     id="path114" />
  <path
     d="m 214,36 v -5 h 5 v 0.5336 h -4.52 V 36 Z"
     fill={stroke}
     id="path115" />
  <path
     d="M 214,71 V 40 h 0.511 V 70.4948 H 235 V 71 Z"
     fill={stroke}
     id="path116" />
  <path
     d="m 239.595,71 v -0.5044 h 3.894 V 32.5044 H 224 V 32 h 20 v 39 z"
     fill={stroke}
     id="path117" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 218.779,75 c 2.331,0 4.221,1.8646 4.221,4.1647 v 38.6703 c 0,2.3 -1.89,4.165 -4.221,4.165 H 151.221 C 148.89,122 147,120.135 147,117.835 V 79.1647 C 147,76.8646 148.89,75 151.221,75 Z"
     fill="#ffffff"
     id="path120" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 151.444,122 c -1.178,-0.001 -2.308,-0.472 -3.141,-1.308 -0.833,-0.837 -1.302,-1.97 -1.303,-3.153 V 78.4613 c 0.001,-1.1828 0.47,-2.3168 1.303,-3.1531 0.833,-0.8364 1.963,-1.3069 3.141,-1.3082 h 67.112 c 1.178,0.0013 2.308,0.4718 3.141,1.3082 0.833,0.8363 1.302,1.9703 1.303,3.1531 v 39.0777 c -0.001,1.183 -0.47,2.317 -1.304,3.154 -0.834,0.836 -1.964,1.306 -3.143,1.307 z m -2.785,-46.3369 c -0.738,0.7416 -1.154,1.7469 -1.155,2.7957 v 39.0772 c 0.001,1.049 0.417,2.054 1.155,2.796 0.739,0.741 1.741,1.159 2.785,1.16 h 67.112 c 1.044,-0.001 2.046,-0.419 2.785,-1.16 0.738,-0.742 1.154,-1.747 1.155,-2.796 V 78.4613 c -0.001,-1.0487 -0.417,-2.0541 -1.155,-2.7957 -0.739,-0.7415 -1.741,-1.1587 -2.785,-1.1601 L 151.444,74.503 c -1.044,0.0013 -2.046,0.4185 -2.785,1.1601 z"
     fill={stroke}
     id="path121" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 179.446,106.316 c -1.645,-1.099 -2.927,-2.661 -3.684,-4.488 -0.757,-1.827 -0.956,-3.8382 -0.57,-5.7783 0.386,-1.94 1.338,-3.7221 2.737,-5.1208 1.399,-1.3986 3.181,-2.3511 5.121,-2.7369 1.94,-0.3857 3.951,-0.1875 5.778,0.5697 1.827,0.7572 3.389,2.0393 4.488,3.6842 1.098,1.6448 1.684,3.5786 1.684,5.5566 -0.003,2.6505 -1.058,5.1915 -2.932,7.0655 -1.874,1.874 -4.415,2.929 -7.066,2.932 -1.978,0 -3.911,-0.586 -5.556,-1.684 z m 0.277,-16.2151 c -1.563,1.0437 -2.781,2.5275 -3.501,4.2637 -0.719,1.7362 -0.908,3.6467 -0.541,5.4901 0.366,1.8433 1.271,3.5363 2.599,4.8653 1.329,1.329 3.022,2.235 4.865,2.601 1.844,0.367 3.754,0.179 5.491,-0.54 1.736,-0.719 3.22,-1.937 4.264,-3.499 1.044,-1.563 1.602,-3.4001 1.602,-5.2795 -0.002,-2.5197 -1.003,-4.9358 -2.784,-6.7182 -1.78,-1.7823 -4.196,-2.7852 -6.716,-2.7885 v 0.0049 c -1.879,-4e-4 -3.716,0.5564 -5.279,1.6002 z"
     fill={stroke}
     id="path122" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 186.783,96.2158 V 92 h -3.566 v 4.2158 H 179 v 3.5684 h 4.217 V 104 h 3.566 V 99.7842 H 191 v -3.5684 z"
     fill={detail}
     id="path123" />
  <path
     d="m 188,130 h -7 v -12.937 h 0.251 V 117 h 6.501 v 0.063 H 188 Z"
     fill="#ffffff"
     id="path124" />
  <path
     d="m 188.47,129.504 h -6.94 V 117 H 181 v 13 h 8 v -13 h -0.53 z"
     fill={stroke}
     id="path125" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 199,129 v 4 h -28 v -4 z"
     fill="#ffffff"
     id="path126" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 171,134 v -4 h 28 v 4 z m 27.492,-0.473 v -3.054 h -26.984 v 3.054 z"
     fill={stroke}
     id="path127" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 177.85,112 c -0.491,0.001 -0.961,0.206 -1.308,0.57 -0.347,0.364 -0.542,0.857 -0.542,1.372 v 1.116 c 0.001,0.514 0.196,1.008 0.543,1.372 0.346,0.363 0.817,0.569 1.307,0.57 h 14.297 c 0.491,-0.001 0.962,-0.206 1.31,-0.57 0.347,-0.364 0.542,-0.857 0.543,-1.372 v -1.116 c 0,-0.515 -0.195,-1.009 -0.543,-1.373 -0.347,-0.364 -0.818,-0.569 -1.31,-0.569 z m 15.254,4.061 c -0.254,0.266 -0.598,0.416 -0.957,0.416 H 177.85 c -0.358,0 -0.702,-0.15 -0.956,-0.416 -0.253,-0.266 -0.395,-0.627 -0.395,-1.003 v -1.116 c 0,-0.376 0.142,-0.737 0.395,-1.003 0.254,-0.266 0.598,-0.416 0.956,-0.416 h 14.297 c 0.359,0 0.703,0.15 0.957,0.416 0.254,0.266 0.397,0.627 0.397,1.003 v 1.116 c 0,0.376 -0.143,0.737 -0.397,1.003 z"
     fill={stroke}
     id="path128" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 276.29,49.4549 1.357,-2.6961 c -2.627,-2.6704 -1.362,-5.4077 -1.205,-5.7245 l 0.249,-0.515 2.994,1.612 -0.094,0.2215 c -0.1,0.2026 -0.16,0.4237 -0.176,0.6503 -0.016,0.2266 0.011,0.4542 0.081,0.6698 0.07,0.2155 0.18,0.4147 0.325,0.5859 0.145,0.1713 0.321,0.3111 0.518,0.4116 0.587,0.3141 1.722,0.4043 2.273,-0.6901 0.552,-1.0945 0,-1.7022 -1.098,-2.776 l -0.032,-0.0309 c -2.473,-2.3768 -2.113,-4.6197 -1.377,-6.0824 l 0.444,0.2369 c -0.956,1.8978 -0.527,3.7365 1.277,5.4695 l 0.035,0.0335 c 1.046,1.03 1.949,1.9004 1.2,3.3888 -0.681,1.352 -2.133,1.3468 -2.944,0.9091 -0.481,-0.2404 -0.858,-0.6583 -1.055,-1.1714 -0.197,-0.513 -0.201,-1.0838 -0.01,-1.5995 l -2.154,-1.1562 -0.027,0.0541 c -0.14,0.2781 -1.298,2.7914 1.248,5.2455 l 0.137,0.1339 -1.3,2.5751 2.016,1.0815 1.312,-2.6034 0.187,0.0412 c 0.603,0.1306 1.225,0.1306 1.827,0 l 0.102,0.515 c -0.608,0.1287 -1.235,0.1374 -1.846,0.0258 L 279.191,51 Z m 7.707,-2.5107 c 0.521,-0.4299 0.946,-0.9699 1.248,-1.5837 0.998,-1.988 0.683,-3.8214 -0.946,-5.4463 l -0.022,-0.0232 c -1.388,-1.4266 -2.151,-2.212 -1.512,-3.4816 0.108,-0.223 0.261,-0.4204 0.447,-0.5796 0.186,-0.1591 0.403,-0.2765 0.635,-0.3448 0.416,-0.126 0.861,-0.0846 1.248,0.1159 0.998,0.5407 0.958,1.4884 0.871,2.0266 l 2.026,1.0867 c 0.526,-1.5683 0.227,-3.1391 -0.826,-4.2515 l -0.127,-0.1339 1.287,-2.5545 -2.016,-1.0816 -1.27,2.5236 -0.187,-0.0412 c -0.52,-0.1271 -1.06,-0.1362 -1.583,-0.0269 -0.524,0.1094 -1.018,0.3347 -1.449,0.6604 l -0.294,-0.4146 c 0.462,-0.3473 0.988,-0.5937 1.546,-0.7243 0.558,-0.1306 1.135,-0.1426 1.698,-0.0354 L 286.098,30 289,31.5451 l -1.352,2.6832 c 1.15,1.3159 1.417,3.1468 0.711,4.9339 l -0.103,0.2575 -2.847,-1.527 0.035,-0.188 c 0.11,-0.6 0.165,-1.2644 -0.579,-1.6635 -0.271,-0.1365 -0.583,-0.164 -0.873,-0.0773 -0.167,0.0493 -0.323,0.134 -0.456,0.2489 -0.134,0.1148 -0.243,0.2573 -0.32,0.4181 -0.451,0.8961 -0.047,1.37 1.42,2.879 l 0.02,0.0206 c 1.804,1.8025 2.156,3.8369 1.04,6.0515 -0.335,0.6855 -0.81,1.2881 -1.392,1.7665 h 0.003 z"
     fill={stroke}
     id="path129" />
  <path
     d="m 188.099,60.9276 c 0.321,0.0153 0.635,-0.1062 0.875,-0.3388 0.24,-0.2327 0.389,-0.5583 0.413,-0.9081 0.025,-0.3498 -0.076,-0.6964 -0.28,-0.9666 -0.61,-0.7495 -1.229,-0.414 -2.049,0.0158 -1.68,0.8988 -2.851,0.4035 -3.599,-0.5242 -0.403,-0.4591 -0.65,-1.0546 -0.701,-1.6879 -0.05,-0.6333 0.099,-1.2666 0.422,-1.7949 L 182,53.2684 183.312,52 l 1.2,1.478 c 0.94,-0.4428 2.035,-0.262 2.92,0.6447 l -1.301,1.258 c -0.239,-0.1678 -0.748,-0.5242 -1.269,0 -0.181,0.1854 -0.289,0.4418 -0.298,0.7132 -0.009,0.2715 0.08,0.536 0.248,0.736 0.506,0.6263 1.024,0.3695 2.159,-0.1887 0.48,-0.2464 2.078,-1.0352 3.515,0.739 0.444,0.5088 0.715,1.1677 0.769,1.8677 0.054,0.7001 -0.112,1.3993 -0.471,1.9821 L 192,62.7316 190.688,64 l -1.2,-1.4885 c -2.039,1.0797 -3.275,-0.2621 -3.469,-0.5006 l -0.13,-0.1598 1.365,-1.3444 c 0.221,0.2547 0.524,0.4056 0.845,0.4209 z"
     fill="#ffffff"
     id="path130" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 189.047,61.5377 c -2.234,1.005 -3.581,-0.4146 -3.729,-0.5829 l -0.252,-0.294 1.715,-1.5075 0.126,0.1256 c 0.199,0.1992 0.467,0.3152 0.748,0.3247 0.282,0.0094 0.557,-0.0886 0.769,-0.2741 0.212,-0.1854 0.346,-0.4444 0.373,-0.7245 0.028,-0.28 -0.052,-0.5601 -0.224,-0.7834 -0.503,-0.5779 -0.979,-0.3945 -1.898,0.0452 l -0.028,0.0126 c -2.014,0.9874 -3.293,0.2513 -4,-0.5528 -0.326,-0.347 -0.561,-0.768 -0.686,-1.2265 -0.125,-0.4585 -0.136,-0.9405 -0.032,-1.4041 l 0.368,0.0879 c -0.09,0.4048 -0.08,0.8254 0.03,1.2252 0.111,0.3998 0.317,0.7667 0.602,1.0688 0.901,1.005 2.097,1.1734 3.555,0.4623 l 0.027,-0.0126 c 0.869,-0.4145 1.619,-0.7738 2.344,0.0453 0.239,0.2833 0.363,0.6454 0.347,1.0152 -0.015,0.3697 -0.168,0.7204 -0.43,0.983 -0.261,0.2626 -0.611,0.4182 -0.982,0.4362 -0.37,0.018 -0.734,-0.1029 -1.019,-0.339 l -1.171,1.0352 c 0.133,0.1483 1.352,1.4297 3.411,0.4347 l 0.129,-0.0628 1.236,1.3919 1.093,-0.9648 -1.151,-1.2965 0.282,-0.2512 1.4,1.5779 L 190.343,63 Z m 1.727,-3.0201 c 0.015,-0.735 -0.256,-1.4471 -0.755,-1.9874 -1.428,-1.6081 -2.959,-0.8945 -3.462,-0.6609 -1.214,0.5428 -1.871,0.8367 -2.493,0.1357 -0.11,-0.1211 -0.193,-0.2635 -0.245,-0.4183 -0.052,-0.1547 -0.071,-0.3186 -0.057,-0.4812 0.025,-0.2891 0.158,-0.5582 0.373,-0.7537 0.193,-0.1761 0.441,-0.2804 0.702,-0.2955 0.262,-0.0151 0.52,0.0599 0.733,0.2125 l 1.1,-0.9723 c -0.362,-0.3212 -0.807,-0.5335 -1.285,-0.6122 -0.478,-0.0787 -0.968,-0.0207 -1.414,0.1675 l -0.126,0.0552 -1.221,-1.3768 -1.093,0.9648 0.695,0.7814 -0.282,0.2512 L 181,52.4648 182.657,51 l 1.291,1.4573 c 0.536,-0.1955 1.117,-0.2329 1.673,-0.1077 0.556,0.1252 1.065,0.4077 1.464,0.8137 l 0.151,0.1407 -1.623,1.4347 -0.119,-0.0729 c -0.342,-0.211 -0.702,-0.3844 -1.108,-0.0276 -0.143,0.1307 -0.233,0.3097 -0.251,0.5025 -0.011,0.1115 0.003,0.2239 0.039,0.33 0.036,0.106 0.093,0.2033 0.17,0.2856 0.418,0.4723 0.795,0.3316 2.036,-0.2211 1.534,-0.7136 2.848,-0.4598 3.918,0.7537 0.556,0.6088 0.857,1.4074 0.841,2.2312 l 0.013,-0.0025 z"
     fill={stroke}
     id="path131" />
  <path
     d="M 152,24.503 V 13 h 1.015 V 23.9769 L 159,28.1729 158.416,29 Z"
     fill={stroke}
     id="path132" />
  <path
     d="m 164.884,32.6814 c 2.215,-2.5249 3.48,-5.6868 3.592,-8.9801 0.113,-3.2932 -0.933,-6.5275 -2.97,-9.1853 -2.038,-2.6578 -4.949,-4.58558 -8.267,-5.47496 -3.319,-0.88938 -6.854,-0.68894 -10.04,0.56924 L 147,9.15335 c 3.292,-1.30044 6.946,-1.50769 10.376,-0.5886 3.43,0.91909 6.439,2.91145 8.545,5.65825 2.106,2.7468 3.187,6.0895 3.071,9.4931 -0.117,3.4037 -1.424,6.6718 -3.713,9.2814 V 33 Z"
     fill={stroke}
     id="path133" />
  <path
     d="m 144.091,36.4806 c -2.539,-1.6448 -4.542,-3.9881 -5.765,-6.7441 -1.222,-2.756 -1.611,-5.8057 -1.119,-8.7775 0.492,-2.9717 1.844,-5.7371 3.89,-7.959 l 0.376,0.3401 c -2.341,2.5485 -3.727,5.8195 -3.926,9.2636 -0.198,3.444 0.802,6.851 2.834,9.6486 2.032,2.7975 4.971,4.815 8.324,5.7135 3.353,0.8985 6.915,0.6231 10.087,-0.7798 L 159,37.6471 c -2.003,0.894 -4.175,1.3552 -6.371,1.3529 -3.031,8e-4 -5.998,-0.8746 -8.538,-2.5194 z"
     fill={stroke}
     id="path134" />
  <path
     d="m 239.003,120 c -2.387,-0.003 -4.676,-0.926 -6.364,-2.566 -1.688,-1.641 -2.637,-3.866 -2.639,-6.186 V 97.3357 h 0.517 v 13.9123 c 0.002,2.187 0.897,4.283 2.488,5.829 1.591,1.546 3.748,2.416 5.998,2.419 h 16.981 c 2.25,-0.003 4.407,-0.873 5.998,-2.419 1.591,-1.546 2.486,-3.642 2.489,-5.829 V 95.7495 c -0.003,-2.1866 -0.898,-4.2828 -2.489,-5.8289 -1.591,-1.5462 -3.748,-2.416 -5.998,-2.4186 h -16.981 c -1.764,5e-4 -3.484,0.5348 -4.922,1.5288 -1.437,0.9941 -2.521,2.3984 -3.1,4.018 l -0.488,-0.1631 c 0.615,-1.7205 1.766,-3.212 3.293,-4.267 1.528,-1.0549 3.356,-1.6207 5.23,-1.6187 h 16.981 c 2.387,0.0027 4.675,0.9253 6.363,2.5656 1.688,1.6403 2.637,3.8642 2.64,6.1839 v 15.4985 c -0.002,2.322 -0.953,4.549 -2.644,6.19 -1.69,1.641 -3.982,2.563 -6.372,2.562 z"
     fill={stroke}
     id="path135" />
  <path
     d="m 242,93.334 h 0.504 v -1.8272 h 17.992 v 18.9862 h -2.854 V 111 H 261 V 91 h -19 z"
     fill={stroke}
     id="path136" />
  <path
     d="m 239.524,94.5013 v 5.3082 L 239,100 v -6 h 12 v 0.5013 z"
     fill={stroke}
     id="path137" />
  <path
     d="m 258,114 h -19 l 0.687,-0.252 0.687,-0.252 h 17.102 V 110 H 258 Z"
     fill={stroke}
     id="path138" />
  <path
     d="M 257.558,101 V 94.4868 H 255 V 94 h 3 v 7 z"
     fill={stroke}
     id="path139" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 254.45,95.6176 4.735,12.4134 -19.503,7.44 -4.735,-12.414 z"
     fill={detail}
     id="path140" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 235.727,104.785 c -0.235,-0.141 -0.426,-0.343 -0.554,-0.585 -0.127,-0.242 -0.186,-0.515 -0.17,-0.788 0.016,-0.273 0.106,-0.536 0.261,-0.762 0.155,-0.225 0.369,-0.404 0.618,-0.517 0.249,-0.113 0.525,-0.155 0.796,-0.122 0.272,0.032 0.53,0.138 0.745,0.307 0.216,0.168 0.381,0.392 0.479,0.648 0.086,0.227 0.116,0.471 0.087,0.712 -0.029,0.241 -0.115,0.472 -0.253,0.672 -0.138,0.2 -0.322,0.364 -0.536,0.477 -0.215,0.113 -0.454,0.172 -0.697,0.173 -0.273,0.001 -0.542,-0.074 -0.776,-0.215 z m 0.102,-2.199 c -0.195,0.144 -0.339,0.348 -0.411,0.58 -0.071,0.232 -0.066,0.481 0.014,0.71 0.081,0.23 0.232,0.427 0.433,0.564 0.201,0.137 0.44,0.206 0.683,0.197 0.243,-0.009 0.476,-0.095 0.666,-0.247 0.19,-0.151 0.327,-0.359 0.39,-0.594 0.064,-0.234 0.05,-0.483 -0.038,-0.709 -0.084,-0.214 -0.23,-0.397 -0.419,-0.527 -0.19,-0.129 -0.414,-0.198 -0.644,-0.198 -0.243,0.001 -0.479,0.079 -0.674,0.224 z"
     fill={stroke}
     id="path141" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M 249,98.1416 252.23,97 253,98.8584 249.77,100 Z m 0.976,1.4155 2.53,-0.8859 -0.497,-1.2169 -2.53,0.8859 z"
     fill={stroke}
     id="path142" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 254.044,102.727 0.135,0.356 -3.955,1.51 -0.136,-0.356 z"
     fill={stroke}
     id="path143" />
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 248.359,104.896 0.136,0.355 -5.981,2.283 -0.136,-0.356 z"
     fill={stroke}
     id="path144" />
  <path
     d="m 235,107.185 0.477,-0.185 2.739,7.328 14.603,-5.689 0.181,0.487 -15.08,5.874 z"
     fill={stroke}
     id="path145" />
  <path
     d="m 254.955,107.526 2.402,-0.931 -4.478,-11.9408 -11.7,4.5416 L 241,98.7216 253.168,94 258,106.887 255.134,108 Z"
     fill={stroke}
     id="path146" />
  <path
     d="m 233,102.154 5.807,-2.154 0.193,0.495 -5.297,1.967 0.92,2.35 -0.506,0.188 z"
     fill={stroke}
     id="path147" />
</svg>

    );
}
