import { User } from "firebase/auth";

export interface PremiumUser extends User {
  role: Role;
  isAdmin: boolean;
}

export enum Role {
  None = "none",
  Patient = "patient",
  Therapist = "therapist",
  Centre = "centre",
}


export function getRole(claims: any): Role {
  if (!!claims.overrideRole) {
    return claims.overrideRole;
  }
  if (!!claims.stripeRole) {
    return claims.stripeRole;
  }
  return Role.None;
}

export function roleToText(role: Role) {
  switch (role) {
    case Role.None: return "[role_none]";
    case Role.Patient: return "[role_patient]";
    case Role.Therapist: return "[role_therapist]";
    case Role.Centre: return "[role_centre]";
    default: return role;
  }
}