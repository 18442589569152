import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { LateralFeedbackData } from "../DataDefinitions";
import { renderBlackLegend } from "./legend";
import { useTranslation } from "../../contexts/TranslationContext";

interface LateralFeedbackGraphData {
	data: LateralFeedbackData
}

const RADIAN = Math.PI / 180;
const labelLateralidad = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" >
			{`${value}`}
		</text>
	);
};


function LateralFeedbackGraph(props: LateralFeedbackGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;

	return (<>
		<Typography><strong>{translate("[graphs_alarms_title]")}</strong></Typography>
		<ResponsiveContainer height={300}>
			<PieChart>
				<Legend formatter={renderBlackLegend} />
				<Pie
					width="100%"
					dataKey="value"
					startAngle={180}
					endAngle={0}
					data={
						[{ feedback: "left", value: props.data.leftFeedbackCount },
						 { feedback: "right", value: props.data.rightFeedbackCount }]}
					outerRadius={120}
					innerRadius={60}
					cy="80%"
					labelLine={false}
					label={labelLateralidad}>
					<Cell name={translate("[value_left]")} key="left-feedback" fill={colors.primary.dark} />
					<Cell name={translate("[value_right]")} key="right-feedback" fill={colors.primary.light} />
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	</>
	);
}

export default LateralFeedbackGraph;