import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Avatar from '../users/Avatar';
import { SharedPatientInvite, inviteStatusToText } from '../center/invite';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from '../../contexts/TranslationContext';

interface SharedPatientProps {
    invitation: SharedPatientInvite;
    icon: ReactElement;
    onAccept: any;
    onCancel: any;
}

export default function SharedPatient(props: SharedPatientProps) {
	const { translate } = useTranslation();
    const button =
        <>
            {props.invitation.status === "pending" &&
                <IconButton
                    aria-label="accept"
                    color="secondary"
                    onClick={props.onAccept}>
                    <CheckIcon />
                </IconButton>
            }
            <IconButton
                aria-label="cancel"
                color="secondary"
                onClick={props.onCancel}>
                <CloseIcon />
            </IconButton>
        </>

    return (
        <ListItem
            divider
            secondaryAction={button}>
            <ListItemAvatar>
                <Avatar name={props.invitation.patientInitials} id={props.invitation.patientID} />
            </ListItemAvatar>
            <ListItemText primary={translate(`[patients_sharedby] ${props.invitation.therapistEmail}`)}
                secondary={translate(`[patient_invitation_status] ${inviteStatusToText(props.invitation.status)}`)}
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }} />
        </ListItem>
    )
}
