import { createContext } from 'react';
import { Process } from '../data/Process';

interface ProcessesContextType {
	processes: Process[];
	setProcesses: (processes: Process[]) => void;
}

export const ProcessesContext = createContext<ProcessesContextType>({
	processes: [],
	setProcesses: (processes: Process[]) => { },
});
