export enum ProcessStatus {
  NotStarted,
  Initialized,
  Playing,
  Paused,
  Finished
}

export interface TrainingAddress{
	processID: string,
  modifiers: any
}

export interface TrainingData {
	trainingAddress: TrainingAddress,
	instruction: string,
  status: ProcessStatus,
}