import { Typography } from "@mui/material";
import { Palette, useTheme } from '@mui/material/styles';
import { useState } from "react";
import { Bar, Cell, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DeliveryData, DeliveryStatus, GrillData, IngredientType, RingData } from "../GrillData";
import { renderBlackLegend } from "./legend";
import { useTranslation } from "../../contexts/TranslationContext";

interface GrillDeliveryGraphData {
	data: GrillData
}

const BarTooltip = (props: any) => {
	const { translate } = useTranslation();
	if (!props.data) {
		return <></>;
	}
	const data: DeliveryData = props.data;

	const ingredientToEmoji = (ingredient: IngredientType) => {
		switch (ingredient) {
			case IngredientType.Onion:
				return "🧅";
			case IngredientType.Pepper:
				return "🌶️";
			case IngredientType.Tomato:
				return "🍅";
			case IngredientType.Chicken:
				return "🍗";
			case IngredientType.Beef:
				return "🥩";
			case IngredientType.Courgette:
				return "🥒";
			case IngredientType.Mushroom:
				return "🍄";
		}
	}

	const statusToText = (delivery: DeliveryStatus) => {
		if (delivery === DeliveryStatus.Correct) {
			return translate("[graphs_grill_status_correct]");
		}

		if (delivery === DeliveryStatus.Ammended) {
			return translate("[graphs_grill_status_ammended]");
		}

		let result = `${translate("[graphs_grill_status_incorrect]")}: `;

		if ((delivery & DeliveryStatus.Raw) !== 0 && (delivery & DeliveryStatus.TimedOut) === 0) {
			result += ` ${translate("[graphs_grill_status_raw]")}.`;
		}
		if ((delivery & DeliveryStatus.Burnt) !== 0) {
			result += ` ${translate("[graphs_grill_status_burnt]")}.`;
		}
		if ((delivery & DeliveryStatus.IncorrectRecipe) !== 0) {
			result += ` ${translate("[graphs_grill_status_incorrect_recipe]")}.`;
		}
		if ((delivery & DeliveryStatus.IncorrectOrder) !== 0) {
			result += ` ${translate("[graphs_grill_status_incorrect_order]")}.`;
		}
		if ((delivery & DeliveryStatus.IncorrectTray) !== 0) {
			result += ` ${translate("[graphs_grill_status_incorrect_tray]")}.`;
		}
		if ((delivery & DeliveryStatus.Discarded) !== 0) {
			result += ` ${translate("[graphs_grill_status_discarded]")}.`;
		}
		if ((delivery & DeliveryStatus.Ammended) !== 0) {
			result += ` ${translate("[graphs_grill_status_re_entregado]")}.`;
		}

		return result
	}

	const ingredients = data.ingredients.map(ing => ingredientToEmoji(ing));

	return (
		<div className="custom-tooltip">
			<p className="label">{ingredients}</p>
			<p className="label">🍡 {statusToText(data.status)}</p>
			<p className="label">⏱ {translate("[value_creationtime]")}: {data.time.toFixed(2)}</p>
			<p className="label">🕰️ {translate("[graphs_grill_delivery_time]")}: {data.timeSinceStart.toFixed(2)}</p>
		</div>
	);
};

const ringLineData = (ring: RingData, colors: Palette): any => {
	if (ring.answered && ring.isTarget) {
		return {
			time: ring.time,
			color: colors.primary.main,
			label: "[graphs_grill_ring_correct_answer]",
			stroke: "0"
		}
	} else if (!ring.answered && !ring.isTarget) {
		return {
			time: ring.time,
			color: colors.primary.main,
			label: "[graphs_grill_ring_correct_noanswer]",
			stroke: "3 3"
		}
	}
	else if (!ring.answered && ring.isTarget) {
		return {
			time: ring.time,
			color: colors.error.main,
			label: "[graphs_grill_ring_missed]",
			stroke: "0"
		}
	} else if (ring.answered && !ring.isTarget) {
		return {
			time: ring.time,
			color: colors.error.main,
			label: "[graphs_grill_ring_error]",
			stroke: "3 3"
		}
	}
};

function ReactionTimeGraph(props: GrillDeliveryGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;

	const data = props.data;
	const deliveries = data.deliveries
		.filter(d => (d.status & DeliveryStatus.TimedOut) === 0)
		.sort((a, b) => a.timeSinceStart - b.timeSinceStart)
		//ensure there is at least 7 seconds between each delivery
		.map((d, index, array) => {
			if (index === 0) {
				return d;
			}
			const previous = array[index - 1];
			if (d.timeSinceStart - previous.timeSinceStart < 7) {
				d.timeSinceStart = previous.timeSinceStart + 7;
			}
			return d;
		});

	
	

	const rings = data.rings.map((ring: RingData, index) => ringLineData(ring, colors));

	const totalTime = data.totalSeconds;

	const [tooltipData, settooltipData] = useState<DeliveryData>();


	const barColor = (entry: any): string => {
		if (entry.status === undefined) {
			return colors.primary.light;
		}

		if (entry.status === DeliveryStatus.Correct) {
			return colors.primary.main;
		}

		if (entry.status === DeliveryStatus.Ammended) {
			return colors.primary.light;
		}

		if ((entry.status & DeliveryStatus.Discarded) !== 0) {
			return colors.secondary.main;
		}
		return colors.error.main;
	};

	return (
		<ResponsiveContainer width="100%" height="100%">
			<ComposedChart data={deliveries}>

				<XAxis dataKey="timeSinceStart" domain={[0, totalTime]}
					type="number" allowDecimals={false} />
				<YAxis dataKey="time" domain={[0, (dataMax: number) => (dataMax.toFixed(2))]} />
				<Tooltip content={<BarTooltip data={tooltipData} />} cursor={false} />
				<Bar name="Tiempo de creación" dataKey="time" maxBarSize={10}
					onMouseEnter={(e: any) => settooltipData(e)}>
					{deliveries.map((entry, index) => (
						<Cell fill={barColor(entry)} key={index} width={10} />
					))}
				</Bar>

				{rings.map((ring, index) => (
					<ReferenceLine
						key={`ring_${index}`}
						x={ring.time}
						stroke={ring.color}
						strokeDasharray={ring.stroke}
						strokeWidth={2}
						isFront />
				))}

				<Legend formatter={renderBlackLegend}
					payload={[
						{ value: translate("[graphs_grill_status_correct]"), type: "square", id: "ID01", color: colors.primary.main },
						{ value: translate("[graphs_grill_status_ammended]"), type: "square", id: "ID02", color: colors.primary.light },
						{ value: translate("[graphs_grill_status_discarded]"), type: "square", id: "ID03", color: colors.secondary.main },
						{ value: translate("[graphs_grill_status_incorrect]"), type: "square", id: "ID04", color: colors.error.main },

						{ value: translate("[graphs_grill_ring_correct_answer]"), type: "plainline", id: "ID05", color: colors.primary.main, payload: { strokeDasharray: "0" } },
						{ value: translate("[graphs_grill_ring_correct_noanswer]"), type: "plainline", id: "ID06", color: colors.primary.main, payload: { strokeDasharray: "3 3" } },
						{ value: translate("[graphs_grill_ring_missed]"), type: "plainline", id: "ID07", color: colors.error.main, payload: { strokeDasharray: "0" } },
						{ value: translate("[graphs_grill_ring_error]"), type: "plainline", id: "ID08", color: colors.error.main, payload: { strokeDasharray: "3 3" } },
					]}
				/>
			</ComposedChart>
		</ResponsiveContainer>

	)
}

function GrillDeliveryGraph(props: GrillDeliveryGraphData) {
	const { translate } = useTranslation();
	return (
		<>
			<Typography><strong>{translate("[graphs_grill_delivered_skewers]")}</strong></Typography>
			<ResponsiveContainer height={500}>
				<ReactionTimeGraph
					data={props.data} />
			</ResponsiveContainer>
		</>
	);
}

export default GrillDeliveryGraph;
