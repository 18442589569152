export enum RemoteControlTag {
	trainingRequested = "trainingRequested",
	patientRequested = "patientRequested",
	stageRequested = "stageRequested",
	vibranceRequested = "vibranceRequested",
	readingSpeed = "readingSpeed",
	itemsSpacing = "itemsSpacing",
	handMaterialRequested = "handMaterialRequested",
	hemiparesis = "hemiparesis",
	smoothTremors = "smoothTremors",
	enableTTS = "enableTTS",
	playPauseTraining = "playPauseTraining",
	skipTrial = "skipTrial",
	nextExercise = "nextExercise",
	stopTraining = "stopTraining",
	cleanTable = "cleanTable",
	addReadingTime = "addReadingTime",
	skipReadingTime = "skipReadingTime",
	recenter = "recenter",
	calibrate = "calibrate",
	hideTablet = "hideTablet",
	clue = "clue",
	lateralFeedback = "lateralFeedback",
	videoStreaming = "videoStreaming",
	pointAt = "pointAt",
	hideButton = "hideButton",
	tutorial = "tutorial",
	heightOffset = "heightOffset",
	locuteRequested = "locuteRequested",
	lateralityPosition = "lateralityPosition",
	innactivityEnabled = "innactivityEnabled",
	trainingChanged = "trainingChanged",
	settingsSync = "settingsSync",
	ipadVisibility = "ipadVisibility",
	playButtonVisibility = "playButtonVisibility",
	tableHeight = "tableHeight",
	autoGrab = "autoGrab",
	quickAction = "quickAction",
}


export interface RemoteLetter {
	tag: RemoteControlTag,
	value: string,
}

export function toRemoteControlMessage(tag: RemoteControlTag, value: string | null | undefined = null): string {
	return `${RemoteControlTag[tag]}:${value}`;
}

export function fromRemoteControlMessage(message: string): RemoteLetter {
	const separatorIndex = message.indexOf(':');
	const tag = message.substring(0, separatorIndex) as RemoteControlTag;
	const body = message.substring(separatorIndex + 1) ?? "";
	return {
		tag: tag,
		value: body,
	}
}