import telerehabSketch from "../../media/landing/telerehabSketch.png";
import posterDCA from "../../media/landing/posterDCA.png";
import posterNeglect from "../../media/landing/posterNeglect.png";

export const researchData =
{
	"title": "[research_title]",
	"read": "[research_read]",
	"goal": "[research_goal]",
	"image": telerehabSketch,
	"paragraph": "[research_paragraph]",
	"studies": [
		{
			"title": "[research_studyRTC_title]",
			"description": "[research_studyRTC_description]",
			"image": posterDCA,
			"url": "https://drive.google.com/file/d/1kuUkG9VCeosVz1EzEBOB42nfr2kMGTbf/"
		},
		{
			"title": "[research_studycaso_title]",
			"description": "[research_studycaso_description]",
			"image": posterNeglect,
			"url": "https://drive.google.com/file/d/1hQjUzgn_7dzbJLlGoC1cgVIOYd2Rm1G6/view?usp=drive_link"
		},
	],
}