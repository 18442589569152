import { Box, useTheme } from "@mui/material";
import TherapistLogo from "../../media/TherapistLogo";
import PatientLogo from "../../media/PatientLogo";
import CenterLogo from "../../media/CenterLogo";
import { Role } from "../login/PremiumUser";
import UserNoLicenseLogo from "../../media/UserNoLicenseLogo";

interface UserLogoProps {
	role: Role,
	width: any,
}

function UserLogo(props: UserLogoProps) {

	const theme = useTheme();

	const role = props.role;

	return (
		<Box maxWidth={props.width} display="flex" justifyContent="center" alignItems="center">
			{role === Role.Therapist &&
				<TherapistLogo width="100%"
					background={theme.palette.secondary.light}
					detail={theme.palette.primary.light}
					stroke={theme.palette.primary.dark}
					skin="white"
					shirt={theme.palette.primary.main} />
			}
			{role === Role.Patient &&
				<PatientLogo width="100%"
					background={theme.palette.secondary.light}
					detail={theme.palette.primary.light}
					stroke={theme.palette.primary.dark}
					skin="white"
					shirt={theme.palette.primary.main} />
			}
			{role === Role.Centre &&
				<CenterLogo width="100%"
					background={theme.palette.secondary.light}
					detail={theme.palette.primary.light}
					stroke={theme.palette.primary.dark}
					skin="white"
					shirt={theme.palette.primary.main} />
			}
			{role === Role.None &&
				<UserNoLicenseLogo
					width="70%"
					background={theme.palette.secondary.light}
					tshirt={theme.palette.primary.main}
					stroke={theme.palette.primary.dark}
				/>
			}
		</Box>
	);
}

export default UserLogo;