import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { CachedDocument } from "../../adapters/Firebase";
import { colorMap } from "../../components/atoms/ProcessPill";
import { useTranslation } from "../../contexts/TranslationContext";

interface SessionPieChartProps {
    sessions: CachedDocument[]
}

interface SessionPieItem {
    processID: string;
    count: number;
    color: string;
}

function SessionsPieChart(props: SessionPieChartProps) {
	const { translate } = useTranslation();
    //group the sessions by processID
    const pieData: SessionPieItem[] = props.sessions.reduce((acc: SessionPieItem[], session: CachedDocument) => {
        const processID = session.doc.processID;
        const index = acc.findIndex((item) => item.processID === processID);
        if (index === -1) {
            acc.push({
                processID: processID,
                count: 1,
                color: colorMap[processID]
            });
        } else {
            acc[index].count++;
        }
        return acc;
    }, []);

    return (
        <ResponsiveContainer height={300} >
            <PieChart >
                <Pie
                    data={pieData}
                    dataKey="count"
                    nameKey="processID"
                    labelLine
                    label={({ name, value }) => translate(`[process_${[name]}] (${[value]})`)}
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );

}

export default SessionsPieChart;