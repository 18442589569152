
export interface CleanSurfaceData {
    totalTime: number;
    cells: CellData[];
}

export interface CellData {
    completed: boolean;
    completionTime: number;
    coordinates: Coordinates;
    stainColor: "Red" | "Blue";
    spongeColor: "Red" | "Blue";
}

export interface Coordinates {
    x: number;
    y: number;
}

function extractCoordinates(coordinates: string): Coordinates {
    const coords = coordinates.substring(1, coordinates.length - 1);
    const [coordX, coordY] = coords.split(',');
    return { x: parseFloat(coordX), y: parseFloat(coordY) };
}

function extractCellsData(data: any): CellData[] {
    const items = data.map((entry: any): CellData => {
        const coordinates = extractCoordinates(entry.coordinates);
        const expectedColor = coordinates.x > 0.5 ? "Blue" : "Red";
        return {
            completed: entry.completed,
            completionTime: Number(entry.completionTime),
            coordinates: extractCoordinates(entry.coordinates),
            stainColor: entry.stainColor === undefined ? expectedColor : entry.stainColor === 0 ? "Red" : "Blue",
            spongeColor: entry.spongeColor === undefined ? expectedColor : entry.spongeColor === 0 ? "Red" : "Blue",
        }
    }
    );
    return items;
}

function extractCleanSurfaceData(sessionSelected: any): CleanSurfaceData {
    const data = sessionSelected.blocks;
    return {
        totalTime: data.totalTime,
        cells: extractCellsData(data.cells),
    }
}


export default extractCleanSurfaceData;