import { useMemo, useState } from "react";
import GraphContainer from "./Graphs/GraphContainer";
import { CachedDocument } from "../adapters/Firebase/Firebase";
import extractGrillData from "./GrillData";
import { GrillData } from "./GrillData";
import GrillDeliveryGraph from "./Graphs/GrillDeliveryGraph";
import AveragegGrillCard from "./Graphs/AverageGrillCard";
import GrillErrorsGraph from "./Graphs/GrillErrorsGraph";
import AIAssistant, { serializeAIData } from "./AIAssistant";

interface GrillGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}

const aiPrompt = (data: any) =>
	"Here is the data of a session of a neurological patient using a VR exercise in which the user must ellaborate multiple skewers in the given time. The receipts for the skewers are presented above the grill, and they must be perfectly cooked in both sides and delivered in the tray. During the exercise, the door bell will ring some times and the user must answer by pressing the button only when a friend is ringing (the bell does a distinctive sound)." +
	`\n${serializeAIData(data)}` +
	"\nPlease elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it."

function GrillSession(props: GrillGraphData) {
	const session: CachedDocument = props.sessionDoc;

	const [sessionData, setSessionData] = useState<GrillData | undefined>(undefined);

	useMemo(() => {
		const data = extractGrillData(session.doc);
		setSessionData(data);
	}, [session])

	if (sessionData === undefined) {
		return <></>;
	}

	return (
		<>
			<GraphContainer size='big' order={0} width={props.graphElements && props.graphElements > 1 ? `45%` : '100%'}>
				{props.children}
				<GrillDeliveryGraph
					data={sessionData} />
			</GraphContainer>
			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AveragegGrillCard data={sessionData} />
			</GraphContainer>
			<GraphContainer order={2} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<GrillErrorsGraph data={sessionData} />
			</GraphContainer>

			<GraphContainer order={5} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AIAssistant prompt={aiPrompt(sessionData)} />
			</GraphContainer >
		</>);
}

export default GrillSession;
