import { Box, Typography, useTheme } from "@mui/material";
import { CartesianGrid, Cell, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import { renderBlackLegend } from "../legend";
import { CellData, CleanSurfaceData } from "./CleanSurfaceData";
import { useTranslation } from "../../../contexts/TranslationContext";

interface CleanSurfaceCoordinatesGraphData {
	data: CleanSurfaceData
}

function extractCoordinates(cells: CellData[]): { x: number, y: number, z: number, isError: boolean }[] {
	let data = cells.map((entry) => {
		return {
			x: entry.coordinates.x,
			y: entry.coordinates.y,
			z: entry.completionTime,
			isError: !entry.completed || entry.spongeColor !== entry.stainColor
		}
	});

	//short them by z
	data.sort((a, b) => a.z - b.z);

	//get the relative z from the previous one
	let previousZ = 0;
	data.forEach((entry) => {
		entry.z = entry.z - previousZ;
		previousZ += entry.z;
	});

	if (!!data && data.length > 0) {
		data[0].z = 0;
	}

	return data;
}

function CleanSurfaceCoordinatesGraph(props: CleanSurfaceCoordinatesGraphData) {
    const { translate } = useTranslation();
	const colors = useTheme().palette;

	const redColor = colors.primary.dark;
	const blueColor = colors.primary.light;
	const errorColor = colors.error.main;
	const missingColor = colors.secondary.main;

	const blueCells = extractCoordinates(props.data.cells.filter((entry) => entry.completed && entry.spongeColor === "Blue"));
	const redCells = extractCoordinates(props.data.cells.filter((entry) => entry.completed && entry.spongeColor === "Red"));

	const uncompletedCells = extractCoordinates(props.data.cells.filter((entry) => !entry.completed));

	return (<>
		<Typography>
			<strong>
				{translate("[graphs_cleansurface_distribution]")}
			</strong>
		</Typography>

		<Box flexDirection="row" display="flex" justifyContent="space-between">

			<ResponsiveContainer height={300} >
				<ScatterChart>
					<XAxis hide={true} domain={[0.0, 1.0]} type="number" dataKey="x" />
					<YAxis hide={true} domain={[0.0, 1.0]} type="number" dataKey="y" />
					<ZAxis range={[200, 800]} domain={[0, 10]} dataKey="z" />
					<CartesianGrid />
					<Legend formatter={renderBlackLegend}
						payload={
							[{ id: "redstains", value: translate("[graphs_cellsurface_distribution_red]"), type: "circle", color: redColor },
							{ id: "blueStains", value: translate("[graphs_cellsurface_distribution_blue]"), type: "circle", color: blueColor },
							{ id: "errorStains", value: translate("[graphs_cellsurface_distribution_errors]"), type: "circle", color: errorColor },
							{ id: "missingStains", value: translate("[graphs_cellsurface_distribution_missing]"), type: "square", color: missingColor },
							]
						}
					/>
					<Scatter name={translate(`[graphs_cellsurface_distribution_red]`)}
						line
						shape="circle"
						data={redCells}
						fill={redColor}>
						{redCells.map((entry, index) => {
							return <Cell key={`cell-${index}`} fill={entry.isError ? errorColor : redColor} />
						})}
					</Scatter>
					<Scatter name={translate(`[graphs_cellsurface_distribution_blue]`)}
						line
						shape="circle"
						data={blueCells}
						fill={blueColor}>
						{blueCells.map((entry, index) => {
							return <Cell key={`cell-${index}`}
								fill={entry.isError ? errorColor : blueColor} />
						})}
					</Scatter>

					<Scatter
						shape="star"
						data={[redCells[0]]}
						fill={redColor}>
						{redCells.map((entry, index) => {
							return <Cell key={`cell-${index}`} fill={entry.isError ? errorColor : redColor} />
						})}
					</Scatter>
					<Scatter
						shape="star"
						data={[blueCells[0]]}
						fill={blueColor}>
						{blueCells.map((entry, index) => {
							return <Cell key={`cell-${index}`}
								fill={entry.isError ? errorColor : blueColor} />
						})}
					</Scatter>

					<Scatter name={translate(`[graphs_cellsurface_distribution_errors]`)}
						shape="square"
						data={uncompletedCells}
						fill={missingColor} />
					<Tooltip />
				</ScatterChart>
			</ResponsiveContainer>
		</Box>
	</>);
}

export default CleanSurfaceCoordinatesGraph;