import { Unsubscribe } from "firebase/firestore";
import { ReactNode, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { publishICE, publishOffer, subscribeToAnswers, subscribeToICEs, submitPatientSettings } from "../../adapters/Firebase";
import { defaultRemoteControlState, ModifiedProcess, RemoteControlState } from "../../contexts/RemoteControlState";
import { Patient, PatientSettingsData, serializeSettings } from "../../data/Patient";
import { RemoteClient } from "../../data/RemoteClient";
import { RemoteControlContext } from "./RemoteControl.context";
import { fromRemoteControlMessage, RemoteControlTag, toRemoteControlMessage } from "./remoteControlMessage";
import { TrainingData } from "./TrainingData";
import { UserContext } from "../../contexts/User.context";

interface RemoteControlProviderProps {
	children: ReactNode;
}

const rtcConfiguration: RTCConfiguration = {
	iceServers: [
		{
			urls: "stun:stun.relay.metered.ca:80",
		},
		{
			urls: "turn:a.relay.metered.ca:80",
			username: "d6018ab0466f677555586c0a",
			credential: "z2O/8tBq2qJsIK8Z",
		},
		{
			urls: "turn:a.relay.metered.ca:80?transport=tcp",
			username: "d6018ab0466f677555586c0a",
			credential: "z2O/8tBq2qJsIK8Z",
		},
		{
			urls: "turn:a.relay.metered.ca:443",
			username: "d6018ab0466f677555586c0a",
			credential: "z2O/8tBq2qJsIK8Z",
		},
		{
			urls: "turn:a.relay.metered.ca:443?transport=tcp",
			username: "d6018ab0466f677555586c0a",
			credential: "z2O/8tBq2qJsIK8Z",
		},
	],
};

function RTCCandidateToInitCandidate(candidate: RTCIceCandidate) {
	return {
		type: "webcandidate",
		candidate: candidate.candidate,
		sdpMid: candidate.sdpMid,
		sdpMLineIndex: candidate.sdpMLineIndex
	}
}

type ClientAction =
	| { type: "register"; client: RemoteClient }
	| { type: "ice"; id: string; candidate: RTCIceCandidateInit }
	| { type: "clear" };

function clientReducer(state: RemoteClient[], action: ClientAction) {
	switch (action.type) {
		case "register":
			let client: RemoteClient = action.client;
			client.ices = [];
			return [...state, action.client];
		case "ice":
			return state.map(client => {
				if (client.id === action.id) {
					let newClient = { ...client };
					newClient.ices = [...newClient.ices, action.candidate];
					return newClient;
				}
				return client;
			});
		case "clear":
			return [];
		default:
			throw new Error();
	}
}

function RemoteControlProvider(props: RemoteControlProviderProps) {

	const user = useContext(UserContext);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [state, setState] = useState<RemoteControlState>(defaultRemoteControlState);
	const [currentTraining, setCurrentTraining] = useState<TrainingData | null>(null);
	const eventTarget = useRef(new EventTarget()).current;

	const [clients, dispatchClient] = useReducer(clientReducer, [] as RemoteClient[]);
	const [videoStream, setVideoStream] = useState<MediaStream>();
	const [audioStream, setAudioStream] = useState<MediaStream>();
	const [voiceTrack, setVoiceTrack] = useState<MediaStreamTrack | null>(null);
	const [microphoneMuted, setMicrophoneMuted] = useState(true);
	const [speakerOn, setSpeakerOn] = useState(true);
	const [remoteUserID, setRemoteUserID] = useState<string | null>(null);

	const peerConnection = useRef<RTCPeerConnection | null>(null);
	const dataChannel = useRef<RTCDataChannel | null>(null);
	const selectedClient = useRef<RemoteClient | null>(null);

	const answerListener = useRef<Unsubscribe | null>(null);
	const icesListeners = useRef<Unsubscribe[]>([]);

	const offerID = useRef<string | null>(null);

	const sendMessage = useCallback((message) => {
		if (dataChannel.current?.readyState === "open") {
			dataChannel.current.send(message);
		} else {
			console.log("Data channel is not open. Current state: " + dataChannel.current?.readyState);
		}
	}, []);

	const setPatientSettings = (settings: PatientSettingsData) => {
		setState((state) => {
			if (state?.patient != null) {
				return {
					...state,
					patient: { ...state.patient, settings: settings }
				};
			}
			return state;
		});
	}

	const commitPatientSettings = () => {
		if (state.patient
			&& state.patient.settings
			&& !!remoteUserID) {

			submitPatientSettings(
				remoteUserID,
				state.patient.id,
				state.patient.settings);
		}
	}

	const setProcesses = (processes: ModifiedProcess[]) => {
		setState((state: RemoteControlState): RemoteControlState => {
			return {
				...state,
				processes: processes
			};
		});
	}

	const updatePatient = useCallback(() => {
		if (!!state.patient) {
			const message = toRemoteControlMessage(
				RemoteControlTag.patientRequested,
				state.patient?.id);
			sendMessage(message);
		}
	}, [sendMessage, state.patient]);

	const updatePatientSettings = useCallback(() => {
		if (!!(state.patient?.settings)) {
			const allSettings = serializeSettings(state.patient.settings);
			allSettings.forEach((setting) => {
				sendMessage(setting);
			});
		}
	}, [sendMessage, state.patient?.settings]);

	const onClientConnected = useCallback((client: RemoteClient | null) => {
		if (client != null) {

			//register the client
			setState((state) => {
				return {
					...state,
					remoteClient: client
				};
			});

			//add a one second delay to allow the client to be fully connected
			setTimeout(() => {
				updatePatient();
				updatePatientSettings();
			}, 1000);

			eventTarget.dispatchEvent(new Event('clientConnected'));
		}
	}, [eventTarget, updatePatient, updatePatientSettings]);

	const onDisconnected = useCallback(() => {
		setState(defaultRemoteControlState);
		setCurrentTraining(null);
	}, []);

	const onMessageReceived = useCallback((message: string) => {
		const letter = fromRemoteControlMessage(message);
		switch (letter.tag) {
			case RemoteControlTag.trainingChanged:
				const trainingData = JSON.parse(letter.value) as TrainingData;
				setCurrentTraining(trainingData);
				break;
		}
	}, []);

	const clear = useCallback(() => {
		dispatchClient({ type: "clear" });

		answerListener.current?.();
		answerListener.current = null;

		icesListeners.current?.forEach((listener: Unsubscribe) => listener());
		icesListeners.current = [];

		offerID.current = null;
	}, []);

	const disconnect = useCallback(() => {
		dataChannel.current?.close();

		if (peerConnection.current?.connectionState !== "closed") {
			peerConnection.current?.getSenders().forEach(sender => {
				peerConnection.current?.removeTrack(sender);
			});
		}

		peerConnection.current?.close();

		voiceTrack?.stop();
		videoStream?.getTracks().forEach(track => track.stop());
		audioStream?.getTracks().forEach(track => track.stop());

		clear();
	}, [clear, videoStream, audioStream, voiceTrack]);

	const connect = useCallback((remoteUserID: string) => {
		disconnect();

		if (!remoteUserID) {
			return;
		}

		peerConnection.current = new RTCPeerConnection(rtcConfiguration);

		peerConnection.current.onconnectionstatechange = (ev: Event) => {
			switch (peerConnection.current?.connectionState) {
				case "disconnected":
					onDisconnected();
					clear();
					break;
				case "connected":
					break;
			}
		}

		const videoTransceiver = peerConnection.current.addTransceiver('video');
		videoTransceiver.direction = 'recvonly';
		const voiceTransceiver = peerConnection.current.addTransceiver('audio');
		voiceTransceiver.direction = 'sendrecv';

		dataChannel.current = peerConnection.current.createDataChannel("messages");

		dataChannel.current.onopen = () => {
			onClientConnected(selectedClient.current);

			navigator.mediaDevices.getUserMedia({
				audio: {
					sampleRate: 8000,
					channelCount: 1,
					echoCancellation: true,
					noiseSuppression: true,
					autoGainControl: true
				}
			})
				.then(localStream => {
					const track = localStream.getAudioTracks()[0];
					voiceTransceiver.sender.replaceTrack(track);
					track.enabled = !microphoneMuted;
					setVoiceTrack(track);
				});
		};

		dataChannel.current.onclose = () => {
			clear();
		};

		dataChannel.current.onmessage = (event: MessageEvent<any>) => {
			onMessageReceived(event.data);
		};

		peerConnection.current?.createOffer()
			.then(async (offer) => {
				if (peerConnection.current) {

					const offerDocId = await publishOffer(remoteUserID, {
						type: offer?.type,
						sdp: offer?.sdp,
					});

					peerConnection.current.ontrack = (event: RTCTrackEvent) => {
						if (event.track.kind === "video") {
							const stream = new MediaStream([event.track]);
							setVideoStream(stream);
						}
						else if (event.track.kind === "audio") {
							const stream = new MediaStream([event.track]);
							setAudioStream(stream);
						}
					};

					peerConnection.current.onicecandidate = ({ candidate }) => {
						if (!!candidate) {
							publishICE(remoteUserID, offerDocId!, RTCCandidateToInitCandidate(candidate));
						}
					};

					await peerConnection.current?.setLocalDescription(offer);

					answerListener.current = subscribeToAnswers(remoteUserID, offerDocId!, (answerId: string, answer: any) => {
						const data: RemoteClient = { id: answerId, ...answer } as RemoteClient;
						dispatchClient({ type: "register", client: data });

						const icesListener = subscribeToICEs(remoteUserID, offerDocId!, answerId, (ice: any) => {
							const candidate: RTCIceCandidateInit = ice as RTCIceCandidateInit;
							dispatchClient({ type: "ice", id: answerId, candidate: candidate });
							if (!!peerConnection.current?.remoteDescription) {
								peerConnection.current?.addIceCandidate(candidate);
							}
						});
						icesListeners.current?.push(icesListener);
					});

					offerID.current = offerDocId;
				}
			});
	}, [clear, disconnect, onClientConnected, onDisconnected, onMessageReceived]);

	const connectToClient = useCallback(async (client: RemoteClient) => {
		if (!peerConnection.current) {
			return;
		}
		selectedClient.current = client;
		const remoteDescription = new RTCSessionDescription(client.answer);
		await peerConnection.current?.setRemoteDescription(remoteDescription);
		for (const ice of client.ices) {
			await peerConnection.current?.addIceCandidate(ice);
		}

	}, [peerConnection]);

	const setRemoteUser = useCallback((remoteUserID: string | null, patient: Patient | null) => {
		disconnect();

		setRemoteUserID(remoteUserID);
		setState((state: RemoteControlState): RemoteControlState => {
			return {
				...defaultRemoteControlState,
				patient: patient
			};
		});

	}, [disconnect]);

	const refresh = useCallback(() => {
		if (!!remoteUserID) {
			connect(remoteUserID);
		}
		else {
			disconnect();
		}
	}, [connect, remoteUserID, disconnect]);

	useEffect(() => {
		if (voiceTrack) {
			voiceTrack.enabled = !microphoneMuted;
		}
	}, [microphoneMuted, voiceTrack]);

	useEffect(() => {
		if (audioRef.current && audioStream) {
			audioRef.current.srcObject = audioStream;
			audioRef.current.play();
		}
	}, [audioStream, audioRef.current]);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.muted = !speakerOn;
		}
	}, [speakerOn, audioRef.current]);

	//disconnect when user is changed
	useEffect(() => {
		if (!user) {
			disconnect();
			onDisconnected();
		}
	}, [disconnect, onDisconnected, user]);

	//notify patient settings change
	useEffect(() => {
		updatePatientSettings();
	}, [state.patient?.settings, state.remoteClient, updatePatientSettings, sendMessage]);

	return (
		<RemoteControlContext.Provider value={{
			userID: remoteUserID,
			state: state,
			setPatientSettings: setPatientSettings,
			commitPatientSettings: commitPatientSettings,
			setProcesses: setProcesses,
			currentTraining: currentTraining,
			eventTarget: eventTarget,
			clients: clients,
			videoStream: videoStream,
			audioStream: audioStream,
			speakerOn: speakerOn,
			setSpeakerOn: setSpeakerOn,
			microphoneMuted: microphoneMuted,
			muteMicrophone: setMicrophoneMuted,
			connectToClient: connectToClient,
			sendMessage: sendMessage,
			setRemoteUser: setRemoteUser,
			refresh: refresh,
			disconnect: () => { disconnect(); onDisconnected() },
		}} >
			<audio ref={audioRef} autoPlay loop />
			{props.children}
		</RemoteControlContext.Provider>
	);
}

export default RemoteControlProvider;