import { Button } from '@mui/material';
import Icon from '../../../components/atoms/Icon';
import { useTranslation } from '../../../contexts/TranslationContext';


export interface QuickActionData {
	name: string;
	icon: any;
	message: string;
	variant: "contained" | "outlined" | "text";
}

interface QuickActionButtonProps {
	data: QuickActionData;
	onClick: () => void;
}

function QuickActionButton(props: QuickActionButtonProps) {
	const { translate } = useTranslation();
	const title = translate(props.data.name);
	const icon: string = props.data.icon ?? "interference";

	return (
		<Button variant={props.data.variant} fullWidth onClick={props.onClick}
			startIcon={<Icon name={icon} />}>
			{title}
		</Button>
	);
};

export default QuickActionButton; 