import { Box, Button, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Patient, PatientSettingsData } from "../../data/Patient";
import Patients from "../users/Patients";
import { ModifiedProcess } from "../../contexts/RemoteControlState";
import Processes from "../remoteControl/processes/Processes";
import Avatar from "../users/Avatar";
import SettingsIcon from '@mui/icons-material/Settings';
import AlertDialog from "../../components/atoms/Popup";
import PatientSettings from "../remoteControl/settings/PatientSettings";
import { UserContext } from "../../contexts/User.context";
import { getSchedule, submitPatientSettings, submitSchedule } from "../../adapters/Firebase";
import { ProcessesContext } from "../../contexts/Processes.context";
import { Process } from "../../data/Process";
import { useTranslation } from "../../contexts/TranslationContext";

interface SerializedProcess {
	processID: string;
	modifiers: any;
}

export const loadSchedule = async (user: any, patient: Patient | null, processes: Process[])
	: Promise<ModifiedProcess[]> => {

	if (!patient) {
		return [];
	}
	const userID = patient.userID ?? user?.uid;
	if (!userID) {
		return [];
	}

	const schedule = await getSchedule(userID, patient.id);
	const modifiedProcesses: ModifiedProcess[] = schedule.map((serializedProcess: SerializedProcess) => {
		const process = processes.find((p) => p.id === serializedProcess.processID);
		if (!process) {
			return null;
		}
		return {
			process: process,
			modifiers: serializedProcess.modifiers
		}
	});

	return modifiedProcesses;
}

const SchedulerFeature = () => {
	const { translate } = useTranslation();
	const navigate = useNavigate();
	const [patient, setPatient] = useState<Patient | null>(null);
	const [patientSettings, setPatientSettings] = useState<PatientSettingsData | null>(null);
	const [defaultProcesses, setDefaultProcesses] = useState<ModifiedProcess[]>([]);
	const [settingsModalOpen, setSettingsModalOpen] = useState(false);
	const user = useContext(UserContext);
	const { processes } = useContext(ProcessesContext);
	const [processesDirty, setProcessesDirty] = useState(false);


	const handlePatientSelected = async (patient: Patient) => {
		if (!patient) {
			return;
		}
		const userID = patient.userID ?? user?.uid;
		if (!userID) {
			return;
		}

		setPatient(patient);
		setPatientSettings(patient.settings);
		setProcessesDirty(false);

		const schedule = await loadSchedule(user, patient, processes);
		setDefaultProcesses(schedule);

		navigate("./patient");
	}

	async function handleProcessSelected(processes: ModifiedProcess[]) {
		if (!patient || !processesDirty) {
			return;
		}
		const userID = patient.userID ?? user?.uid;
		if (!userID) {
			return;
		}

		const serializedProcess = processes.map((process) => {
			return {
				processID: process.process.id,
				modifiers: process.modifiers
			}
		});

		await submitSchedule(userID, patient.id, serializedProcess);
		setProcessesDirty(false);
	}

	function handleOpenSettingsModal(): void {
		setSettingsModalOpen(true);
	}

	function handleSettingsUpdated(settings: PatientSettingsData): void {
		setPatientSettings(settings);
	}

	function handleCloseSettingsModal(): void {
		if (!patient || !patientSettings) {
			return;
		}
		const userID = patient.userID ?? user?.uid;
		if (!userID) {
			return;
		}

		submitPatientSettings(
			userID,
			patient.id,
			patientSettings);

		setSettingsModalOpen(false);
	}

	return (
		<>
			<Typography variant="h3" className="session-unprintable">{translate("[sessionsscheduler_title]")}</Typography>
			<Routes>
				<Route path="/" element={
					<>
						<Typography variant="body1">
							{translate("[sessionsscheduler_description]")}
						</Typography>
						<Typography variant="h5">
							{translate("[sessionsscheduler_selectpatient]")}
						</Typography>
						<Patients onPatientSelected={handlePatientSelected} />
					</>
				} />
				<Route path="/patient" element={
					<>
						{!!patient &&
							<>
								<Typography variant="h5" gutterBottom>
									{translate("[sessionsscheduler_patientconfig]")}
								</Typography>
								<Typography variant="body1">
									{translate("[sessionsscheduler_patientconfig_description]")}
								</Typography>
								<Box display="flex" justifyContent="left" alignItems="center" marginBottom={4}>
									<Avatar name={patient.initials ?? patient.id.slice(0, 2)} id={patient.id} />
									<Button size="small"
										endIcon={<SettingsIcon fontSize="small" />}
										onClick={handleOpenSettingsModal}>
										{translate("[remotecontrolbar_settings]")}
									</Button>
								</Box>

								<Typography variant="h5" gutterBottom>
									{translate("[sessionsscheduler_processes]")}
								</Typography>
								<Typography variant="body1">
									{translate("[sessionsscheduler_processes_description]")}
								</Typography>
								<Processes
									onProcessesSelected={handleProcessSelected}
									onProcessesUpdated={() => setProcessesDirty(true)}
									patient={patient}
									defaultQueue={defaultProcesses}
									queueOnly
									disabled={!processesDirty}
									startQueueText="[sessionsscheduler_startqueue]"
								/>
							</>
						}
					</>
				} />
			</Routes>


			{!!patientSettings &&
				<AlertDialog
					open={!!settingsModalOpen}
					onCancel={handleCloseSettingsModal}
					title={translate("[page_settings]")}>
					<PatientSettings
						data={patientSettings}
						sendMessage={() => { }}
						onSettingsUpdated={handleSettingsUpdated}
					/>
				</AlertDialog>
			}
		</>
	);
}

export default SchedulerFeature;
