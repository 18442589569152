import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProcessesContext } from "../../contexts/Processes.context";
import { Process } from "../../data/Process";
import { useTranslation } from "../../contexts/TranslationContext";

function extractValue(key: string,
	format: string,
	payload: any,
	translate: (key: string) => string)
	: string {
	switch (format.toLowerCase()) {
		case "bool":
			return key === "true" ? "☑" : "☐";
		case "togglegroup":
			return translate(payload[parseInt(key)]);
		case "presets":
			return translate(payload[parseInt(key)]?.title);
		case "array":
			return `${payload[parseInt(key)]}`;
		default:
			return key;
	}
}

function getModifiersText(modifiers: any,
	process: Process | null,
	translate: (key: string) => string)
	: string {

	if (!process || !modifiers) {
		return "";
	}

	return Object.keys(modifiers).reduce((text, key) => {
		if (key === "training_processID") {
			return text;
		}
		if (!(process?.modifiers)) {
			return text;
		}

		const modifier = process.modifiers.find((modifier) => modifier.id === key);

		if (!modifier) {
			return text;
		}
		if (modifier.format === "fixed") {
			return text;
		}
		if (modifier.format === "multiselect") {
			return text;
		}

		const title = translate(modifier.title);

		if (modifier.format.toLowerCase() === "bool") {
			if (modifiers[key] === "false") {
				return text;
			}
			else {
				return text + `${title}. `;
			}
		}

		const value = extractValue(modifiers[key].toString(), modifier.format, modifier.payload, translate);

		return text + `${title}: ${value}. `;
	}, "");
}

interface ModifiersTextProps {
	modifiers: any,
	processID: string,
}

export function ModifiersText(props: ModifiersTextProps) {
	const { translate } = useTranslation();
	const { processes, setProcesses } = useContext(ProcessesContext);
	const [text, setText] = useState<string>("");

	useEffect(() => {
		const process: Process = processes.find((p: Process) => p.id === props.processID)!;
		const text = getModifiersText(props.modifiers, process, translate);
		setText(() => text);
	}, [processes, props.modifiers, props.processID]);

	return <Typography
		style={{
			overflowWrap: "break-word",
			wordWrap: "break-word",
		}} >
		{text}
	</Typography>;
}
