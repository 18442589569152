export default function Logo(props: any) {
	const { width, height, ...other } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"
			{...other}>
			<path opacity="0.83" d="M108.032 96.8384C160.205 63.9183 187.222 46.8753 227.108 44.3176C275.125 41.2378 310.638 61.1604 340.611 77.9772C359.429 88.5387 377.08 98.7523 382.698 117.3C384.706 123.938 384.819 130.472 390.08 140.259C391.985 143.817 398.341 155.327 411.306 163.714C419.523 169.029 436.349 176.929 452.958 179.695C455.062 180.043 459.123 180.644 462.262 183.81C468.549 190.153 466.132 201.741 466.018 202.228C463.662 212.842 455.714 215.434 457.14 221.455C458.314 226.405 463.879 225.517 466.245 231.233C469.41 238.889 461.201 244.779 464.227 252.6C466.14 257.541 470.01 256.724 473.984 261.769C480.053 269.486 476.775 278.751 476.731 290.87C476.662 311.019 485.618 315.047 484.966 325.209C483.018 355.388 402.08 349.368 351.663 405.595C326.42 433.756 336.211 446.98 311.76 464.71C275.264 491.166 213.335 490.801 177.022 461.187C151.536 440.403 161.075 423.482 130.005 383.228C96.1189 339.337 76.3278 348.507 52.3977 314.899C17.4242 265.789 16.5634 185.611 53.6411 137.771C63.7019 124.808 78.4756 115.491 108.032 96.8384Z" fill="url(#paint0_linear_12_2)" />
			<path opacity="0.65" d="M215.359 69.7497C179.009 82.7825 101.351 117.85 76.793 147.712C53.1565 176.463 41.0948 225.553 54.7132 264.79C63.3834 289.77 69.8534 303.785 88.4286 321.422C119.379 350.825 167.886 351.641 180.479 353.657C192.462 355.577 277.103 366.342 321.15 317.686C358.343 276.598 335.681 225.501 377.823 198.314C399.555 184.291 411.478 194.083 442.584 174.899C458.081 165.35 469.647 144.862 472.752 113.54C475.926 81.4792 448.08 47.5243 423.444 35.2908C404.886 26.0723 369.918 25.299 349.482 29.2784C330.272 33.0231 312.471 42.1722 282.312 51.7904C262.763 58.0288 230.1 64.4583 215.359 69.7497Z" fill="url(#paint1_linear_12_2)" />
			<defs>
				<linearGradient id="paint0_linear_12_2" x1="85.218" y1="372.848" x2="548.898" y2="24.6088" gradientUnits="userSpaceOnUse">
					<stop stopColor="#4A4FFF" />
					<stop offset="1" stopColor="#8BD4F5" stopOpacity="0.71" />
				</linearGradient>
				<linearGradient id="paint1_linear_12_2" x1="264.254" y1="53.3113" x2="254.497" y2="398.234" gradientUnits="userSpaceOnUse">
					<stop offset="0.0608" stopColor="#C93A6E" stopOpacity="0.8" />
					<stop offset="0.0704" stopColor="#C83A70" stopOpacity="0.802" />
					<stop offset="0.3559" stopColor="#A54498" stopOpacity="0.8628" />
					<stop offset="0.6157" stopColor="#8C4AB6" stopOpacity="0.9182" />
					<stop offset="0.839" stopColor="#7C4FC8" stopOpacity="0.9657" />
					<stop offset="1" stopColor="#7750CE" />
				</linearGradient>
			</defs>
		</svg>

	);
}
