import { Typography, Box } from "@mui/material";
import { BirdsData, BirdsCountingMode } from "../BirdsData";
import { useTranslation } from "../../contexts/TranslationContext";

interface AverageBirdsData {
	data: BirdsData
}

function AverageBirdsCard(props: AverageBirdsData) {
	const { translate } = useTranslation();
	const data: BirdsData = props.data;

	const allErrors = data.leftErrors + data.middleErrors + data.rightErrors;
	return (<>

		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />

		<Box><Typography>{translate("[graphs_birds_error_rate_label]")}</Typography></Box>
		<Box><Typography variant="h4">
			{((allErrors / data.totalTries) * 100).toFixed(1)}%
		</Typography></Box>
		<br />

		{(data.countingMode === BirdsCountingMode.Together) &&
			<Box style={{ justifyContent: "space-between", display: "flex" }}>
				<Typography><strong>{translate("[graphs_birds_errors_all_trees]")}</strong> {allErrors}</Typography>
			</Box>
		}

		{(data.countingMode === BirdsCountingMode.LeftAndRight) &&
			<Box style={{ justifyContent: "space-between", display: "flex" }}>
				<Typography><strong>{translate("[graphs_birds_errors_left_tree]")}</strong> {data.leftErrors}</Typography>
				<Typography><strong>{translate("[graphs_birds_errors_right_tree]")}</strong> {data.rightErrors}</Typography>
			</Box>
		}

		{(data.countingMode === BirdsCountingMode.PerTree) &&
			<Box style={{ justifyContent: "space-between", display: "flex" }}>
				<Typography><strong>{translate("[graphs_birds_errors_left_tree]")}</strong> {data.leftErrors}</Typography>
				<Typography><strong>{translate("[graphs_birds_errors_middle_tree]")}</strong> {data.middleErrors}</Typography>
				<Typography><strong>{translate("[graphs_birds_errors_right_tree]")}</strong> {data.rightErrors}</Typography>
			</Box>
		}

	</>);
}

export default AverageBirdsCard;