import { Box, Grid, Typography } from '@mui/material';
import { CollaboratorData, collaboratorsData, collaboratorsTitle } from './collaboratorsData';
import { useTranslation } from '../../contexts/TranslationContext';


function Collaborators() {
	const { translate } = useTranslation();
	return (
		<Box my={10} >
			<Typography variant="h2" gutterBottom>
				{translate(collaboratorsTitle)}
			</Typography>
			<Grid container justifyContent="center">
				{collaboratorsData.map((collaborator: CollaboratorData, index: number) => {
					return (
						<Grid item key={index} xs={6} md={2} gap={2} style={{display: "flex", alignItems: "center", justifyContent: "center" }}>
							<img src={collaborator.image} alt="" width={100} />
						</Grid>)
				})
				}
			</Grid>
		</Box>);
}

export default Collaborators;
