import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from "recharts";
import { useTheme } from '@mui/material/styles';
import { DeliveryStatus, GrillData } from "../GrillData";
import { Typography } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";

interface GrillErrorsGraphData {
	data: GrillData
}

function GrillErrorsGraph(props: GrillErrorsGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;

	const countDiscarded = props.data.deliveries.filter(d => (d.status & DeliveryStatus.Discarded) !== 0).length;
	const countRaw = props.data.deliveries.filter(d => (d.status & DeliveryStatus.Raw) !== 0 && (d.status & DeliveryStatus.TimedOut) === 0).length;
	const countBurnt = props.data.deliveries.filter(d => (d.status & DeliveryStatus.Burnt) !== 0).length;
	const countIncorrectOrder = props.data.deliveries.filter(d => (d.status & DeliveryStatus.IncorrectOrder) !== 0).length;
	const countIncorrectRecipe = props.data.deliveries.filter(d => (d.status & DeliveryStatus.IncorrectRecipe) !== 0).length;
	const countIncorrectTray = props.data.deliveries.filter(d => (d.status & DeliveryStatus.IncorrectTray) !== 0).length;
	const countAmmended = props.data.deliveries.filter(d => (d.status & DeliveryStatus.Ammended) !== 0).length;
	
	
	const data =
		[
			{ count: countDiscarded, title: translate("[graphs_grill_status_discarded]") },
			{ count: countIncorrectRecipe, title: translate("[graphs_grill_status_incorrect_recipe]") },
			{ count: countRaw, title: translate("[graphs_grill_status_raw]") },
			{ count: countBurnt, title: translate("[graphs_grill_status_burnt]") },
			{ count: countIncorrectOrder, title: translate("[graphs_grill_status_incorrect_order]") },
			{ count: countIncorrectTray, title: translate("[graphs_grill_status_incorrect_tray]") },
			{ count: countAmmended, title: translate("[graphs_grill_status_re_entregado]") }
		];

	return (
		<>
			<Typography><strong>{translate("[graphs_grill_errors]")}</strong></Typography>
			<ResponsiveContainer height={300}>
				<RadarChart cx="50%" cy="50%" outerRadius={50} data={data}>
					<PolarGrid />
					<PolarAngleAxis dataKey="title" />
					<PolarRadiusAxis allowDecimals={false} domain={[0, (dataMax: number) => (dataMax.toFixed(2))]} />
					<Radar dataKey="count" fill={colors.error.light} fillOpacity={0.8} strokeWidth={2} stroke={colors.error.main}/>
				</RadarChart>
			</ResponsiveContainer>
		</>);

}

export default GrillErrorsGraph;