import { Box, Button, Link, Paper, TextField, Typography } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { sendEmail } from '../../adapters/Firebase';
import { UserContext } from '../../contexts/User.context';
import { useTranslation } from '../../contexts/TranslationContext';

const email = "info@recognition.es";

function ContactForm() {
	const { translate } = useTranslation();
	const [sending, setSending] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement>(null);
	const user = useContext(UserContext);

	const onSubmit = async (event: any) => {
		setSending(() => true);
		event.preventDefault();
		const { email, message } = event.target.elements;

		const result = await sendEmail("[Contacto]", email.value, message.value);

		setSending(() => false);

		if (result && formRef.current) {
			formRef.current.reset();
		}
	};

	return (
		<Box
			component="form"
			onSubmit={onSubmit}
			ref={formRef}
			sx={{
				'& .MuiTextField-root': { m: 1, width: '100%' },
				'& .MuiButtonBase-root': { m: 1 }
			}}
		>
			<Typography variant="h3" gutterBottom>
				{translate("[contact_title]")}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{translate("[contact_description]")}
			</Typography>

			<Paper elevation={3} sx={{ p: 2, mb: 2 }}>
			<TextField
				name="email"
				label={translate("[contact_email]")}
				variant="outlined"
				type="email"
				required
				autoComplete="email"
				defaultValue={user?.email}
			/>
			<TextField
				name="message"
				label={translate("[contact_message]")}
				variant="outlined"
				required
				multiline
				rows={4}
			/>
			<Button type="submit" variant="contained" color="primary" disabled={sending} >
				{translate("[contact_submit]")}
			</Button>

			<Typography variant="caption" color="textSecondary">
				{translate("[contact_send_email]")}
				<Link href={`mailto:info@recognition.es?subject=${translate("[contact_subject]")}`}>
					{email}
				</Link>.
			</Typography>
			</Paper>
		</Box>
	);
}

export default ContactForm;
