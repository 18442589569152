
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

interface WaveSectionProps {
	children: React.ReactNode;
	marginTop?: number;
	marginBottom?: number;
	isTop?: boolean;
}
const PATH_STRING_FOR_TOP = `M 0,114.85479
            c 25.467592,-0.5722 51.563065,-1.04103 78.105469,-1.40323 26.542881,-0.3621 53.471011,-0.61673 80.597651,-0.76214 27.12644,-0.14557 54.38867,-0.18158 81.59766,-0.10778 27.20911,0.074 54.30168,0.25759 81.08984,0.54958 28.99993,0.31963 57.54055,0.76607 86.06055,1.21933 28.52,0.45326 57.01954,0.91334 85.93945,1.26082 20.59744,0.24764 41.4072,0.43697 62.32227,0.56583 20.91507,0.12885 41.93564,0.1971 62.95117,0.20229 21.01553,0.005 42.02697,-0.0524 62.92578,-0.17578 20.89882,-0.12338 41.68507,-0.31241 62.25,-0.56925 19.98494,-0.24898 40.15366,-0.58762 60.40039,-0.97469 20.24674,-0.38707 40.57082,-0.8225 60.86524,-1.26552 20.29441,-0.44303 40.55952,-0.89359 60.6875,-1.31043 20.12798,-0.41684 40.11975,-0.80004 59.86719,-1.10856 17.51244,-0.27372 35.42754,-0.50722 53.50784,-0.68216 18.0802,-0.17494 36.3258,-0.29131 54.5019,-0.3306 18.1763,-0.0393 36.2817,-0.002 54.0821,0.1313 17.8003,0.13303 35.2955,0.36154 52.248,0.70354
            L 1204.4593,11.054964 -3.4570313,10.224211`;

const PATH_STRING_FOR_BOTTOM = `m -3.4570313,10.224211
c 36.7472993,0.784125 73.9196723,1.322382 111.2988313,1.611611 37.37884,0.289205 74.89106,0.328818 112.3164,0.118607 37.42598,-0.210146 74.69164,-0.669712 111.57813,-1.376 43.36849,-0.8592103 86.04844,-2.0585286 128.69922,-3.2769159 42.65077,-1.2183873 85.27263,-2.4558505 128.52148,-3.3899129 30.80285,-0.6657087 61.92511,-1.1746994 93.20313,-1.521053 31.27801,-0.3463537 62.71232,-0.5302645 94.14062,-0.5441492 31.42831,-0.013885 62.8498,0.1419283 94.10352,0.4736262 31.25371,0.3316977 62.3395,0.8394743 93.09375,1.5298684 20.86556,0.4672671 41.86215,1.0502881 62.93555,1.7125873 21.0734,0.6622988 42.2237,1.4041955 63.4004,2.187816 42.3533,1.5672409 72.4867,1.6944027 114.6253,3.3046681
M 0,114.85479
c 25.467592,-0.5722 51.563065,-1.04103 78.105469,-1.40323 26.542881,-0.3621 53.471011,-0.61673 80.597651,-0.76214 27.12644,-0.14557 54.38867,-0.18158 81.59766,-0.10778 27.20911,0.074 54.30168,0.25759 81.08984,0.54958 28.99993,0.31963 57.54055,0.76607 86.06055,1.21933 28.52,0.45326 57.01954,0.91334 85.93945,1.26082 20.59744,0.24764 41.4072,0.43697 62.32227,0.56583 20.91507,0.12885 41.93564,0.1971 62.95117,0.20229 21.01553,0.005 42.02697,-0.0524 62.92578,-0.17578 20.89882,-0.12338 41.68507,-0.31241 62.25,-0.56925 19.98494,-0.24898 40.15366,-0.58762 60.40039,-0.97469 20.24674,-0.38707 40.57082,-0.8225 60.86524,-1.26552 20.29441,-0.44303 40.55952,-0.89359 60.6875,-1.31043 20.12798,-0.41684 40.11975,-0.80004 59.86719,-1.10856 17.51244,-0.27372 35.42754,-0.50722 53.50784,-0.68216 18.0802,-0.17494 36.3258,-0.29131 54.5019,-0.3306 18.1763,-0.0393 36.2817,-0.002 54.0821,0.1313 17.8003,0.13303 35.2955,0.36154 52.248,0.70354
L 1204.4593,11.054964 -3.4570313,10.224211`;

const sideBarSize = 280;
const breakSize = "lg";

function WaveSection(props: WaveSectionProps) {

	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up(breakSize));
	const fromColor = theme.palette.primary.light;
	const toColor = theme.palette.primary.main;

	const svg = `<svg width="100%" height="100%" id="svg" viewBox="0 0 1200 120" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="10%" stop-color="${toColor}"></stop>
        <stop offset="60%" stop-color="${fromColor}"></stop>
      </linearGradient>
    </defs>
    <path d="${props.isTop ? PATH_STRING_FOR_TOP : PATH_STRING_FOR_BOTTOM}"
      transform="scale(-1, 1) translate(-1200, 0)" 
      fill="url(#gradient)" />
    </svg>`;

	const svgUrl = `url("data:image/svg+xml,${encodeURIComponent(svg)}")`;

	const width = (isBigScreen ? sideBarSize  + 20  : 0) ;

	return (
			<Box
				sx={{
					backgroundImage: svgUrl,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					position: "relative",
					width: `calc(100vw - ${width}px)`,
					left: `calc(50% + ${width / 2}px)`,
					right: "50%",
					marginLeft: "-50vw",
					marginRight: "-50vw",
					marginTop: `-${props.marginTop}px`,
					marginBottom: `-${props.marginBottom}px`,
				}}
		>
			<Container >
				<Box
					paddingTop={`${props.marginTop}px`}
					paddingBottom={`${props.marginBottom}px`}
					marginBottom={`${props.marginBottom}px`}
					color={theme.palette.primary.contrastText} >
					{props.children}
				</Box>
			</Container>
			</Box>
	);
}

export default WaveSection;