import lucaImage from "../../media/landing/LUCA.png";
import anaImage from "../../media/landing/ANA.png";
import joseImage from "../../media/landing/JOSE.png";

export interface TeamMemberData {
	image: string,
	name: string,
	title: string,
	description: string,
	twitterUrl: string,
	linkedInUrl: string,
}

export const teamTitle = "[team_title]";

export const teamData: TeamMemberData[] = [
	{
		"name": "[team_jose_name]",
		"image": joseImage,
		"title": "[team_jose_position]",
		"description": "[team_jose_description]",
		"twitterUrl": "https://twitter.com/JM_Torralba_",
		"linkedInUrl": "https://www.linkedin.com/in/jose-maria-torralba-mu%C3%B1oz-938b5444/",
	},
	{
		"name": "[team_ana_name]",
		"image": anaImage,
		"title": "[team_ana_position]",
		"description": "[team_ana_description]",
		"twitterUrl": "https://twitter.com/Anaibga",
		"linkedInUrl": "https://www.linkedin.com/in/ana-ib%C3%A1%C3%B1ez-b3ba0926b/",
	},
	{
		"name": "[team_luca_name]",
		"image": lucaImage,
		"title": "[team_luca_position]",
		"description": "[team_luca_description]",
		"twitterUrl": "https://twitter.com/LucaMefisto",
		"linkedInUrl": "https://www.linkedin.com/in/lucamefisto/",
	},
];