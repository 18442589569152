import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Legend, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import { FruitDeliveryStatus, FruitsData, FruitsTrialData } from "../FruitsData";
import { renderBlackLegend } from "./legend";
import { useTranslation } from "../../contexts/TranslationContext";


interface FruitsDistributionGraphData {
	data: FruitsData
}

function FruitsDistributionGraph(props: FruitsDistributionGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;

	const pickedFruits = props.data.tries
		.filter((entry: FruitsTrialData) => entry.deliveryStatus !== FruitDeliveryStatus.NotDelivered);

	const minZ = pickedFruits.length > 0 ? Math.min(...pickedFruits
		.map((entry: FruitsTrialData) => entry.reactionTime)) : 1;
	const maxZ = pickedFruits.length > 0 ? Math.max(...pickedFruits
		.map((entry: FruitsTrialData) => entry.reactionTime)) : 1;

	const coordinates = props.data.tries.map((entry: FruitsTrialData) => {
		return {
			x: entry.coordinates.x,
			y: entry.coordinates.y,
			reactionTime: entry.deliveryStatus === FruitDeliveryStatus.NotDelivered ? minZ : entry.reactionTime,
			isCorrect: !entry.isForbidden && entry.deliveryStatus === FruitDeliveryStatus.Binned,
			isCorrectDiscarded: !entry.isForbidden && entry.deliveryStatus === FruitDeliveryStatus.Discarded,
			isError: entry.isForbidden && entry.deliveryStatus === FruitDeliveryStatus.Binned,
			isErrorDiscarded: entry.isForbidden && entry.deliveryStatus === FruitDeliveryStatus.Discarded,
			isMissing: !entry.isForbidden && entry.deliveryStatus === FruitDeliveryStatus.NotDelivered,
		}
	});

	return (<>
		<Typography><strong>{translate("[graphs_distribution_attempts]")}</strong></Typography>
		<ResponsiveContainer height={350}>
			<ScatterChart
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
				<XAxis hide={true} domain={[-90, 90]} type="number" dataKey="x" name="horizontal" unit={translate("[units_degrees]")} />
				<YAxis hide={true} domain={[40, 120]} type="number" dataKey="y" name="vertical" unit={translate("[units_degrees]")} />
				<ZAxis range={[50, 300]} domain={[minZ, maxZ]}
					type="number" dataKey="reactionTime" 
					name={translate("[value_reactiontime]")}
					unit={translate("[units_seconds]")}
					scale="linear" />
				<ReferenceLine x="-35" />
				<ReferenceLine x="35" />
				<ReferenceLine y="90" />
				<Legend formatter={renderBlackLegend} />
				<Tooltip />
				<Scatter name={translate("[graphs_fruits_distribution_hits]")}
					data={coordinates.filter((entry: any) => entry.isCorrect)}
					fill={colors.primary.dark} />
				<Scatter name={translate("[graphs_fruits_distribution_discarded]")}
					data={coordinates.filter((entry: any) => entry.isCorrectDiscarded)}
					fill={colors.primary.light} />
				<Scatter name={translate("[graphs_fruits_distribution_errors]")}
					data={coordinates.filter((entry: any) => entry.isError)}
					fill={colors.error.dark}
					shape="wye"
					legendType="wye" />
				<Scatter name={translate("[graphs_fruits_distribution_errors_discarded]")}
					data={coordinates.filter((entry: any) => entry.isErrorDiscarded)}
					fill={colors.error.light}
					shape="wye"
					legendType="wye" />
				<Scatter name={translate("[graphs_fruits_distribution_misses]")}
					data={coordinates.filter((entry: any) => entry.isMissing)}
					fill={colors.secondary.main}
					shape="square"
					legendType="square" />
			</ScatterChart>
		</ResponsiveContainer>
	</>);
}

export default FruitsDistributionGraph;