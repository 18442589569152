import { memo, useContext, useEffect, useState } from "react";

import { CircularProgress, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import AlertDialog from "../../components/atoms/Popup";
import { UserContext } from "../../contexts/User.context";
import SentCentreInvite from "./SentCentreInvite";
import { cancelTherapistInvitation, inviteTherapistToCentre } from "../../adapters/Firebase/Functions";
import { subscribeToAllCentreInvites } from "../../adapters/Firebase";
import { CentreInvite } from "./invite";
import { useTranslation } from "../../contexts/TranslationContext";

enum AddState {
	None,
	Adding,
	Registering,
	Deleting,
	Failed
}

interface AddCodeState {
	state: AddState;
	message?: string;
}

export const SentCentreInvites = memo(function () {
	const { translate } = useTranslation();
	const [addState, setAddState] = useState<AddCodeState>({ state: AddState.None });
	const [invitations, setInvitations] = useState<CentreInvite[]>([]);
	const [deletableInvite, setDeletableInvite] = useState<CentreInvite | null>(null);
	const [email, setEmail] = useState("");

	const user = useContext(UserContext)!;

	const loadingAdd = addState.state === AddState.Adding
		|| addState.state === AddState.Registering
		|| addState.state === AddState.Deleting;
	const handleDeleteTherapist = (invitation: CentreInvite) => {
		setDeletableInvite(invitation);
	}

	const addTherapist = () => {
		setEmail(() => "");
		setAddState(() => ({ state: AddState.Adding }));
	}

	const registerTherapist = async (therapistEmail: string) => {
		const result = await inviteTherapistToCentre(therapistEmail);
		if (result.result === 200) {
			setAddState(() => ({ state: AddState.None }));
		}
		else {
			setAddState(() => ({ message: result.message, state: AddState.Failed }));
		}
	}

	const confirmDeleteTherapist = async () => {
		const invite = deletableInvite as CentreInvite;
		cancelDeleteTherapist();
		setAddState(() => ({ state: AddState.Deleting }));
		await cancelTherapistInvitation(invite.therapistID);
		setAddState(() => ({ state: AddState.None }));
	}

	const cancelDeleteTherapist = () => {
		setDeletableInvite(null);
	}

	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const cancelAddTherapist = () => {
		setAddState(() => ({ state: AddState.None }));
	}

	useEffect(() => {
		const unsubscribe = subscribeToAllCentreInvites(user.uid, "invitesSent", setInvitations);
		return unsubscribe;
	}, [user.uid]);

	return (
		<>
			<Typography variant="h5">{translate("[therapists_title]")}</Typography>
			<Typography variant="body2">{translate("[therapists_description]")}</Typography>
			<br />
			<div style={{ textAlign: "right" }}>
				{loadingAdd ? (
					<>
						<CircularProgress size={36} />
					</>) : (
					<Button
						disabled={loadingAdd}
						onClick={addTherapist}
					>
						{translate("[therapists_addtherapist]")}
					</Button>
				)
				}
			</div>
			<br />
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<List>
					{Array.from(invitations).map((invitation: CentreInvite, index: number) => (
						<SentCentreInvite
							invitation={invitation}
							key={index}
							onDelete={() => handleDeleteTherapist(invitation)} />
					))}
				</List>
			</Paper>

			< AlertDialog
				open={!!deletableInvite}
				title={`${translate("[therapists_remove]")} ${deletableInvite?.therapistEmail}`}
				okTitle={translate("[therapists_accept]")}
				cancelTitle={translate("[therapists_cancel]")}
				onOk={confirmDeleteTherapist}
				onCancel={cancelDeleteTherapist}>
				{translate("[therapists_confirmdelete]")}
			</AlertDialog>

			< AlertDialog
				open={addState.state === AddState.Failed}
				title={translate("[therapists_cantaddtherapist]")}
				cancelTitle={translate("[therapists_cancel]")}
				onCancel={cancelAddTherapist}>
				{translate(addState.message!)}
			</AlertDialog>

			< AlertDialog
				open={addState.state === AddState.Adding}
				title={translate("[therapists_addtherapist]")}
				okTitle={translate("[therapists_add]")}
				cancelTitle={translate("[therapists_cancel]")}
				onOk={() => registerTherapist(email)}
				onCancel={cancelAddTherapist}>
				<TextField
					autoFocus
					inputMode="email"
					margin="dense"
					label={translate("[therapists_email]")}
					type="text"
					value={email}
					onChange={handleEmailChange}
					fullWidth
				/>
			</AlertDialog>
		</>
	);

});

export default SentCentreInvites;
