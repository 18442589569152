import { Handedness, HandednessData } from './DataDefinitions';


export interface FruitCoordinates {
	x: number;
	y: number;
}



export interface FruitsData {
	totalTime: number;
	errors: number;
	handedness: HandednessData;
	tries: FruitsTrialData[];
}


export enum FruitDeliveryStatus {
	Binned,
	Discarded,
	NotDelivered
}

export interface FruitsTrialData {
	pickTime: number,
	releaseTime: number,
	reactionTime: number,
	handedness: Handedness,
	isForbidden: boolean,
	deliveryStatus: FruitDeliveryStatus,
	coordinates: FruitCoordinates,
}

function extractCoordinates(coordinates: string): FruitCoordinates {
	const attemptCoords = coordinates.substring(1, coordinates.length - 1);
	const [coordX, coordY] = attemptCoords.split(',');
	return { x: parseFloat(coordX), y: parseFloat(coordY) };
}

function extractFruitsData(sessionSelected: any): FruitsData {

	const blocks = sessionSelected.blocks;
	const trials = blocks.deliveredFruits.filter((entry: any) => entry.counts !== null);

	const trialsData = trials.map((entry: any, index: number): FruitsTrialData => {

		const pickTime = entry.pickTime;
		let previousReleaseTime = 0;

		let i = index - 1;
		while (i > 0 && trials[i - 1].releaseTime > pickTime) {
			i--;
		}
		if (i > 0) {
			previousReleaseTime = trials[i - 1].releaseTime;
		}

		return (
			{
				pickTime: pickTime,
				releaseTime: entry.releaseTime,
				reactionTime: pickTime - previousReleaseTime,
				handedness: entry.handedness,
				isForbidden: entry.isForbidden,
				deliveryStatus: entry.deliveryStatus,
				coordinates: extractCoordinates(entry.coordinates),
			});
	}
	);

	const composedData: FruitsData = trialsData.reduce(
		(acum: FruitsData, trial: FruitsTrialData, index: number) => {
			const isError = trial.isForbidden && trial.deliveryStatus === FruitDeliveryStatus.Binned;
			const isLeft = trial.handedness === Handedness.Left;
			const isRight = trial.handedness === Handedness.Right;
			return {

				totalTime: Math.max(acum.totalTime, trial.releaseTime),
				errors: acum.errors + (isError ? 1 : 0),
				handedness: {
					left: acum.handedness.left + (isLeft ? 1 : 0),
					right: acum.handedness.right + (isRight ? 1 : 0),
					leftErrors: acum.handedness.leftErrors + (isLeft && isError ? 1 : 0),
					rightErrors: acum.handedness.rightErrors + (isRight && isError ? 1 : 0),
				},
				tries: [...acum.tries, trial],
			}
		},
		{
			totalTime: 0,
			errors: 0,
			handedness: {
				right: 0,
				left: 0,
				leftErrors: 0,
				rightErrors: 0,
			},
			tries: [],
		});


	return composedData;
}


export default extractFruitsData;