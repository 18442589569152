import { List, Paper, Typography } from "@mui/material";
import { CentreInvite } from "./invite";
import ReceivedCentreInvite from "./ReceivedCentreInvite";
import { useState } from "react";
import HandshakeIcon from '@mui/icons-material/Handshake';
import AlertDialog from "../../components/atoms/Popup";
import { acceptCentreInvitation, rejectCentreInvitation } from "../../adapters/Firebase";
import { useTranslation } from "../../contexts/TranslationContext";

interface ReceivedInvitesProps {
    invitations: CentreInvite[]
}

function ReceivedCentreInvites(props: ReceivedInvitesProps) {
	const { translate } = useTranslation();
    const [invitationError, setInvitationError] = useState<string | null>(null);

    async function handleAcceptInvitation(invitation: CentreInvite) {
        const result = await acceptCentreInvitation(invitation.centreID);
        if (result.code !== 200) {
            setInvitationError(() => result.message);
        }
    }

    async function handleRejectInvitation(invitation: CentreInvite) {
        const result = await rejectCentreInvitation(invitation.centreID);
        if (result.code !== 200) {
            setInvitationError(() => result.message);
        }
    }

    return (
        <>
            <Typography variant="h5" gutterBottom noWrap>
                {translate("[center_invitations]")}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {translate("[center_invitations_description]")}
            </Typography>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <List>
                    {
                        Array.from(props.invitations).map((invitation: CentreInvite, index: number) => (
                            <ReceivedCentreInvite
                                invitation={invitation}
                                key={index}
                                icon={<HandshakeIcon color="secondary" />}
                                onAccept={() => handleAcceptInvitation(invitation)}
                                onCancel={() => handleRejectInvitation(invitation)} />
                        ))
                    }
                </List>
            </Paper>


            < AlertDialog
                open={!!invitationError}
                title={translate("[center_invitationerror_title]")}
                cancelTitle={translate("[center_accept]")}
                onCancel={() => setInvitationError(() => null)} >
                {translate(invitationError!)}
            </AlertDialog>

        </>
    );
}

export default ReceivedCentreInvites;