export const faqTutorials = [
	{
		"title": "[faq_1_title]",
		"content": "[faq_1_content]",
		"video": "https://www.youtube.com/embed/804pkyvvDl8"
	},

	{
		"title": "[faq_2_title]",
		"content": "[faq_2_content]",
		"video": "https://www.youtube.com/embed/vVgopYFstG0"
	}
];

export const faQuestions = [
	{
		"title": "[faq_3_title]",
		"content": "[faq_3_content]"
	},
];

export const notFound = "[faq_notfound]";