import { ReferenceLine, ResponsiveContainer, Tooltip, XAxis, AreaChart, Area } from "recharts";
import { useTheme } from '@mui/material/styles';
import { ViewDirectionData } from "../DataDefinitions";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";

interface ViewDirectionGraphData {
	data: ViewDirectionData
}

export function normalizeViewOrientationSamples(samples: number[]) {
	const degreesPerSample = 360 / samples.length;
	const total = samples.reduce((a, b) => a + b, 0);


	let result = samples.map(
		(value, index) => {

			let degrees: number = index * degreesPerSample;
			if (degrees > 180) {
				degrees -= 360;
			}

			return {
				degrees: degrees,
				value: (value / total) * 100
			}
		});

	result.sort((a, b) => {
		return a.degrees - b.degrees;
	});

	const quarterLenght = result.length / 4;
	result = result.slice(quarterLenght, result.length - quarterLenght);
	return result;
}

function calculateViewOrientationLaterality(samples: number[]) {
	let leftCount = 0;
	let rightCount = 0;
	const degreesPerSample = 360 / samples.length;
	for (let i = 0; i < samples.length; i++) {
		let degrees: number = i * degreesPerSample;
		if (degrees > 180) {
			degrees -= 360;
		}
		if (degrees >= 0) {
			rightCount += samples[i];
		}
		else {
			leftCount += samples[i];
		}

	}
	return (rightCount / (leftCount + rightCount)) * 100;
}

function ViewDirectionGraph(props: ViewDirectionGraphData) {
	const { translate } = useTranslation();
	const colors = useTheme().palette;
	const viewDirectionRatio = calculateViewOrientationLaterality(props.data.samples);

	return (<>
		<Typography><strong>{translate("[graphs_viewdirection_title]")}</strong></Typography>
		<ResponsiveContainer height={300}>
			<AreaChart data={normalizeViewOrientationSamples(props.data.samples)}>
				<XAxis dataKey="degrees" />
				<Tooltip />
				<ReferenceLine x="0"></ReferenceLine>
				<Area type="basis" dataKey={(data) => data.value} stroke={colors.primary.main} fill={colors.primary.light} />
			</AreaChart>
		</ResponsiveContainer>
		<Box style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
			<Box style={{ textAlign: "center", flexDirection: "column" }}>
				<strong>{translate("[graphs_viewdirection_left]")}</strong><p>
					{(100 - viewDirectionRatio).toFixed(2)}%
				</p></Box>
			<Box style={{ textAlign: "center", flexDirection: "column" }}>
				<strong>{translate("[graphs_viewdirection_right]")}</strong><p>
					{(viewDirectionRatio).toFixed(2)}%
				</p></Box>
		</Box>
	</>
	);
}

export default ViewDirectionGraph;