import { LateralFeedbackData, ViewDirectionData } from './DataDefinitions';

export function combineViewOrientationSamples(samplesA: number[], samplesB: number[]) {
	let result = new Array(samplesB.length);
	for (let i = 0; i < samplesB.length; i++) {
		result[i] = (samplesA.length > i ? samplesA[i] : 0) + samplesB[i];
	}
	return result;
};


export enum BirdsCountingMode {
	Together = 0,
	LeftAndRight = 1,
	PerTree = 2,
}


export interface BirdsData {
	leftErrors: number;
	middleErrors: number;
	rightErrors: number;
	totalTries: number;
	countingMode: BirdsCountingMode;
	viewOrientation: ViewDirectionData,
	lateralFeedback: LateralFeedbackData,
}

export interface BirdsTrialData {
	targets: number[],
	counts: number[],
	countingMode: BirdsCountingMode,
	viewOrientation: ViewDirectionData,
	lateralFeedback: LateralFeedbackData,
}
function extractBirdsData(sessionSelected: any): BirdsData {

	const blocks = sessionSelected.blocks;
	const trials = blocks.trials.filter((entry: any) => entry.counts !== null);

	const computeTogetherErrors = (data: BirdsTrialData): number => {
		const countSum = data.counts.reduce((a, b) => a + b, 0);
		const targetsSum = data.targets.reduce((a, b) => a + b, 0);
		return Math.abs(countSum - targetsSum);
	}

	const trialsData = trials.map((entry: any): BirdsTrialData => ({
		targets: entry.targets,
		counts: entry.counts,
		countingMode: !entry.countingMode ? !!entry.perTree ? BirdsCountingMode.PerTree : BirdsCountingMode.Together : entry.countingMode,
		viewOrientation: entry.viewOrientation,
		lateralFeedback: entry.lateralFeedback ?? { leftFeedbackCount: 0, rightFeedbackCount: 0 }
,
	}
	));

	const composedData: BirdsData = trialsData.reduce(
		(acum: BirdsData, trial: BirdsTrialData, index: number) => {
			return {
				leftErrors: acum.leftErrors + (trial.countingMode !== BirdsCountingMode.Together ? Math.abs(trial.counts[0] - trial.targets[0]) : 0),
				middleErrors: acum.middleErrors + (trial.countingMode === BirdsCountingMode.PerTree ? Math.abs(trial.counts[1] - trial.targets[1]) :
					trial.countingMode === BirdsCountingMode.Together ? computeTogetherErrors(trial) : 0),
				rightErrors: acum.rightErrors + (trial.countingMode !== BirdsCountingMode.Together ? Math.abs(trial.counts[2] - trial.targets[2]) : 0),

				totalTries: acum.totalTries + trial.targets[0] + trial.targets[1] + trial.targets[2],
				countingMode: trial.countingMode,
				viewOrientation: {
					samples: combineViewOrientationSamples(acum.viewOrientation.samples, trial.viewOrientation.samples)
				},
				lateralFeedback: {
					leftFeedbackCount: acum.lateralFeedback.leftFeedbackCount + trial.lateralFeedback.leftFeedbackCount,
					rightFeedbackCount: acum.lateralFeedback.rightFeedbackCount + trial.lateralFeedback.rightFeedbackCount,
				}

			}
		},
		{
			leftErrors: 0,
			middleErrors: 0,
			rightErrors: 0,
			totalTries: 0,
			countingMode: BirdsCountingMode.Together,
			viewOrientation: { samples: [] },
			lateralFeedback: { leftFeedbackCount: 0, rightFeedbackCount: 0 },
		});


	return composedData;
}


export default extractBirdsData;