import { hsl } from 'd3-color'
import Typography from '@mui/material/Typography';

export interface AvatarProps {
	id: string
	name: string;
}

export default function Avatar(props: AvatarProps) {

	return <AvatarCustom id={props.id}>
		<Typography style={{ margin: "auto", color: "inherit" }}>
			{props.name.slice(0, 3).toUpperCase()}
		</Typography>
	</AvatarCustom>
}

export function AvatarCustom({ id, children }: { id: string, children: any }) {

	function randomColorFromID(id: string, saturation: number, brightness: number): string {
		let hash = 0;
		for (let i = 0; i < id.length; i++) {
			hash = id.charCodeAt(i) + ((hash << 5) - hash);
		}

		return hsl(hash % 360, saturation, brightness).hex();
	}

	const background = randomColorFromID(id, 0.55, 0.9);
	const foreground = randomColorFromID(id, 1, 0.4);

	return (
		<div style={{
			display: "flex", height: "50px", aspectRatio: "1 / 1", borderRadius: "50%",
			backgroundColor: background,
			color: foreground
		}} >
			{children}
		</div>
	);
}