import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AvatarCustom } from '../users/Avatar';
import { ReactComponent as TherapistIcon } from '../../media/doctor.svg';
import SvgIcon from '@mui/icons-material/Delete';
import { CentreInvite, inviteStatusToText } from './invite';
import { useTranslation } from '../../contexts/TranslationContext';

interface SentInvite {
	invitation: CentreInvite;
	onDelete: any;
}

export default function SentCentreInvite(props: SentInvite) {
	const { translate } = useTranslation();
	const deleteButton =
		<IconButton edge="end"
			aria-label="delete"
			color="secondary"
			onClick={props.onDelete}>
			<DeleteIcon />
		</IconButton>

	return (
		<ListItem
			divider
			secondaryAction={deleteButton}>
			<ListItemButton>
				<ListItemAvatar>
					<AvatarCustom id={props.invitation.therapistID}>
						<SvgIcon component={TherapistIcon}
							color="inherit"
							viewBox="0 0 64 64"
							style={{ margin: "auto", color: "inherit" }} />
					</AvatarCustom>
				</ListItemAvatar>
				<ListItemText primary={props.invitation.therapistEmail} 
				secondary={translate(`[center_invitation_status] ${inviteStatusToText(props.invitation.status)}`)}
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}} />
			</ListItemButton>
		</ListItem>
	)
}
