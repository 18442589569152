import { SvgIcon } from '@mui/material';
import { ReactComponent as HideMenu } from '../../media/hidemenu.svg';
import { ReactComponent as LeftAlarm } from '../../media/leftalarm.svg';
import { ReactComponent as Recentre } from '../../media/recentre.svg';
import { ReactComponent as RightAlarm } from '../../media/rightalarm.svg';
import { ReactComponent as Search } from '../../media/search.svg';
import { ReactComponent as Clean } from '../../media/clean.svg';
import { ReactComponent as AddTime } from '../../media/addtime.svg';
import { ReactComponent as SkipTime } from '../../media/skiptime.svg';
import { ReactComponent as Interference } from '../../media/interference.svg';
import { ReactComponent as Dishwasher } from '../../media/dishwasher.svg';

const icons: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    hidemenu: HideMenu,
    leftalarm: LeftAlarm,
    recentre: Recentre,
    rightalarm: RightAlarm,
    search: Search,
    clean: Clean,
    addtime: AddTime,
    skiptime: SkipTime,
    interference: Interference,
    dishwasher: Dishwasher,
};

interface IconData {
    name: string;
}

function Icon(props: IconData) {
    const icon: any = icons[props.name];
    return (<SvgIcon component={icon}
        viewBox="0 0 64 64" />
    );
};

export default Icon;