import { Box, Button, Typography } from "@mui/material";
import { roleToText, PremiumUser } from "../login/PremiumUser";
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from "../../contexts/TranslationContext";

interface UserMenuProps {
	user: PremiumUser,
}

function UserMenu(props: UserMenuProps) {
	const { translate } = useTranslation();
	const navigate = useNavigate();

	function openUserPage() {
		navigate("user");
	}

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography
				variant="subtitle1"
				style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
			>
				<strong>{translate(roleToText(props.user.role))}</strong>
			</Typography>
			<Typography
				variant="body2"
				style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
			>
				{props.user.email}
			</Typography>


			<Box gap={1} marginTop={1} display="flex" flexDirection="column" alignItems="center">
				<Button style={{ fontWeight: "bold" }}
					startIcon={<PersonIcon />}
					variant="outlined"
					onClick={openUserPage}>
					{translate("[userpage_button]")}
				</Button>
			</Box>
		</Box>
	);
}

export default UserMenu;